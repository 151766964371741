import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';

const ScrollButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if we're more than 200px from the top
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(scrollTop > 200);

      // Get the main content element (everything before footer)
      const mainContent = document.querySelector('main') || document.querySelector('.container');
      if (!mainContent) return;

      // Get the bottom position of the main content
      const mainContentBottom = mainContent.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;
      
      // Consider "near bottom" when within 100px of the main content's bottom
      setIsAtBottom(mainContentBottom - windowHeight < 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToPosition = () => {
    if (isAtBottom) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // Find the main content element
      const mainContent = document.querySelector('main') || document.querySelector('.container');
      if (!mainContent) return;

      // Calculate the position to scroll to (bottom of main content)
      const mainContentBottom = mainContent.offsetTop + mainContent.offsetHeight - window.innerHeight;
      
      window.scrollTo({ 
        top: mainContentBottom,
        behavior: 'smooth' 
      });
    }
  };

  if (!isVisible) return null;

  return (
    <button
      onClick={scrollToPosition}
      className="fixed bottom-6 right-4 z-50 bg-gradient-to-r from-[#FF6100] to-[#A66D44] text-white p-3 rounded-full shadow-lg hover:shadow-xl transition-all duration-300 md:hidden"
      aria-label={isAtBottom ? "Scroll to top" : "Scroll to bottom"}
    >
      {isAtBottom ? (
        <ChevronUp size={24} />
      ) : (
        <ChevronDown size={24} />
      )}
    </button>
  );
};

export default ScrollButton; 