import React, { useState, useEffect, useMemo, useRef } from 'react'
import { MapPin, Plane } from 'lucide-react'
import debounce from 'lodash/debounce'
import { useLocations } from '../context/LocationContext'
import routesLogo from "./assets/routeslogo.png";
import europcar_img from "./assets/europcar_img.webp"

export default function LocationSearch({ isOpen, onClose, onSelect, isMobile }) {
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredLocations, setFilteredLocations] = useState([])
  const searchInputRef = useRef(null)
  const hasFetchedRef = useRef(false)
  
  const { locations, loading: isLoading, fetchLocations } = useLocations()

  useEffect(() => {
    if (isOpen && locations.length === 0 && !hasFetchedRef.current) {
      hasFetchedRef.current = true
      fetchLocations()
    }
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) {
      hasFetchedRef.current = false
    }
  }, [isOpen])

  // const filterLocations = useMemo(() => {
  //   return (searchTerm, locations) => {
  //     const search = searchTerm.toLowerCase()
  //     return locations.filter(location => 
  //       location.airport_name?.toLowerCase().includes(search) ||
  //       location.city?.toLowerCase().includes(search) ||
  //       location.country_name?.toLowerCase().includes(search) ||
  //       location.airport_code?.toLowerCase().includes(search)
  //     ).slice(0, 10)
  //   }
  // }, [])
  const filterLocations = useMemo(() => {
    return (searchTerm, locations) => {
      const search = searchTerm.toLowerCase()
      return locations.filter(location => {
        const airportNameMatch = location.airport_name?.toLowerCase().startsWith(search);
        const cityMatch = location.city?.toLowerCase().startsWith(search);
        const countryNameMatch = location.country_name?.toLowerCase().startsWith(search);
        const airportCodeMatch = location.airport_code?.toLowerCase().startsWith(search);
        
        // Prioritize matches that start with the search term
        if (airportNameMatch || cityMatch || countryNameMatch || airportCodeMatch) {
          return true;
        }
        
        // Fallback to includes for other matches
        // return location.airport_name?.toLowerCase().includes(search) ||
        //        location.city?.toLowerCase().includes(search) ||
        //        location.country_name?.toLowerCase().includes(search) ||
        //        location.airport_code?.toLowerCase().includes(search);
      }).slice(0, 10)
    }
  }, [])

  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm, locations) => {
        const filtered = filterLocations(searchTerm, locations)
        setFilteredLocations(filtered)
      }, 300),
    [filterLocations]
  )

  useEffect(() => {
    if (locations.length > 0) {
      debouncedSearch(searchTerm, locations)
    }
    return () => debouncedSearch.cancel()
  }, [searchTerm, locations, debouncedSearch])

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [isOpen])

  if (!isOpen) return null

  const containerClasses = `
    bg-black bg-opacity-70 backdrop-filter backdrop-blur-md
    rounded-lg shadow-lg w-full p-4 location-search-container
  `

  const formatLocation = (location) => {
    const parts = []
    if (location.airport_name) parts.push(location.airport_name)
    if (location.city) parts.push(location.city)
    if (location.country_name) parts.push(location.country_name)
    return parts.join(', ')
  }

  return (
    <div className={containerClasses}>
      <div className="flex items-center mb-4">
        <input
          ref={searchInputRef}
          type="text"
          placeholder="Search for a location"
          className="flex-grow p-2 border rounded bg-transparent text-white placeholder-white placeholder-opacity-70"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="border-t border-white/20 pt-4 max-h-60 overflow-y-auto">
        {isLoading ? (
          <div className="text-white text-center py-4">Loading locations...</div>
        ) : filteredLocations.length > 0 ? (
          filteredLocations.map((location, index) => (
            <div 
              key={location.location_id || index}
              className="flex items-center p-2 hover:bg-white/10 cursor-pointer text-white"
              onClick={() => {
                onSelect(formatLocation(location), location.location_id, location.source); // Pass both the formatted location name and ID
                onClose();
              }}
            >
            
              {(location.airport_code.length === 3 || location.airport_code[3] === "O") ? <Plane className="mr-2" /> : <MapPin className="mr-2" />}
              <div className="flex items-center justify-between w-full">
                <div>
                  <div className="font-bold">
                    {location.airport_name || location.city}
                  </div>
                  <div className="text-sm text-gray-300">
                    {location.airport_code}
                  </div>
                  <div className="text-sm text-gray-300">
                    {`${location.city}, ${location.country_name}`}
                  </div>
                </div>
                <img 
                  src={location.source === 'rms' ? routesLogo : europcar_img} 
                  alt={`${location.source} logo`}
                  className="h-6 w-auto ml-4"
                />
                { ["Chile","Australia","New Zealand","Barbados","Cayman Islands","Trinidad And Tobago"].includes(location.country_name) &&
                <img 
                src={europcar_img} 
                alt={`${location.source} logo`}
                className="h-6 w-auto ml-4"
              />
                }
              </div>
            </div>
          ))
        ) : (
          <div className="text-white text-center py-4">
            No locations found
          </div>
        )}
      </div>
    </div>
  )
}
