import React from 'react';
import { Link } from 'react-router-dom';
import pci_image from "./assets/pci-dss.webp"
import europcar_img from "./assets/europcar_img.webp"

const Footer = () => {
  return (
    <footer className="py-4">
      <div className="container mx-auto px-4 pt-4 border-t border-gray-200 shadow-[0_-2px_4px_rgba(0,0,0,0.05)] z-[9999]">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="font-bold mb-4 border-b-2 border-orange-500 inline-block">Services</h3>
            <ul className="space-y-2">
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/">Rent A Vehicle</Link></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/locations">Location Maps</Link></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/ShuttleRequestTracking">Shuttle Tracker</Link></li>
              {/* <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/">Reserve Now</Link></li> */}
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4 border-b-2 border-orange-500 inline-block">Site Map</h3>
            <ul className="space-y-2">
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/">Reserve Now</Link></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/locations">Locations</Link></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/rentalpolicy">Rental Policies</Link></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/ShuttleRequestTracking">Shuttle Tracker</Link></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/SmsPolicy">SMS Privacy</Link></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><Link to="/SmsAlerts">SMS Alerts</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold mb-4 border-b-2 border-orange-500 inline-block">Partner Links</h3>
            <ul className="space-y-2">
              <li className="hover:text-orange-500 transition-colors duration-200"><a href="https://www.routesairportparking.com/#Quotes" target="_blank" rel="noopener noreferrer">Routes Airport Parking</a></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><a href="https://www.routesautosales.com/" target="_blank" rel="noopener noreferrer">Routes Auto Remarketing</a></li>
              <li className="hover:text-orange-500 transition-colors duration-200"><a href="https://routesautohaulage.com/" target="_blank" rel="noopener noreferrer">Routes Auto Haulage</a></li>
            </ul>
            <div className="flex items-center space-x-4 mt-4">
              <img src={pci_image} alt="PCI DSS" className="h-8 w-auto" />
              <img src={europcar_img} alt="Europcar" className="h-8 w-auto" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
