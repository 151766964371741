import React, { useState, useEffect } from 'react'
import { ChevronLeft, ChevronRight, X, Clock, Loader2 } from 'lucide-react'
import moment from 'moment/moment'
import { useFormData } from '../context/FormContext';
import { getVehicleRatesMobile } from '../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const MobileCalendar = ({ 
  isOpen, 
  onClose, 
  onSelect,
  initialPickupDate = null,
  initialReturnDate = null,
  initialPickupTime = '11:00 AM',
  initialReturnTime = '11:00 AM',
  locationId = null,
  tempFormData = null
}) => {
 
  const { formData, updateFormData } = useFormData();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(() => {
    if (initialPickupDate) {
      return moment(initialPickupDate).startOf('month');
    }
    return moment().startOf('month');
  });
  const [selectedStartDate, setSelectedStartDate] = useState(() => {
    if (initialPickupDate) {
      return moment(initialPickupDate);
    }
    return moment().add(1, 'day').startOf('day');
  });
  const [selectedEndDate, setSelectedEndDate] = useState(() => {
    if (initialReturnDate) {
      return moment(initialReturnDate);
    }
    return moment().add(3, 'days').startOf('day');
  });

  const [pickupTime, setPickupTime] = useState(initialPickupTime);
  const [dropoffTime, setDropoffTime] = useState(initialReturnTime);

  const [showTimePickup, setShowTimePickup] = useState(false)
  const [showTimeDropoff, setShowTimeDropoff] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const generateCalendarDays = () => {
    const startOfMonth = currentDate.clone().startOf('month')
    const daysInMonth = currentDate.daysInMonth()
    const days = []

    // Add empty spaces for days before the first of the month
    const firstDayOfMonth = startOfMonth.day()
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null)
    }

    // Add all days of the month
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(currentDate.clone().date(i))
    }

    return days
  }

  const handleDateSelect = (date) => {
    if (!date || date.isBefore(moment().startOf('day'))) return;

    const selectedDate = moment(date).startOf('day');

    if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {
      setSelectedStartDate(selectedDate);
      setSelectedEndDate(null);
      setShowTimePickup(true);
    } else {
      if (selectedDate.isBefore(selectedStartDate)) {
        setSelectedEndDate(selectedStartDate.clone());
        setSelectedStartDate(selectedDate);
      } else {
        setSelectedEndDate(selectedDate);
      }
      setShowTimeDropoff(true);
    }
  };

  const handleTimeSelect = (isPickup, time) => {
    if (isPickup) {
      setPickupTime(time);
      setShowTimePickup(false);
    } else {
      setDropoffTime(time);
      setShowTimeDropoff(false);
    }

    if (selectedStartDate && selectedEndDate) {
      // const startDate = new Date(selectedStartDate.format('MM/DD/YYYY'));
      // const endDate = new Date(selectedEndDate.format('MM/DD/YYYY'));
      const startDate = new Date(selectedStartDate);
      const endDate = new Date(selectedEndDate);
     

      onSelect(
        startDate,
        endDate,
        isPickup ? time : pickupTime,
        isPickup ? dropoffTime : time
      );
    }
  };

  const TimeSelector = ({ isOpen, onClose, isPickup, currentTime, onSelect }) => {
    if (!isOpen) return null

    const generateTimeOptions = () => {
      const times = []
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 30) {
          const time = moment().hour(i).minute(j).format('hh:mm A')
          times.push(time)
        }
      }
      return times
    }

    return (
      <div className="fixed inset-0 bg-black/50 z-[10003]">
        <div className="fixed bottom-0 left-0 right-0 bg-white rounded-t-xl p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">
              Select {isPickup ? 'Pick-up' : 'Drop-off'} Time
            </h3>
            <button onClick={onClose}>
              <X size={24} />
            </button>
          </div>
          <div className="max-h-[50vh] overflow-auto">
            {generateTimeOptions().map(time => (
              <button
                key={time}
                className={`w-full py-4 text-left px-4 
                  ${time === currentTime ? 'bg-orange-50 text-orange-600 font-medium' : ''}
                  hover:bg-gray-50 active:bg-gray-100`}
                onClick={() => onSelect(time)}
              >
                {time}
              </button>
            ))}
          </div>
        </div>
      </div>
    )
  }

  if (!isOpen) return null

  const handleMobileFindRide = async () => {
    try {
      
      const currentLocationId = locationId || formData.selectedLocationId;
      if (!currentLocationId) {
        toast.error('Please select your pick-up location');
        return;
      }

      setIsLoading(true);

      const formatTimeTo24Hr = (timeStr) => {
        const [time, period] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours);
        
        if (period === 'PM' && hours !== 12) hours += 12;
        if (period === 'AM' && hours === 12) hours = 0;
        
        return `${hours.toString().padStart(2, '0')}:${minutes}`;
      };

      const pickupTime24 = formatTimeTo24Hr(pickupTime);
      const dropoffTime24 = formatTimeTo24Hr(dropoffTime);

      const searchData = {
        pickupDate: selectedStartDate.format('YYYY-MM-DD') + 'T' + pickupTime24,
        dropoffDate: selectedEndDate.format('YYYY-MM-DD') + 'T' + dropoffTime24,
        pickupTime: pickupTime24,
        dropoffTime: dropoffTime24,
        locationId: currentLocationId,
        dropoff_location_id: (tempFormData ? tempFormData.selectedReturnLocationId  : formData.selectedReturnLocationId) || currentLocationId,
      };


      const response = await getVehicleRatesMobile(searchData);
      
      if (!response || (Array.isArray(response) && response.length === 0)) {
        toast.error('No vehicles found for the selected criteria');
        return;
      }

      // Call onSelect if provided (for MainHeader integration)
      if (onSelect) {
        onSelect(
          selectedStartDate.toDate(),
          selectedEndDate.toDate(),
          pickupTime,
          dropoffTime
        );
      }

    
      const tempFormData1 = {...tempFormData}
      tempFormData1['pickupDate'] = selectedStartDate.format('L')
      tempFormData1['returnDate'] = selectedEndDate.format('L')
      tempFormData1['pickupTime'] = pickupTime
      tempFormData1['returnTime'] = dropoffTime

      // updateFormData({
      //   selectedLocationId: currentLocationId,
      //   pickupDate: selectedStartDate.format('L'),
      //   returnDate: selectedEndDate.format('L'),
      //   pickupTime: pickupTime,
      //   returnTime: dropoffTime
      // });
      updateFormData(tempFormData1)

      // Close the calendar modal after successful API response
      if (onClose) {
        onClose();
      }

      navigate('/vehicle', { 
        state: { 
          vehicleData: response,
          searchCriteria: searchData
        },
        replace: true
      });

    } catch (error) {
      console.error('Error fetching vehicle rates:', error);
      toast.error('Error fetching vehicle rates. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateToDisplay = (dateString) => {
    if (!dateString) return "";
    
    // Try parsing with different formats
    let date;
    if (dateString.includes('/')) {
      // Handle MM/DD/YYYY format
      const [month, day, year] = dateString.split('/');
      date = moment(`${year}-${month}-${day}`);
    } else {
      // Try parsing the date directly
      date = moment(dateString);
    }

    // Check if the date is valid
    if (!date.isValid()) {
      console.warn('Invalid date:', dateString);
      return "";
    }

    return date.format("MMM D, YYYY");
  };

  return (
    <div className="fixed inset-0 bg-white z-[9999]">
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b">
        <button onClick={onClose} className="p-2">
          <X size={24} />
        </button>
        <h2 className="text-lg font-semibold">Select Dates</h2>
        <div className="w-8" />
      </div>

      {/* Selected Dates */}
      <div className="p-3 bg-gray-50 space-y-3">
        <div>
          <p className="text-sm text-gray-500 mb-1">Pick-up</p>
          <div className="bg-white rounded-lg border p-2">
            <div className="font-medium">
              {selectedStartDate ? selectedStartDate.format('MMM D, YYYY') : 'Select date'}
         
            </div>
            <button 
              onClick={() => selectedStartDate && setShowTimePickup(true)}
              className={`mt-1 flex items-center gap-2 text-md ${selectedStartDate ? 'text-orange-600' : 'text-gray-400'}`}
              disabled={!selectedStartDate}
            >
              <Clock size={14} />
              {pickupTime}
            </button>
          </div>
        </div>

        <div>
          <p className="text-sm text-gray-500 mb-1">Drop-off</p>
          <div className="bg-white rounded-lg border p-2">
            <div className="font-medium">
              {selectedEndDate ? selectedEndDate.format('MMM D, YYYY') : 'Select date'}
            </div>
            <button 
              onClick={() => selectedEndDate && setShowTimeDropoff(true)}
              className={`mt-1 flex items-center gap-2 text-md ${selectedEndDate ? 'text-orange-600' : 'text-gray-400'}`}
              disabled={!selectedEndDate}
            >
              <Clock size={14} />
              {dropoffTime}
            </button>
          </div>
        </div>
      </div>

      {/* Calendar */}
      <div className="p-3">
        <div className="flex items-center justify-between mb-2">
          <button onClick={() => setCurrentDate(d => moment(d).subtract(1, 'month'))}>
            <ChevronLeft size={24} />
          </button>
          <span className="font-medium">{currentDate.format('MMMM YYYY')}</span>
          <button onClick={() => setCurrentDate(d => moment(d).add(1, 'month'))}>
            <ChevronRight size={24} />
          </button>
        </div>

        <div className="grid grid-cols-7 gap-0.5">
          {DAYS.map(day => (
            <div key={day} className="text-center text-xs text-gray-500 py-1">
              {day}
            </div>
          ))}
          {generateCalendarDays().map((date, i) => (
            <button
              key={i}
              disabled={!date || date.isBefore(moment().startOf('day'))}
              className={`
                aspect-square flex items-center justify-center text-xs relative
                ${!date ? 'invisible' : ''}
                ${date?.isBefore(moment().startOf('day')) ? 'text-gray-300' : ''}
                ${date && date.isSame(selectedStartDate, 'day') ? 'bg-orange-600 text-white rounded-full' : ''}
                ${date && date.isSame(selectedEndDate, 'day') ? 'bg-orange-600 text-white rounded-full' : ''}
                ${date && selectedStartDate && selectedEndDate && 
                  date.isBetween(selectedStartDate, selectedEndDate) ? 'bg-orange-100' : ''}
              `}
              onClick={() => handleDateSelect(date)}
            >
              {date?.date()}
            </button>
          ))}
        </div>
      </div>

      {/* Confirm Button */}
      <div className="absolute bottom-0 left-0 right-0 p-4 border-t bg-white">
        <button
          className="w-full bg-orange-600 text-white py-3 rounded-lg font-medium disabled:opacity-50"
          disabled={!selectedStartDate || !selectedEndDate || isLoading}
          onClick={handleMobileFindRide}
        >
          {isLoading ? (
            <div className="flex items-center justify-center gap-2">
              <Loader2 className="animate-spin" size={20} />
              <span>Finding your ride...</span>
            </div>
          ) : (
            'Book Now'
          )}
        </button>
      </div>

      {/* Time Pickers */}
      <TimeSelector
        isOpen={showTimePickup}
        onClose={() => setShowTimePickup(false)}
        isPickup={true}
        currentTime={pickupTime}
        onSelect={(time) => handleTimeSelect(true, time)}
      />
      <TimeSelector
        isOpen={showTimeDropoff}
        onClose={() => setShowTimeDropoff(false)}
        isPickup={false}
        currentTime={dropoffTime}
        onSelect={(time) => handleTimeSelect(false, time)}
      />
    </div>
  )
}

export default MobileCalendar
