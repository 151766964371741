import React, { useState } from 'react';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';
import { Mail, Phone } from 'lucide-react';
import axios from 'axios';

const Contact = () => {

  const baseURL = process.env.REACT_APP_API_URL;

  // Add state management
  const [formData, setFormData] = useState({
    fullname: '',
    emailid: '',
    comments: ''
  });

  const [inputErrors, setInputErrors] = useState({
    fullname: false,
    emailid: false,
    comments: false
  });

  // Add these new states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setInputErrors(prev => ({
      ...prev,
      [name]: false
    }));
  };

  // Add validation function
  const validateForm = () => {
    const errors = {
      fullname: false,
      emailid: false,
      comments: false
    };
    let isValid = true;

    // Name validation (at least 2 characters)
    if (!formData.fullname || formData.fullname.trim().length < 2) {
      errors.fullname = true;
      isValid = false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.emailid || !emailRegex.test(formData.emailid)) {
      errors.emailid = true;
      isValid = false;
    }

    // Comments validation (at least 10 characters)
    if (!formData.comments || formData.comments.trim().length < 10) {
      errors.comments = true;
      isValid = false;
    }

    setInputErrors(errors);
    return isValid;
  };

  // Update handleSubmit with API call
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      setLoading(true);
      setError(null);
      
      axios
        .post(baseURL + "/new_routes_website_api_contactus", {
          fullname: formData.fullname,
          emailid: formData.emailid,
          comments: formData.comments
        })
        .then(() => {
          alert("Thank you for your message. We will get back to you soon.");
          // Reset form
          setFormData({
            fullname: '',
            emailid: '',
            comments: ''
          });
        })
        .catch((err) => {
          setError("An error occurred. Please try again later.");
        })
        .finally(() => setLoading(false));
    }
  };

  // Update getInputClassName to include error message
  const getInputClassName = (fieldName) => 
    `form-control ${inputErrors[fieldName] ? 'border-red-500' : 'border-gray-300'} border rounded-lg p-2 w-full`;

  return (
    <div className="min-h-screen flex flex-col">
      <MainHeader />
      
      <main className="flex-grow container mx-auto px-4 py-12 max-w-6xl">
        {/* Page Title */}
        <div className="text-center mb-12">
  <h1 className="text-3xl md:text-5xl font-extrabold text-gray-800 tracking-tight mb-4">
  We're Here <span className="text-orange-500">To Help</span>
  </h1>
  <p className="text-gray-600 text-lg max-w-2xl mx-auto">
  Get in touch with our customer care team
          </p>
        </div>
        {/* Contact Cards Container */}
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {/* Email Card */}
          <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-xl border border-gray-100">
            <div className="bg-orange-100 p-5 rounded-full mb-6 transform transition-all duration-300 hover:rotate-12">
              <Mail className="w-10 h-10 text-orange-600" />
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Email Us</h2>
            {/* <p className="text-gray-600 mb-4 text-center">We'll respond within 24 hours</p> */}
            <a 
              href="mailto:customercare@routes.ca"
              className="text-blue-600 hover:text-blue-700 font-medium text-lg transition-colors duration-300 hover:underline"
            >
              customercare@routes.ca
            </a>
          </div>

          {/* Phone Card */}
          <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col items-center justify-center transition-transform duration-300 hover:scale-105 hover:shadow-xl border border-gray-100">
            <div className="bg-orange-100 p-5 rounded-full mb-6 transform transition-all duration-300 hover:rotate-12">
              <Phone className="w-10 h-10 text-orange-600" />
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">Call Us</h2>
            {/* <p className="text-gray-600 mb-4 text-center">Available Monday-Friday, 9am-5pm EST</p> */}
            <div className="flex flex-col items-center gap-3">
              <a 
                href="tel:+18884718639"
                className="text-blue-600 hover:text-blue-700 font-medium text-lg transition-colors duration-300 hover:underline"
              >
                +1 (888) 471-8639
              </a>
              <span className="text-gray-400">or</span>
              <a 
                href="tel:+14169936826"
                className="text-blue-600 hover:text-blue-700 font-medium text-lg transition-colors duration-300 hover:underline"
              >
                +1 (416) 993-6826
              </a>
            </div>
          </div>
        </div>

        {/* Add the contact form */}
        <div className="mt-16 max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-8">
          <form onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="form-group">
                <label htmlFor="fullname" className="block text-gray-700 mb-2">Name*</label>
                <input
                  type="text"
                  className={getInputClassName('fullname')}
                  id="fullname"
                  placeholder="Your name"
                  name="fullname"
                  value={formData.fullname}
                  onChange={handleInputChange}
                />
                {inputErrors.fullname && (
                  <p className="text-red-500 text-sm mt-1">Please enter a valid name (minimum 2 characters)</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="emailid" className="block text-gray-700 mb-2">Email address*</label>
                <input
                  type="email"
                  className={getInputClassName('emailid')}
                  id="emailid"
                  placeholder="Enter email"
                  name="emailid"
                  value={formData.emailid}
                  onChange={handleInputChange}
                />
                {inputErrors.emailid && (
                  <p className="text-red-500 text-sm mt-1">Please enter a valid email address</p>
                )}
              </div>
            </div>

            <div className="form-group mt-6">
              <label htmlFor="comments" className="block text-gray-700 mb-2">Your Message*</label>
              <textarea
                className={getInputClassName('comments')}
                id="comments"
                rows="5"
                placeholder="Your message"
                name="comments"
                value={formData.comments}
                onChange={handleInputChange}
              ></textarea>
              {inputErrors.comments && (
                <p className="text-red-500 text-sm mt-1">Please enter a message (minimum 10 characters)</p>
              )}
            </div>
            
            <div className="text-center mt-6">
              <button
                type="submit"
                disabled={loading}
                className="bg-orange-500 text-white px-8 py-3 rounded-lg font-medium hover:bg-orange-600 transition-colors duration-300 disabled:bg-gray-400"
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </form>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
