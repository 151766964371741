import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import {
  MapPin,
  Menu,
  X,
  BusFront,
  Clock,
  Loader2,
  MoveRight,
} from "lucide-react";
import bgImage from "./assets/bckg_image.webp";
import bgImageMobile from "./assets/mobile_bg.webp";
import imgExplore1 from "./assets/img_explore_1.webp";
import imgExplore2 from "./assets/img_explore_2.webp";
import routesLogo from "./assets/routeslogo.png";
import LocationSearch from "./LocationSearch";
import ReturnLocationSearch from './ReturnLocationSearch';
import "./CarRentalHome.css";
import { useNavigate } from "react-router-dom";
import { getVehicleRates } from "../services/api";
import { useFormData } from "../context/FormContext";
import LoadingOverlay from "./LoadingOverlay";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideMenu from "./SideMenu";

// Lazy load components that aren't needed for initial render
const GoogleReviewsWidget = lazy(() => import("./GoogleReviewsWidget"));
const TrustPilotWidget = lazy(() => import("./TrustPilotWidget"));
const Calendar = lazy(() => import("./Calendar"));
const MobileCalendar = lazy(() => import("./MobileCalendar"));
const Footer = lazy(() => import("./Footer"));

// Move generateTimeOptions before its usage
const generateTimeOptions = () => {
  const times = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      let hour = i % 12;
      const period = i < 12 ? "AM" : "PM";
      // Use 00 for midnight hours (12 AM)
      if (period === "AM" && hour === 0) {
        hour = "00";
      } else {
        hour = hour || 12; // Use 12 for noon (12 PM)
      }
      times.push(
        `${hour.toString().padStart(2, "0")}:${j
          .toString()
          .padStart(2, "0")} ${period}`
      );
    }
  }
  return times;
};

const CarRentalHome = () => {
  const { formData, updateFormData } = useFormData();

  // Get tomorrow's date for pickup
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Get return date (2 days after pickup date)
  const returnDateDefault = new Date(tomorrow);
  returnDateDefault.setDate(returnDateDefault.getDate() + 2);

  // Format dates as strings
  const formatDate = (date) => date.toLocaleDateString();

  const [menuOpen, setMenuOpen] = useState(false);
  const [locationSearchOpen, setLocationSearchOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const formRef = useRef(null);
  const locationSearchRef = useRef(null);
  const locationInputRef = useRef(null);
  const [mobileLocationSearchOpen, setMobileLocationSearchOpen] = useState(false);
  const [showPickupTime, setShowPickupTime] = useState(false);
  const [showReturnTime, setShowReturnTime] = useState(false);

  const timePickerRef = useRef(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  // Memoize expensive computations
  const timeOptions = React.useMemo(() => generateTimeOptions(), []);

  // Add loading state for images
  const [imagesLoaded, setImagesLoaded] = useState(false);

  // Add priority loading for desktop background
  useEffect(() => {
    // Preload critical images with priority for desktop
    const imageUrls = [bgImage, bgImageMobile, routesLogo];
    let loadedImages = 0;

    // Create new Image objects
    const preloadImages = imageUrls.map((url) => {
      const img = new Image();
      
      // Add loading priority for desktop background
      if (url === bgImage) {
        img.fetchPriority = 'high';
        img.loading = 'eager';
      }
      
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageUrls.length) {
          setImagesLoaded(true);
        }
      };

      img.onerror = () => {
        console.error(`Failed to load image: ${url}`);
        // Still increment counter to prevent hanging
        loadedImages++;
        if (loadedImages === imageUrls.length) {
          setImagesLoaded(true);
        }
      };

      img.src = url;
      return img;
    });

    // Keep references to prevent garbage collection
    return () => {
      preloadImages.forEach(img => {
        img.onload = null;
        img.onerror = null;
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    };

    const handleScroll = debounce(() => {
      if (formRef.current) {
        const scrollPosition = window.scrollY;
        setIsSticky(scrollPosition > 200);
      }
    }, 10);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Add this state to track if we should prevent location search closing
  const [preventLocationSearchClose, setPreventLocationSearchClose] = useState(false);

  // Update the useEffect for click outside handling
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationInputRef.current &&
        !locationInputRef.current.contains(event.target) &&
        !event.target.closest('.location-search-container')
      ) {
        if (!preventLocationSearchClose) {
          if (!isMobile) {
            closeLocationSearch();
          } else {
            closeMobileLocationSearch();
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, preventLocationSearchClose]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setShowPickupTime(false);
        setShowReturnTime(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (
            returnLocationInputRef.current &&
            !returnLocationInputRef.current.contains(event.target) &&
            !event.target.closest('.return-location-search-container')
        ) {
            closeReturnLocationSearch();
            closeMobileReturnLocationSearch();
        }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [preventLocationSearchClose]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openLocationSearch = () => {
    setLocationSearchOpen(true);
  };

  const closeLocationSearch = () => {
    setLocationSearchOpen(false);
  };

  const openMobileLocationSearch = () => {
    setMobileLocationSearchOpen(true);
  };

  const closeMobileLocationSearch = () => {
    setMobileLocationSearchOpen(false);
  };

  const handleLocationSelect = (location, locationId, source) => {
    updateFormData({
      selectedLocation: location,
      selectedLocationId: locationId,
      selectedLocationSource: source,
    });
    closeLocationSearch();
    closeMobileLocationSearch();
    
    if (isMobile) {
      if (differentReturnLocation) {
        setPreventLocationSearchClose(true);
        // Open return location search immediately
        setMobileReturnLocationSearchOpen(true);
      } else {
        setPreventLocationSearchClose(false);
        openMobileCalendar();
      }
    }
  };

  const openCalendar = () => {
    setCalendarOpen(true);
  };

  const closeCalendar = () => {
    setCalendarOpen(false);
  };

  const handleDateSelect = (start, end, pickupTime, dropoffTime) => {
    // Don't update times if they're not provided (preserve existing times)
    const updatedData = {
      pickupDate: start.toLocaleDateString(),
      returnDate: end.toLocaleDateString(),
    };

    // Only update times if they're actually provided
    if (pickupTime) {
      updatedData.pickupTime = pickupTime;
    }
    if (dropoffTime) {
      updatedData.returnTime = dropoffTime;
    }

    updateFormData(updatedData);

    if (!isMobile) {
      closeCalendar();
    }
  };

  const renderTimePicker = (time, setTime, show, setShow, label) => (
    <div className="relative" ref={timePickerRef}>
      <div
        className="flex items-center space-x-2 cursor-pointer"
        onClick={() => setShow(!show)}
      >
        <Clock size={16} className="text-white" />
        <span className="text-white">{time || label}</span>
      </div>
      {show && (
        <div className="absolute top-full left-0 mt-1 bg-black/80 backdrop-blur-sm border border-white/20 rounded shadow-lg max-h-48 overflow-y-auto z-50">
          {timeOptions.map((option) => (
            <div
              key={option}
              className="px-4 py-2 hover:bg-white/20 cursor-pointer text-white"
              onClick={() => {
                setTime(option);
                setShow(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const [returnLocationSearchOpen, setReturnLocationSearchOpen] = useState(false);
  const [differentReturnLocation, setDifferentReturnLocation] = useState(false);
  const [mobileReturnLocationSearchOpen, setMobileReturnLocationSearchOpen] = useState(false);
  const returnLocationInputRef = useRef(null);

  const openReturnLocationSearch = () => {
    setReturnLocationSearchOpen(true);
  };

  const closeReturnLocationSearch = () => {
    setReturnLocationSearchOpen(false);
  };

  const openMobileReturnLocationSearch = () => {
    setMobileReturnLocationSearchOpen(true);
  };

  const closeMobileReturnLocationSearch = () => {
    setMobileReturnLocationSearchOpen(false);
  };

  const handleReturnLocationSelect = (location, locationId, source) => {
    // First update the form data
    updateFormData({
      selectedReturnLocation: location,
      selectedReturnLocationId: locationId,
      selectedReturnLocationSource: source,
    });
    
    // Reset the prevent close flag
    setPreventLocationSearchClose(false);
    
    // Close both searches with a slight delay to ensure the selection is registered
    setTimeout(() => {
      closeReturnLocationSearch();
      closeMobileReturnLocationSearch();
      
      // Only open calendar on mobile after a brief delay
      if (isMobile) {
        setTimeout(() => {
          setCalendarOpen(true);
        }, 100);
      }
    }, 50);
  };

  // Add this effect to handle changes to differentReturnLocation
  useEffect(() => {
    if (differentReturnLocation) {
        // Clear return location when differentReturnLocation is set to true
        updateFormData({
            selectedReturnLocation: '',
            selectedReturnLocationId: null,
            selectedReturnLocationSource: null,
        });
    } else {
        // Set return location same as pickup location if differentReturnLocation is false
        updateFormData({
            selectedReturnLocation: formData.selectedLocation,
            selectedReturnLocationId: formData.selectedLocationId,
            selectedReturnLocationSource: 'pickup', // or any appropriate source
        });
    }
  }, [differentReturnLocation, formData.selectedLocation, formData.selectedLocationId]);

  const renderForm = () => (
    <div
      className={`bg-black/40 backdrop-blur-sm rounded-lg p-4 flex flex-col space-y-4 border border-white ${
        isSticky ? "fixed top-0 left-0 right-0 z-40 mx-auto max-w-6xl" : ""
      }`}
    >
      {/* First Row */}
      <div className="flex items-start space-x-4">
        {/* Left Side - Location and Checkbox */}
        <div style={{ width: "35%" }}>
          <div className="relative" ref={locationInputRef}>
            <div
              className="rounded-full px-6 py-3 flex items-center cursor-pointer bg-black/20"
              onClick={openLocationSearch}
            >
              <MapPin className="text-gray-100 mr-2" size={25} />
              <input
                type="text"
                placeholder="Where To?"
                value={formData.selectedLocation}
                readOnly
                className="w-full bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100"
              />
            </div>
            {locationSearchOpen && (
              <div className="absolute top-full left-0 mt-2 w-full z-50">
                <LocationSearch
                  isOpen={locationSearchOpen}
                  onClose={closeLocationSearch}
                  onSelect={handleLocationSelect}
                  isMobile={isMobile}
                />
              </div>
            )}
          </div>

          {!differentReturnLocation && (
            <div className="flex items-center px-6 mt-3">
              <input
                type="checkbox"
                id="differentReturn"
                checked={differentReturnLocation}
                onChange={(e) => setDifferentReturnLocation(e.target.checked)}
                className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <label htmlFor="differentReturn" className="ml-2 text-white text-sm">
                Return to a different location
              </label>
            </div>
          )}
        </div>

        {/* Middle - Date/Time Selectors */}
        <div className="flex items-center space-x-2 self-start" style={{ width: "50%" }}>
          {/* Pickup Date/Time */}
          <div
            className="flex-1 flex items-center space-x-1 cursor-pointer px-3 py-3 bg-black/20 rounded-full"
            onClick={openCalendar}
          >
            <input
              type="text"
              placeholder="Pick-up"
              value={formData.pickupDate ? formatDateToDisplay(formData.pickupDate) : ""}
              readOnly
              className="w-full bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center"
            />
            <select
              value={formData.pickupTime}
              onChange={(e) => updateFormData({ pickupTime: e.target.value })}
              className="bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center appearance-none cursor-pointer hover:bg-white/10 rounded-lg px-2"
              onClick={(e) => e.stopPropagation()}
              style={{ WebkitAppearance: "none", MozAppearance: "none" }}
            >
              <option value="" disabled hidden className="bg-gray-900 text-white py-2">
                Time
              </option>
              {timeOptions.map((time) => (
                <option
                  key={time}
                  value={time}
                  className="bg-gray-900 text-white py-2 px-4 hover:bg-gray-800"
                >
                  {time}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center justify-center">
            <MoveRight size={20} className="text-white" />
          </div>

          {/* Return Date/Time */}
          <div
            className="flex-1 flex items-center space-x-1 cursor-pointer px-3 py-3 bg-black/20 rounded-full"
            onClick={openCalendar}
          >
            <input
              type="text"
              placeholder="Return"
              value={formData.returnDate ? formatDateToDisplay(formData.returnDate) : ""}
              readOnly
              className="w-full bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center"
            />
            <select
              value={formData.returnTime}
              onChange={(e) => updateFormData({ returnTime: e.target.value })}
              className="bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center appearance-none cursor-pointer hover:bg-white/10 rounded-lg px-2"
              onClick={(e) => e.stopPropagation()}
              style={{ WebkitAppearance: "none", MozAppearance: "none" }}
            >
              <option value="" disabled hidden className="bg-gray-900 text-white py-2">
                Time
              </option>
              {timeOptions.map((time) => (
                <option
                  key={time}
                  value={time}
                  className="bg-gray-900 text-white py-2 px-4 hover:bg-gray-800"
                >
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Book Now Button */}
        <div className="px-4 self-start">
          <button
            onClick={handleFindMyRide}
            disabled={isLoading}
            className="bg-[#FF6100] border-2 border-white hover:bg-[#FF8942] 
            hover:shadow-lg text-white px-9 py-3 rounded-full transition-all 
            flex items-center justify-center whitespace-nowrap"
          >
            {isLoading ? (
              <>
                <Loader2 className="animate-spin mr-2" size={18} />
                Finding...
              </>
            ) : (
              "Book Now"
            )}
          </button>
        </div>
      </div>

      {/* Second Row - Only show when return location is checked */}
      {differentReturnLocation && (
        <div className="flex items-center space-x-4">
          {/* Return Location */}
          <div style={{ width: "33%" }}>
            <div className="relative" ref={returnLocationInputRef}>
              <div
                className="rounded-full px-6 py-3 flex items-center cursor-pointer bg-black/20"
                onClick={openReturnLocationSearch}
              >
                <MapPin className="text-gray-100 mr-2" size={25} />
                <input
                  type="text"
                  placeholder="Return To?"
                  value={formData.selectedReturnLocation}
                  readOnly
                  className="w-full bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100"
                />
              </div>
              {returnLocationSearchOpen && (
                <div className="absolute top-full left-0 mt-2 w-full z-50">
                  <ReturnLocationSearch
                    isOpen={returnLocationSearchOpen}
                    onClose={closeReturnLocationSearch}
                    onSelect={handleReturnLocationSelect}
                    isMobile={isMobile}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Checkbox in second row when checked */}
          <div className="flex items-center" style={{ width: "45%" }}>
            <div className="flex items-center px-6">
              <input
                type="checkbox"
                id="differentReturn"
                checked={differentReturnLocation}
                onChange={(e) => setDifferentReturnLocation(e.target.checked)}
                className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <label htmlFor="differentReturn" className="ml-2 text-white text-sm">
                Return to a different location
              </label>
            </div>
          </div>

          {/* Empty space to maintain alignment */}
          <div className="px-4 invisible">
            <button className="px-9 py-3">Spacer</button>
          </div>
        </div>
      )}

      {/* Calendar */}
      {!isMobile && (
        <div
          className="absolute left-1/2 transform -translate-x-1/2"
          // className="absolute top-full left-0 mt-2 w-full z-50"
          style={{
            top: differentReturnLocation ? "calc(100% + 80px)" : "calc(100% + 110px)",
            zIndex: 99999,
            width: "600px",
            marginTop: "30px",
            marginLeft: "10%",
          }}
        >
          <div className="backdrop-blur-xl bg-black/60">
            <Calendar
              isOpen={calendarOpen}
              onClose={closeCalendar}
              onSelect={handleDateSelect}
              initialPickupDate={formData.pickupDate ? new Date(formData.pickupDate) : null}
              initialReturnDate={formData.returnDate ? new Date(formData.returnDate) : null}
            />
          </div>
        </div>
      )}
    </div>
  );

  // Add this function
  const openMobileCalendar = () => {
    setCalendarOpen(true);
  };

  const handleFindMyRide = async (e) => {
    e.preventDefault();

    try {
      // Add validation for return location if checkbox is checked
      if (differentReturnLocation && !formData.selectedReturnLocationId) {
        toast.error("Please select your return location");
        return;
      }

      // Check each field individually with specific error messages
      if (!formData.selectedLocationId) {
        toast.error("Please select your pick-up location");
        return;
      }

      if (!formData.pickupDate) {
        toast.error("Please select your pick-up date");
        return;
      }

      if (!formData.pickupTime) {
        toast.error("Please select your pick-up time");
        return;
      }

      if (!formData.returnDate) {
        toast.error("Please select your return date");
        return;
      }

      if (!formData.returnTime) {
        toast.error("Please select your return time");
        return;
      }

      // Convert date strings to Date objects with timezone handling
      const formatDateWithoutTimezone = (dateStr, timeStr) => {
        const [month, day, year] = dateStr.split("/");
        const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        return new Date(`${isoDate}T${timeStr}`);
      };

      // Format times to 24-hour format (HH:mm)
      const formatTimeTo24Hr = (timeStr) => {
        const [time, period] = timeStr.split(" ");
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours);

        if (period === "PM" && hours !== 12) {
          hours += 12;
        } else if (period === "AM" && hours === 12) {
          hours = 0;
        }

        return `${hours.toString().padStart(2, "0")}:${minutes}`;
      };

      const pickupTime24 = formatTimeTo24Hr(formData.pickupTime);
      const dropoffTime24 = formatTimeTo24Hr(formData.returnTime);

      const pickupDateTime = formatDateWithoutTimezone(
        formData.pickupDate,
        pickupTime24
      );
      const returnDateTime = formatDateWithoutTimezone(
        formData.returnDate,
        dropoffTime24
      );

      // Validate dates
      if (pickupDateTime >= returnDateTime) {
        toast.error("Return date must be after pickup date");
        return;
      }

      // Set loading to true before API call
      setIsLoading(true);

      const searchData = {
        pickupDate: pickupDateTime,
        dropoffDate: returnDateTime,
        pickupTime: pickupTime24,
        dropoffTime: dropoffTime24,
        locationId: formData.selectedLocationId,
        // Use return location ID if checkbox is checked, otherwise use pickup location ID
        dropoff_location_id: differentReturnLocation 
          ? formData.selectedReturnLocationId 
          : formData.selectedLocationId,
      };

      const response = await getVehicleRates(searchData);
      navigate("/vehicle", {
        state: { vehicleData: response },
      });
    } catch (error) {
      console.error("Error fetching vehicle rates:", error);
      toast.error("Error fetching vehicle rates. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMobileFindRide = async () => {
    try {
      // Check each field individually with specific error messages
      if (!formData.selectedLocationId) {
        toast.error("Please select your pick-up location");
        return;
      }

      if (!formData.pickupDate) {
        toast.error("Please select your pick-up date");
        return;
      }

      if (!formData.pickupTime) {
        toast.error("Please select your pick-up time");
        return;
      }

      if (!formData.returnDate) {
        toast.error("Please select your return date");
        return;
      }

      if (!formData.returnTime) {
        toast.error("Please select your return time");
        return;
      }

      // Convert date strings to Date objects with timezone handling
      const formatDateWithoutTimezone = (dateStr, timeStr) => {
        // Split the date string into components
        const [month, day, year] = dateStr.split("/");

        // Create date string in ISO format (YYYY-MM-DD)
        const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;

        // Combine date and time
        return new Date(`${isoDate}T${timeStr}`);
      };

      // Format times to 24-hour format (HH:mm)
      const formatTimeTo24Hr = (timeStr) => {
        const [time, period] = timeStr.split(" ");
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours);

        if (period === "PM" && hours !== 12) {
          hours += 12;
        } else if (period === "AM" && hours === 12) {
          hours = 0;
        }

        return `${hours.toString().padStart(2, "0")}:${minutes}`;
      };

      const pickupTime24 = formatTimeTo24Hr(formData.pickupTime);
      const dropoffTime24 = formatTimeTo24Hr(formData.returnTime);

      const pickupDateTime = formatDateWithoutTimezone(
        formData.pickupDate,
        pickupTime24
      );
      const returnDateTime = formatDateWithoutTimezone(
        formData.returnDate,
        dropoffTime24
      );

      // Validate dates
      if (pickupDateTime >= returnDateTime) {
        toast.error("Return date must be after pickup date");
        return;
      }

      setIsLoading(true);

      const searchData = {
        pickupDate: pickupDateTime,
        dropoffDate: returnDateTime,
        pickupTime: pickupTime24,
        dropoffTime: dropoffTime24,
        locationId: formData.selectedLocationId,
        dropoff_location_id: differentReturnLocation 
          ? formData.selectedReturnLocationId 
          : formData.selectedLocationId,
      };

      const response = await getVehicleRates(searchData);
      navigate("/vehicle", {
        state: { vehicleData: response },
      });
    } catch (error) {
      console.error("Error fetching vehicle rates:", error);
      toast.error("Error fetching vehicle rates. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Add this helper function near the top of the component
  const formatDateToDisplay = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  // Update the desktop background div to include a fallback color
  const desktopBackgroundStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundColor: '#1a1a1a', // Fallback color while image loads
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    transition: 'background-image 0.3s ease-in-out'
  };

  return (
    <div className="relative min-h-screen w-full overflow-x-hidden font-sans  tracking-wider-custom">
      {/* Show loading spinner while images are loading */}
      {!imagesLoaded && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <Loader2 className="animate-spin text-white" size={40} />
        </div>
      )}

      {/* Wrap non-critical components in Suspense */}
      <Suspense fallback={null}>
        {/* Mobile Layout */}
        <div
          className="lg:hidden min-h-screen relative bg-cover bg-center"
          style={{ backgroundImage: `url(${bgImageMobile})` }}
        >
          {/* Mobile Header */}
          <header className="fixed top-0 left-0 right-0 z-50 lg:hidden">
            <div className="container mx-auto px-4 py-6">
              <div className="flex justify-between items-center">
                <img src={routesLogo} alt="Routes Logo" className="h-16" />
                <div className="flex items-center space-x-3">
                  <button className="flex items-center space-x-1 text-white bg-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out rounded-full px-2 py-2 cursor-pointer" onClick={() => navigate("/ShuttleRequestTracking")}>
                    {/* <BusFront size={20} /> */}
                    <span>SHUTTLE TRACKER</span>
                  </button>
                  <button
                    className="text-white focus:outline-none"
                    onClick={toggleMenu}
                  >
                    {menuOpen ? <X size={24} /> : <Menu size={24} />}
                  </button>
                </div>
              </div>
            </div>
          </header>

          {/* Mobile Search Bar */}
          <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-transparent"></div>
          <div className="relative min-h-screen flex items-start pt-48 px-4">
            <div
              className={`w-full max-w-xl mx-auto space-y-4 transition-all duration-300 ${
                isSticky
                  ? "fixed top-0 left-0 right-0 z-40 px-4 pt-28  backdrop-blur-sm"
                  : ""
              }`}
              ref={formRef}
            >
              <div
                className="bg-black/40 backdrop-blur-sm rounded-full shadow-lg overflow-hidden"
                ref={locationInputRef}
              >
                <div className="flex flex-col space-y-3 px-4 py-3">
                  <div className="flex items-center">
                    <MapPin className="text-gray-100 mr-2" size={24} />
                    <input
                      type="text"
                      placeholder="Where To?"
                      className="w-full bg-transparent text-xl focus:outline-none text-white placeholder-white placeholder-opacity-100"
                      onClick={openMobileLocationSearch}
                      value={formData.selectedLocation}
                      readOnly
                    />
                  </div>
                  
                  {/* Add checkbox for mobile with consistent styling */}
                  <div className="flex items-center px-2">
                    <input
                      type="checkbox"
                      id="mobileDifferentReturn"
                      checked={differentReturnLocation}
                      onChange={(e) => setDifferentReturnLocation(e.target.checked)}
                      className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                    />
                    <label htmlFor="mobileDifferentReturn" className="ml-2 text-white text-sm">
                      Return to a different location
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Location Search */}
          {mobileLocationSearchOpen && (
            <div className="fixed inset-0 bg-black/40 backdrop-blur-md z-50 overflow-y-auto location-search-container">
              <div className="p-4">
                <button
                  className="mb-4 text-white"
                  onClick={closeMobileLocationSearch}
                >
                  &larr; Back
                </button>
                <h2 className="text-white text-lg font-bold mb-4">Pick-up Location</h2>
                <LocationSearch
                  isOpen={true}
                  onClose={closeMobileLocationSearch}
                  onSelect={handleLocationSelect}
                  isMobile={true}
                />
              </div>
            </div>
          )}

          {/* Mobile Return Location Search */}
          {mobileReturnLocationSearchOpen && (
            <div className="fixed inset-0 bg-black/40 backdrop-blur-md z-[60] overflow-y-auto return-location-search-container">
              <div className="p-4">
                <button
                  className="mb-4 text-white"
                  onClick={() => {
                    setPreventLocationSearchClose(false);
                    closeMobileReturnLocationSearch();
                  }}
                >
                  &larr; Back
                </button>
                <h2 className="text-white text-lg font-bold mb-4">Return Location</h2>
                <ReturnLocationSearch
                  isOpen={true}
                  onClose={() => {
                    setPreventLocationSearchClose(false);
                    closeMobileReturnLocationSearch();
                  }}
                  onSelect={handleReturnLocationSelect}
                  isMobile={true}
                />
              </div>
            </div>
          )}
        </div>

        {/* Desktop Layout */}
        <div
          className="hidden lg:block h-screen bg-cover bg-center"
          style={desktopBackgroundStyle}
        >
          {/* Desktop Header */}
          <header className="absolute top-0 left-0 right-0 z-50">
            <div className="container mx-auto px-6 py-4">
              <div className="flex justify-between items-center">
                <img src={routesLogo} alt="Routes Logo" className="h-20" />
                <div className="flex items-center space-x-6">
                  <div className="flex items-center space-x-2 text-white bg-orange-500 hover:bg-orange-600 transition duration-300 ease-in-out rounded-full px-4 py-2 cursor-pointer" onClick={() => navigate("/ShuttleRequestTracking")}>
                    <BusFront size={30} />
                    <span>SHUTTLE TRACKER</span>
                  </div>

                  <button
                    className="text-white focus:outline-none"
                    onClick={toggleMenu}
                  >
                    {menuOpen ? <X size={28} /> : <Menu size={28} />}
                  </button>
                </div>
              </div>
            </div>
          </header>

          {/* Desktop Form Section */}
          <div
            className="absolute inset-0 flex items-start justify-center px-4"
            style={{ paddingTop: "35vh" }}
          >
            <div className="w-full max-w-6xl" ref={formRef}>
              {renderForm()}
            </div>
          </div>

          {/* <div className="flex flex-col justify-between h-full mb-8">
            <div className="flex-grow"></div>
            <div className="flex justify-between items-end">
              <button 
                className="bg-[#001992] text-white px-6 py-2 rounded ml-8 mb-8 hover:bg-[#0033cc] cursor-pointer" 
                style={{ zIndex: 1 }}
                onClick={() => {
                  console.log("Button clicked!")
                }}
              >
                VIEW ALL OFFERS
              </button>
              <button 
                type="button"
                className="bg-[#737373] text-white text-sm rounded-full mr-8 mb-8 hover:bg-[#8a8a8a] cursor-pointer" 
                style={{ width: '70px', height: '70px',  zIndex: 1  }}
                onClick={() => {
                  alert("Explore clicked!")
                }}
              >
                Explore
              </button>
            </div>
          </div> */}
        </div>

        {/* Additional Sections */}
        <div className="w-full">
          {/* Road Trip Ideas Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-0">
            <div
              className="relative h-[400px] hidden md:block"
              style={{ zIndex: -1 }}
            >
              <img
                src={imgExplore1}
                alt="Winter road aerial view"
                className="w-full h-full object-cover"
              />
            </div>
            <div
              className="bg-gray-200 p-8 flex flex-col justify-center items-center text-center bg-cover bg-center"
              style={{ backgroundImage: `url(${imgExplore2})` }}
            >
              <h2 className="text-3xl font-bold mb-4 text-white">
                ROAD TRIP IDEAS
              </h2>
              <p className="mb-6 text-white">
                Don't Wait, Accelerate!
                <br />
                Explore touring
                <br />
                itineraries on scenic
                <br />
                routes
              </p>
              {/* <button
                className="text-2xl text-white underline font-bold 
                  hover:text-gray-200 active:text-gray-600 
                  transition-all duration-500 cursor-pointer 
                  focus:outline-none focus:ring-2 focus:ring-white/50 
                  px-4 py-2 rounded z-10"
                onClick={() => {navigate("/offers")}}
              >
                Special Offers
              </button> */}
            </div>
          </div>

          {/* Reviews Section */}
          <div className="py-16 px-4 bg-gradient-to-b from-gray-100 to-white">
            <div className="max-w-6xl mx-auto">
              <h2 className="text-center text-3xl md:text-4xl font-bold mb-4">
                What Our Customers Say
              </h2>
              <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
                Join thousands of satisfied customers who trust Routes for their car rental needs
              </p>
              
              {/* Google Reviews */}
              <div className="mb-8 transform hover:scale-[1.02] transition-transform duration-300">
                <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-100">
                  <GoogleReviewsWidget />
                </div>
              </div>

              {/* Trustpilot Reviews */}
              <div className="transform hover:scale-[1.02] transition-transform duration-300">
                <div className="bg-white rounded-xl shadow-lg p-6 border border-gray-100">
                  <TrustPilotWidget />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add this condition for MobileCalendar */}
        {isMobile && calendarOpen && (
          <MobileCalendar
            isOpen={calendarOpen}
            onClose={closeCalendar}
            onSelect={handleDateSelect}
            initialPickupDate={null}
            initialReturnDate={null}
            onFindRide={handleMobileFindRide}
            isLoading={isLoading}
          />
        )}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        <SideMenu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />

        {/* Footer */}
        <div className="relative z-[50] pointer-events-auto">
          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        </div>
      </Suspense>
    </div>
  );
};

// Memoize the entire component to prevent unnecessary re-renders
export default React.memo(CarRentalHome);
