// Helper function to format dates to ISO string format
export const date_formater = (date) => {
  if (!date) return '';
  
  const d = new Date(date);
  
  // Check if date is valid
  if (isNaN(d.getTime())) return '';

  // Format to ISO string and remove the 'Z' at the end
  return d.toISOString().slice(0, -1);
};

// Add any other helper functions here as needed 