import React, { useState, useEffect, useRef, useMemo, memo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Users, Filter, ArrowLeft, Car, Wind } from "lucide-react";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { Switch } from "../components/ui/switch";
import Calendar from "../components/Calendar"; // Ensure Calendar component is imported
import { getVehicleRates } from "../services/api";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { useFormData } from "../context/FormContext";
import "./OptionalServices.css";
import "./Vehicle.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import dummyImage from "../components/assets/dummy.png";
import ScrollButton from "../components/ScrollButton";
import { useSession } from "../context/SessionContext";
import axios from "axios";
import routesLogo from '../components/assets/routeslogo.png';
import europcar_img from '../components/assets/europcar_img.webp';

// Add this function near the top of the file, before the VehicleCard component
const getRibbonColor = () => {
  return "linear-gradient(45deg, #FF4800, #FF6100)";
};

// Add this new component for better image handling
const VehicleImage = ({ src, alt, className }) => {
  const [imageError, setImageError] = useState(false);

  return (
    <LazyLoadImage
      src={imageError || !src ? dummyImage : src}
      alt={alt}
      effect="blur"
      className={className}
      onError={() => setImageError(true)}
      placeholderSrc={dummyImage} // Use dummy image as placeholder
      threshold={100}
      wrapperClassName="w-full h-48 flex items-center justify-center"
    />
  );
};

// Update the PriceComparisonModal component to handle undefined apiVehicleData
const PriceComparisonModal = memo(
  ({
    isOpen,
    onClose,
    vehicle,
    onPayNowClick,
    onPayLaterClick,
    getCurrencySymbol,
    rentalLength = 1,
  }) => {
    useEffect(() => {
      if (isOpen) {
        document.body.style.overflow = "hidden";
      }
      return () => {
        document.body.style.overflow = "unset";
      };
    }, [isOpen]);

    if (!isOpen || !vehicle) return null;

    // Calculate prices with fallback for rentalLength
    const poaPricePerDay = vehicle.poaData
      ? (vehicle.poaData.price_after_taxes / rentalLength).toFixed(2)
      : 0;
    const ppPricePerDay = vehicle.ppData
      ? (vehicle.ppData.price_after_taxes / rentalLength).toFixed(2)
      : 0;

    const savingsAmount =
      vehicle.poaData && vehicle.ppData
        ? (
            vehicle.poaData.price_after_taxes - vehicle.ppData.price_after_taxes
          ).toFixed(2)
        : 0;
    const savingsPercentage = vehicle.poaData
      ? ((savingsAmount / vehicle.poaData.price_after_taxes) * 100).toFixed(1)
      : 0;

    return (
      <div className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 overflow-y-auto">
        <div className="min-h-screen px-4 py-8 flex items-center justify-center">
          <div className="bg-white rounded-3xl p-4 sm:p-6 md:p-8 max-w-4xl w-full mx-auto relative shadow-2xl animate-slideUp">
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute right-4 top-4 sm:right-6 sm:top-6 text-gray-400 hover:text-gray-600 transition-colors bg-gray-100 p-2 rounded-full hover:bg-gray-200 z-10"
              aria-label="Close modal"
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <h2 className="text-2xl sm:text-2xl font-bold mb-6 sm:mb-8 text-gray-800 text-center mt-2">
              Compare & Choose
            </h2>

            <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
              {/* Vehicle Details Card */}
              <div className="w-full lg:w-1/3">
                <div className="bg-gray-50 rounded-2xl p-4 sm:p-6 shadow-sm hover:shadow-md transition-shadow duration-300">
                  <VehicleImage
                    src={vehicle.image}
                    alt={vehicle.name}
                    className="w-full h-40 sm:h-48 object-contain mb-4 transition-transform duration-300 hover:scale-105"
                  />
                  <h3 className="text-xl font-bold text-gray-800 mb-1">
                    {vehicle.name}
                  </h3>
                  <p className="text-gray-500 italic mb-4">or similar</p>
                  <div className="space-y-3">
                    {/* Vehicle Features */}
                    <div className="grid grid-cols-2 gap-3">
                      <div className="flex items-center gap-2 text-gray-600 bg-white p-2 rounded-lg">
                        <Users className="w-4 h-4 text-[#FF4800]" />
                        <span>{vehicle.passengers} Seats</span>
                      </div>
                      <div className="flex items-center gap-2 text-gray-600 bg-white p-2 rounded-lg">
                        <Car className="w-4 h-4 text-[#FF4800]" />
                        <span>{vehicle.transmission}</span>
                      </div>
                      <div className="flex items-center gap-2 text-gray-600 bg-white p-2 rounded-lg col-span-2">
                        <Wind className="w-4 h-4 text-[#FF4800]" />
                        <span>Air Conditioning</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Payment Options */}
              <div className="w-full lg:w-2/3 space-y-4 sm:space-y-6">
                {/* Pay Now Option */}
                <div className="border-2 border-[#FF4800] rounded-2xl p-4 sm:p-6 relative overflow-hidden hover:shadow-xl transition-all duration-300">
                  {/* Savings Badge */}
                  <div className="absolute -right-12 top-7 w-48 transform rotate-45 bg-gradient-to-r from-green-500 to-green-600 text-white text-center py-1 text-sm font-semibold shadow-lg">
                    Save {savingsPercentage}%
                  </div>

                  <div className="mb-4">
                    <h4 className="text-lg sm:text-xl font-bold text-gray-800 mb-2">
                      Pay now and save
                    </h4>
                    <p className="text-sm text-green-600 font-medium">
                      Best price guaranteed
                    </p>
                  </div>

                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-4 mb-4">
                    <div>
                      <div className="flex items-baseline gap-2">
                        <span className="text-2xl sm:text-3xl font-bold text-gray-800">
                          {getCurrencySymbol(vehicle.currency)}
                          {ppPricePerDay}
                        </span>
                        <span className="text-gray-600">/day</span>
                      </div>
                      <div className="flex flex-col sm:flex-row items-baseline gap-2 mt-1">
                        <span className="text-gray-400 line-through">
                          {getCurrencySymbol(vehicle.currency)}
                          {vehicle.poaData.price_after_taxes.toFixed(2)}
                        </span>
                        <span className="text-base sm:text-lg font-semibold text-gray-800">
                          {getCurrencySymbol(vehicle.currency)}
                          {vehicle.ppData.price_after_taxes.toFixed(2)} total
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={() => onPayNowClick(vehicle.ppData)}
                      className="w-full sm:w-auto bg-gradient-to-r from-[#FF4800] to-[#FF6100] text-white px-6 sm:px-8 py-3 rounded-xl hover:shadow-lg hover:scale-105 transition-all duration-300 font-semibold"
                    >
                      Pay Now
                    </button>
                  </div>
                  <p className="text-sm text-gray-500">
                    Includes all taxes and fees
                  </p>
                </div>

                {/* Pay Later Option */}
                <div className="border-2 border-gray-200 rounded-2xl p-4 sm:p-6 hover:border-orange-500 transition-all duration-300 hover:shadow-lg">
                  <div className="flex flex-col sm:flex-row justify-between items-start gap-4 mb-4">
                    <div>
                      <h4 className="text-lg sm:text-xl font-bold text-gray-800 mb-2">
                        Reserve now, pay later
                      </h4>
                      <p className="text-sm text-gray-600">
                        Flexible payment option at pickup
                      </p>
                    </div>
                    <div className="bg-gray-100 px-3 py-1 rounded-full text-sm font-medium text-gray-600">
                      Pay at counter
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-end gap-4 mb-4">
                    <div>
                      <div className="flex items-baseline gap-1">
                        <span className="text-2xl sm:text-3xl font-bold text-gray-800">
                          {getCurrencySymbol(vehicle.poaData.currency)}
                          {poaPricePerDay}
                        </span>
                        <span className="text-gray-600">/day</span>
                      </div>
                      <div className="text-base sm:text-lg font-semibold text-gray-600 mt-1">
                        {getCurrencySymbol(vehicle.poaData.currency)}
                        {vehicle.poaData.price_after_taxes.toFixed(2)} total
                      </div>
                    </div>
                    <button
                      onClick={() => onPayLaterClick(vehicle.poaData)}
                      className="w-full sm:w-auto bg-white border-2 border-[#FF4800] text-[#FF4800] px-6 sm:px-8 py-3 rounded-xl hover:bg-[#FF4800] hover:text-white transition-all duration-300 font-semibold"
                    >
                      Pay Later
                    </button>
                  </div>
                  <p className="text-sm text-gray-500">
                    Includes all taxes and fees
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

// Update the VehicleCard component to make the highlight more noticeable
const VehicleCard = memo(
  ({
    vehicle,
    onPayNowClick,
    onPayLaterClick,
    getRibbonColor,
    getCurrencySymbol,
    sourceValue,
    dataObject,
    selectedChooseDetail,
    onChooseClick,
    isHighlighted,
  }) => {
    const showChooseButton = vehicle.hasMultiplePaymentOptions;

    return (
      <Card
        className={`overflow-hidden bg-white text-black hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 rounded-xl ${
          isHighlighted
            ? "border-[#FF6100] border-4 shadow-lg ring-4 ring-[#FF6100]/30 scale-105"
            : "border border-gray-100"
        } relative`}
      >
        {/* Add highlight badge if highlighted */}
        {isHighlighted && (
          <div className="absolute top-1 left-1 z-10 bg-gradient-to-r from-[#FF4800] to-[#FF6100] text-white px-2 py-1 rounded-full text-xs shadow-lg">
            Selected from SkyScanner
          </div>
        )}
        <div className="flex flex-col h-full">
          {/* Gradient overlay for image container */}
          <div className="relative bg-gradient-to-b from-gray-50 to-white p-4 overflow-hidden">
            <VehicleImage
              src={vehicle.image}
              alt={vehicle.name}
              className="w-full h-48 object-contain hover:scale-105 transition-transform duration-300"
            />
            <div
              style={{
                position: "absolute",
                right: "-32px",
                top: "20px",
                width: "150px",
                transform: "rotate(45deg)",
                background: getRibbonColor(vehicle.class_ribbon),
                color: "white",
                padding: "5px 0",
                textAlign: "center",
                fontSize:
                  vehicle.class_ribbon === "Intermediate" ? "0.60rem" : "0.75rem",
                fontWeight: "600",
                textTransform: "uppercase",
                boxShadow: "0 4px 4px rgba(0,0,0,0.2)",
                zIndex: "10",
              }}
            >
              {vehicle.class_ribbon}
            </div>
          </div>
          {/* service provided by starts */}
          <div className="flex items-center justify-start gap-2 px-4 py-1">
            <span className="text-sm text-gray-600">Service provided by</span>
            <div className="flex items-center gap-2">
              <img 
                src={sourceValue.selectedLocationSource === 'rms' ? routesLogo : europcar_img} 
                alt={`${sourceValue.selectedLocationSource} logo`}
                className="h-5 w-auto object-contain"
              />
              {["Chile", "Australia", "New Zealand", "Barbados", "Cayman Islands", "Trinidad And Tobago"]
              .includes(sourceValue.selectedLocation.split(',').map(part => part.trim()).pop()) && (
                <img 
                  src={europcar_img} 
                  alt={`${sourceValue.selectedLocationSource} logo`}
                  className="h-5 w-auto object-contain"
                />
              )}
            </div>
          </div>
          {/* service provided by ends */}
          <div className="p-6 flex flex-col flex-grow">
            <div className="flex justify-between items-start mb-6">
              {/* Vehicle Name Section */}
              <div>
                <h3 className="group relative inline-flex flex-col">
                  <span className="text-xl font-bold bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text text-transparent mb-1">
                    {vehicle.name}
                  </span>
                  <span className="text-sm font-medium text-gray-500 italic">
                    or Similar
                  </span>
                  <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-[#BB7E55] to-[#FF8942] group-hover:w-full transition-all duration-300"></span>
                </h3>
              </div>

              {/* Price Section */}
              <div className="text-right min-w-[150px]">
                {" "}
                {/* Added min-width for consistency */}
                {/* Daily Rate */}
                <div className="mb-4">
                  {" "}
                  {/* Increased margin-bottom */}
                  <div className="flex items-center justify-end mb-2">
                    {" "}
                    {/* Added margin-bottom */}
                    <span className="text-sm font-medium text-gray-800 pb-[3px]">
                      {getCurrencySymbol(vehicle.currency)}
                    </span>
                    <span className="text-2xl font-extrabold text-gray-800 leading-none">
                      {" "}
                      {/* Added leading-none */}
                      {vehicle.pricePerDay.toFixed(2)}
                    </span>
                    <span className="text-sm text-gray-800 font-medium self-end -mb-[3px]">
                      /day
                    </span>{" "}
                    {/* Adjusted alignment */}
                  </div>
                  {/* <div>
                  <span className="text-xs font-semibold text-[#46A24A] bg-[#46A24A]/10 px-2.5 py-1 rounded-full">
                    DAILY RATE
                  </span>
                </div> */}
                </div>
                {/* Total Price */}
                <div>
                  <div className="flex items-end justify-end mb-1">
                    {" "}
                    {/* Parent already has items-end */}
                    <span className="text-sm font-medium text-gray-500">
                      {getCurrencySymbol(vehicle.currency)}
                    </span>
                    <span className="text-lg font-bold text-gray-500 leading-none">
                      {" "}
                      {/* Added leading-none */}
                      {vehicle.price.toFixed(2)}
                    </span>
                    <span className="text-sm text-gray-500 font-medium ml-1 -mb-[3px]">
                      total
                    </span>{" "}
                    {/* Added ml-1 for spacing and mb-0.5 for fine-tuning */}
                  </div>
                  <div className="text-xs text-gray-500 font-medium">
                    Includes all taxes & fees
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 text-sm mb-1">
              <div className="flex flex-col items-center p-2 bg-gray-50 rounded-lg">
                <Car className="w-5 h-5 mb-1 text-gray-600" />
                <span className="text-gray-600 font-medium">
                  {vehicle.transmission}
                </span>
              </div>
              <div className="flex flex-col items-center p-2 bg-gray-50 rounded-lg">
                <Users className="w-5 h-5 mb-1 text-gray-600" />
                <span className="text-gray-600 font-medium">
                  {vehicle.passengers} Seats
                </span>
              </div>
              <div className="flex flex-col items-center p-2 bg-gray-50 rounded-lg">
                <Wind className="w-5 h-5 mb-1 text-gray-600" />
                <span className="text-sm font-semibold text-gray-500">AC</span>
              </div>
            </div>

            <div className="mt-auto">
              <Button
                onClick={() =>
                  showChooseButton
                    ? onChooseClick(vehicle)
                    : vehicle.poaData
                    ? onPayLaterClick(vehicle)
                    : onPayNowClick(vehicle)
                }
                className="group w-full relative py-4 rounded-xl font-semibold text-white
                bg-gradient-to-r from-[#FF4800] to-[#FF6100]
                hover:scale-[1.02] active:scale-98 transition-all duration-300
                shadow-[0_2px_6px_rgba(255,97,0,0.1)]"
              >
                <div className="absolute inset-0 rounded-xl bg-[#FF4800] opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                <div className="absolute -inset-px bg-gradient-to-r from-[#FF4800] via-[#FF6100] to-[#FF4800] rounded-xl blur-lg opacity-20 group-hover:opacity-40 transition-opacity duration-500"></div>
                <div className="absolute inset-px bg-gradient-to-r from-[#FF4800] to-[#FF6100] rounded-[11px]"></div>
                <div className="relative flex items-center justify-center">
                  <div className="flex items-center gap-2 group-hover:gap-4 transition-all duration-300">
                    <span className="text-lg tracking-wide">
                      {showChooseButton ? "CHOOSE" : "BOOK NOW"}
                    </span>
                    <svg
                      className="w-5 h-5 transform group-hover:translate-x-1 transition-transform duration-300"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
);

const FilterSection = memo(({ filters, handleFilterChange, handleSubTypeChange, handleSubTypeChange2, carClasses, carSubClasses, selectedVehicleTypes, selectedSubTypes, handleVehicleTypeChange, sourceValue }) => {
  return (
    <Card className="bg-white text-black">
      <CardContent className="p-6">
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-semibold mb-2 pb-2 border-b border-gray-200 relative">
              Sort By Price
              <span className="absolute bottom-0 left-0 w-40 h-0.5 bg-[#FF6100]"></span>
            </h3>
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <span>Low to High</span>
                <Switch
                  checked={filters.price === 'lowToHigh'}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      handleFilterChange('price', 'lowToHigh');
                    } else {
                      handleFilterChange('price', 'lowToHigh');
                    }
                  }}
                />
              </div>
              <div className="flex items-center justify-between">
                <span>High to Low</span>
                <Switch
                  checked={filters.price === 'highToLow'}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      handleFilterChange('price', 'highToLow');
                    } else {
                      handleFilterChange('price', 'lowToHigh');
                    }
                  }}
                />
              </div>
            </div>
          </div>          
          
          { sourceValue.selectedLocationSource === 'rms' && !["Chile", "Australia", "New Zealand", "Barbados", "Cayman Islands", "Trinidad And Tobago"]
              .includes(sourceValue.selectedLocation.split(',').map(part => part.trim()).pop()) &&
          <div>
            <h3 className="text-lg font-semibold mb-2 pb-2 border-b border-gray-200 relative">
              Car Type
              <span className="absolute bottom-0 left-0 w-40 h-0.5 bg-[#FF6100]"></span>
            </h3>
            <div className="space-y-2">
              {carClasses.map((type) => (
                <div key={type} className="flex flex-col">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedVehicleTypes?.includes(type === 'All Vehicles' ? type : type?.toLowerCase())}
                      onChange={(e) => handleVehicleTypeChange(type, e.target.checked)}
                      className="mr-3 w-5 h-5 border-2 border-gray-300 rounded-sm checked:bg-[#FF6100] checked:border-[#FF6100] transition-all duration-300"
                    />
                    <div className="car-type-content">
                      <span className="text-lg font-medium hover-text-orange">{type}</span>
                    </div>
                  </div>

                  <hr></hr>
        
                  {type !== 'All Vehicles' && selectedVehicleTypes.includes(type?.toLowerCase()) && carSubClasses.map((subType) => {
                  const splitSubType = subType.split('-');

                  if (splitSubType[1] === type) {
                    return (
                      <div key={subType} className="flex items-center justify-between ml-4">
                        <span>{splitSubType[0]}</span> 
                        <input
                          type="checkbox"
                          checked={selectedSubTypes.some((st) => st?.type === type && st?.subType === subType?.toLowerCase())}
                          onChange={(e) => {
                            handleSubTypeChange(type, subType, e.target.checked); 
                            handleSubTypeChange2(subType, e.target.checked);
                          }}
                        />
                      </div>
                    );
                  }
                  return null;
                })}
                </div>
              ))}
            </div>
          </div>
}



          <div>
            <h3 className="text-lg font-semibold mb-2 pb-2 border-b border-gray-200 relative">
              Transmission
              <span className="absolute bottom-0 left-0 w-40 h-0.5 bg-[#FF6100]"></span>
            </h3>
            <select 
              defaultValue="all"
              onChange={(e) => handleFilterChange('transmission', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#FF6100] focus:border-transparent"
            >
              <option value="all">All</option>
              <option value="automatic">Automatic</option>
              <option value="manual">Manual</option>
            </select>
          </div>
          
          <div>
            <h3 className="text-lg font-semibold mb-2 pb-2 border-b border-gray-200 relative">
              Seats
              <span className="absolute bottom-0 left-0 w-40 h-0.5 bg-[#FF6100]"></span>
            </h3>
            <select 
              defaultValue="all"
              onChange={(e) => handleFilterChange('seats', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#FF6100] focus:border-transparent"
            >
              <option value="all">All</option>
              <option value="2">2+</option>
              <option value="4">4+</option>
              <option value="6">6+</option>
            </select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
});

// Add this new component at the top of the file
const LoadingOverlay = () => (
  <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9999] flex items-center justify-center">
    <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 flex flex-col items-center gap-4 shadow-2xl border border-white/20">
      <div className="relative">
        <div className="w-16 h-16 border-4 border-[#FF6100]/30 border-t-[#FF6100] rounded-full animate-spin"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-10 h-10 border-4 border-[#A66D44]/30 border-t-[#A66D44] rounded-full animate-spin"></div>
        </div>
      </div>
      <div className="text-white text-center">
        <p className="text-lg font-semibold mb-1">Loading Vehicles</p>
        <p className="text-sm text-white/80">
          Please wait while we fetch the best rates...
        </p>
      </div>
    </div>
  </div>
);

const VehicleListing = () => {
  const location = useLocation();
  const apiVehicleData = location.state?.vehicleData;
  const [vehicles, setVehicles] = useState([]);
  const [filters, setFilters] = useState({
    price: "lowToHigh",
    vehicleType: "All Vehicles",
    transmission: "all",
    seats: "all",
  });

  const { formData, updateFormData } = useFormData();
  // Form-related state and refs
  const [locationSearchOpen, setLocationSearchOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const locationInputRef = useRef(null);
  const timePickerRef = useRef(null);
  const navigate = useNavigate();

  // Add this state near other state declarations
  const [isFilterVisible, setIsFilterVisible] = useState(false);

// Add this new state to store unique car classes
const [carClasses, setCarClasses] = useState([]);

const [carSubClasses, setCarSubClasses] = useState([]);


// Update the state to handle multiple selections
const [selectedVehicleTypes, setSelectedVehicleTypes] = useState(['All Vehicles']);

const [selectedSubTypes, setSelectedSubTypes] = useState([]);

  // Add these state declarations near the top of the VehicleListing component
  const [sourceValue, setSourceValue] = useState(formData || "rms"); // Default value
  const [dataObject, setDataObject] = useState({
    country_name: "", // Will be populated from API
  });
  useEffect(()=>{
    setSourceValue(formData || "rms");
  },[formData])

  const [selectedChooseDetail, setSelectedChooseDetail] = useState([
    {
      car_detail: {
        SIPP_code: "",
        mileage: 200,
        ceil: "0.25",
      },
    },
  ]);

  const { resetTimer } = useSession();

  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showPriceModal, setShowPriceModal] = useState(false);

  const [searchParams] = useSearchParams();

  // Add this state near other state declarations
  const [isSkyScannerProcessed, setIsSkyScannerProcessed] = useState(false);

  // Add these state variables near the top with other state declarations
  const [persistentSkyscannerId, setPersistentSkyscannerId] = useState(null);
  const [persistentSippCode, setPersistentSippCode] = useState(null);

  // Add this new state to track if pixel has been fired
  const [pixelFired, setPixelFired] = useState(false);

  // Add this near the top with other refs
  const pixelFiredRef = useRef(false);

  // Add this state near other state declarations
  const [isLoadingFromDeepLink, setIsLoadingFromDeepLink] = useState(false);

  useEffect(() => {
    resetTimer();
    const fetchVehicles = () => {
      setVehicles([]); // Clear previous vehicles

      if (!apiVehicleData) return; // Add early return if no data

      const processVehicleData = (data, type) => {
        if (!data) return [];

        return Object.entries(data).map(([sippCode, value]) => {
          const rentalLength = apiVehicleData?.length_of_rental || 1;
          const vehicleSippCode = value.car_detail.SIPP_code || sippCode;

         

          return {
            id: value.car_detail._id,
            name: `${value.car_detail.make} ${value.car_detail.model}`,
            class: value.car_detail.car_class+'-'+value.car_detail.car_main_class,
            class_ribbon: value.car_detail.car_class,
            image: value.car_detail.image_path.includes("http")
              ? value.car_detail.image_path
              : `${process.env.REACT_APP_API_URL}/static/img/${value.car_detail.image_path}`,
            price: value.price_after_taxes,
            pricePerDay: value.price_after_taxes / rentalLength,
            transmission: value.car_detail.car_transmission,
            passengers: value.car_detail.seat_count,
            luggage: 2,
            type: value.car_detail.car_type,
            maincarclass: value.car_detail.car_main_class,
            currency: value.currency,
            sippCode: vehicleSippCode,
            distance_unit: value.distance_unit,
            paymentType: type,
            hasMultiplePaymentOptions:
              apiVehicleData?.TYPE === "BOTH" &&
              apiVehicleData?.PP?.[sippCode] &&
              apiVehicleData?.POA?.[sippCode],
            ppData: apiVehicleData?.PP?.[sippCode],
            poaData: apiVehicleData?.POA?.[sippCode],
          };
        });
      };

      let allVehicles = [];

      // Handle different TYPE scenarios with null checks
      switch (apiVehicleData?.TYPE) {
        case "BOTH":
          // Process PP vehicles
          if (apiVehicleData?.PP) {
            allVehicles = [...processVehicleData(apiVehicleData.PP, "PP")];
          }
          // Add POA vehicles that don't have PP counterpart
          if (apiVehicleData?.POA) {
            const poaVehicles = processVehicleData(
              apiVehicleData.POA,
              "POA"
            ).filter(
              (poaVehicle) =>
                !allVehicles.some((v) => v.sippCode === poaVehicle.sippCode)
            );
            allVehicles = [...allVehicles, ...poaVehicles];
          }
          break;

        case "POA":
          // Process only POA vehicles
          if (apiVehicleData?.POA) {
            allVehicles = processVehicleData(apiVehicleData.POA, "POA");
          }
          break;

        case "PP":
          // Process only PP vehicles
          if (apiVehicleData?.PP) {
            allVehicles = processVehicleData(apiVehicleData.PP, "PP");
          }
          break;

        default:
          console.warn("Unknown vehicle data type:", apiVehicleData?.TYPE);
      }

      setVehicles(allVehicles);

 // Extract unique car classes and update carClasses state
 const uniqueClasses = [...new Set(allVehicles.map(v => v.maincarclass))];
 setCarClasses(['All Vehicles', ...uniqueClasses]);


 const uniqueSubClasses = [...new Set(allVehicles.map(v => v.class))];
 setCarSubClasses([...uniqueSubClasses]);



      // Ensure "All Vehicles" is selected by default
      setSelectedVehicleTypes(["All Vehicles"]);
    };

    fetchVehicles();
  }, [apiVehicleData]); // Add apiVehicleData as dependency

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationInputRef.current &&
        !locationInputRef.current.contains(event.target)
      ) {
        setLocationSearchOpen(false);
      }
      if (
        timePickerRef.current &&
        !timePickerRef.current.contains(event.target)
      ) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update the vehicle type filter handler
 
  // Update the vehicle type filter handler
  const handleVehicleTypeChange = (type, isChecked) => {
    if (type === 'All Vehicles') {
        // If "All Vehicles" is checked, uncheck all other types
        if (isChecked) {
            setSelectedVehicleTypes(['All Vehicles']);
            setSelectedSubTypes([]); // Uncheck all subtypes when "All Vehicles" is checked
        } else {
            // If "All Vehicles" is unchecked, allow other selections
            setSelectedVehicleTypes(selectedVehicleTypes.filter(t => t == 'All Vehicles'));
        }
    } else {
        // For other vehicle types
        if (isChecked) {
            // Uncheck "All Vehicles" if another type is checked
            setSelectedVehicleTypes(prev => {
                const newTypes = prev.filter(t => t !== 'All Vehicles');
                return [...newTypes, type.toLowerCase()];
            });
        } else {
            // Remove the unselected type
            const updatedTypes = selectedVehicleTypes.filter(t => t !== type.toLowerCase());
            setSelectedVehicleTypes(updatedTypes);

            // Check if no vehicle types are selected
            if (updatedTypes.length === 0) {
                setSelectedVehicleTypes(['All Vehicles']);
                setSelectedSubTypes([]); // Check "All Vehicles" if none are selected
            }
        }
    }
};


// Add a similar function for subTypes
const handleSubTypeChange = (type, subType, isChecked) => {
  
  setSelectedSubTypes(prev => {
      let newSubTypes = [...prev];

      if (isChecked) {
          if (!newSubTypes.some(t => t.type === type && t.subType === subType.toLowerCase())) {
              newSubTypes.push({ type, subType: subType.toLowerCase() });

              handleSubTypeChange2(subType, isChecked);
                      }
      } else {
          newSubTypes = newSubTypes.filter(t => !(t.type === type && t.subType === subType.toLowerCase()));

          // if (newSubTypes.length === 0) {
          //   setSelectedVehicleTypes(['All Vehicles']);}
      }
   
      return newSubTypes;
  });
};
// the problem im facing is that when i click on the subtype of one type then the subtype of all the other type is being selected


const handleSubTypeChange2 = (subType, isChecked) => {
  setSelectedSubTypes(prev => {
      let newSubTypes = [...prev];

      if (isChecked) {
          if (!newSubTypes.includes(subType.toLowerCase())) {
              newSubTypes.push(subType.toLowerCase());
          }
      } else {
          newSubTypes = newSubTypes.filter(t => t !== subType.toLowerCase());

          // if (newSubTypes.length === 0) {
          //   setSelectedVehicleTypes(['All Vehicles']);}
      }

      return newSubTypes;
  });
};


  const handleLocationSelect = (location, locationId) => {
    updateFormData({
      selectedLocation: location,
      selectedLocationId: locationId,
      selectedReturnLocation: location,
      selectedReturnLocationId: locationId
    });
    setLocationSearchOpen(false);
  };

  const handleFindMyRide = async (e) => {
    
    e.preventDefault();

    try {
      const pickupDateObj = new Date(formData.pickupDate);
      const dropoffDateObj = new Date(formData.returnDate);

      const searchData = {
        pickupDate: pickupDateObj,
        dropoffDate: dropoffDateObj,
        pickupTime: formData.pickupTime,
        dropoffTime: formData.returnTime,
        locationId: formData.selectedLocationId,
      };

      const response = await getVehicleRates(searchData);
      navigate("/vehicle", {
        state: { vehicleData: response },
      });
    } catch (error) {
      console.error("Error fetching vehicle rates:", error);
    }
  };

  // Define the generateTimeOptions function
  const generateTimeOptions = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hour = i % 12 || 12;
        const period = i < 12 ? "AM" : "PM";
        times.push(
          `${hour.toString().padStart(2, "0")}:${j
            .toString()
            .padStart(2, "0")} ${period}`
        );
      }
    }
    return times;
  };

  // Update the date selection handler
  const handleDateSelect = (start, end) => {
    if (start && end) {
      // Add null check
      updateFormData({
        pickupDate: start.toLocaleDateString(),
        returnDate: end.toLocaleDateString(),
      });
    }
    setCalendarOpen(false);
  };

  // Add this helper function
  // const scrollToTop = () => {
  //   vehicleGridRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // Update the other filter handlers
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    // scrollToTop();
  };

  // Update the handlePayNowClick function
  const handlePayNowClick = (vehicleData, rateCode = "PNI") => {
    // Get the skyscanner ID and SIPP code from URL params or persistent state
    const skyscannerId = searchParams.get('skyscanner_redirectid') || persistentSkyscannerId;
    const skyScannerSippCode = searchParams.get('SIPP_Code') || persistentSippCode;

    // If we have skyscanner data, fire the pixel for checkout
    if (skyscannerId && skyScannerSippCode) {
      const pixelData = {
        redirectId: skyscannerId,
        price: vehicleData.price_after_taxes?.toFixed(2) || vehicleData.price.toFixed(2),
        currency: vehicleData.currency
      };

      // Fire pixel for checkout page
      const pixel = new Image();
      pixel.src = `https://analytics.skyscanner.net/dv/v1/event.png?skyscanner_redirectid=${
        pixelData.redirectId
      }&tracking_source_id=DV-942169-3&event_type=pre-conversion&occurred_at=${encodeURIComponent(
        new Date().toISOString()
      )}&price=${pixelData.price}&payment_fee=0.00&currency=${
        pixelData.currency
      }&stage=checkout-page`;

      pixel.onload = () => {
        console.log("Checkout pixel fired successfully");
      };

      pixel.onerror = (e) => {
        console.error("Error loading checkout pixel:", e);
      };
    }

    // Determine if this is direct API data or processed vehicle data
    const isPP = vehicleData.car_detail;
    const sippCode = isPP ? vehicleData.car_detail.SIPP_code : vehicleData.sippCode;

    // Get the correct data source based on type
    let apiData;
    if (isPP) {
      // If it's direct API data, use it as is
      apiData = vehicleData;
    } else {
      // If it's processed vehicle data, get the appropriate data based on rate code
      apiData =
        rateCode === "NI"
          ? apiVehicleData?.POA[sippCode]
          : apiVehicleData?.PP[sippCode]; // Fallback to POA if PP doesn't exist
    }

    // If no valid data is found, log error and return
    if (!apiData) {
      console.error("No valid vehicle data found");
      return;
    }

    const vehicleDetails = {
      name: `${apiData.car_detail.make} ${apiData.car_detail.model}`,
      price: apiData.price_after_taxes,
      pricePerDay: apiData.price_after_taxes / apiVehicleData.length_of_rental,
      // image: apiData.car_detail.image_path
      //   ? `${process.env.REACT_APP_API_URL}/static/img/${apiData.car_detail.image_path}`
      //   : "/placeholder.svg",
      image: apiData.car_detail.image_path.includes("http")
              ? apiData.car_detail.image_path
              : `${process.env.REACT_APP_API_URL}/static/img/${apiData.car_detail.image_path}`,
      class: apiData.car_detail.car_class,
      carCode: apiData.car_detail.SIPP_code,
      features: {
        seats: apiData.car_detail.seat_count,
        bags: 2,
        doors: apiData.car_detail.door_count || 4,
        transmission: apiData.car_detail.car_transmission,
        ac: apiData.car_detail.car_ac === "AC" ? true : false,
        age: 25,
      },
      currency: apiData.currency,
      distance_unit: apiData.distance_unit,
      type: apiData.car_detail.car_type,
      Rate_Code: rateCode,
      rentalDetails: {
        numberOfDays: apiVehicleData.length_of_rental || 1,
        ratePerDay: apiData.base_rate,
        subtotal: apiData.base_rate,
        taxesAndFees: apiData.total_taxes || 0,
        total: apiData.price_after_taxes,
      },
      additionalDetails: {
        mileage: apiData.car_detail.mileage || 200,
        mileageCharge: apiData.car_detail.ceil || "",
        hasAirportSurcharge: true,
        hasStateTax: true,
        hasEnhancedCleaning: true,
        isInTerminal: false,
      },
      taxes_detail: {
          ...apiData.taxes_detail?.baserate_tax_charge,
          ...apiData.taxes_detail?.calculated_tax_charge,
          ...apiData.taxes_detail?.independent_tax_charge
      },
    };

    // Navigate to checkout with vehicle details in state
    navigate("/checkout", {
      state: {
        vehicleDetails: vehicleDetails,
        skyscannerData: {
          redirectId: skyscannerId,
          sippCode: skyScannerSippCode,
          price: vehicleData.price_after_taxes?.toFixed(2) || vehicleData.price.toFixed(2),
          currency: vehicleData.currency
        }
      },
    });
  };

  // Update the handlePayLaterClick function
  const handlePayLaterClick = (vehicleData) => {
    setShowPriceModal(false);
    // Get POA data
    const sippCode = vehicleData.car_detail
      ? vehicleData.car_detail.SIPP_code
      : vehicleData.sippCode;
    const poaData = vehicleData.car_detail
      ? vehicleData
      : apiVehicleData?.POA[sippCode];
    handlePayNowClick(poaData, "NI"); // Pass 'NI' as the rate code for Pay Later
  };

  // Update the filteredVehicles useMemo
  const filteredVehicles = useMemo(() => {
    // Get SIPP code from either search params or location state
    const skyScannerSippCode =
      searchParams.get("SIPP_Code") || location.state?.skyScannerSippCode;

    // First filter the vehicles
    const filteredVehicles = vehicles.filter((vehicle) => {
      const matchesType =
        selectedVehicleTypes.includes("All Vehicles") ||
        selectedVehicleTypes.some(
          (type) => vehicle.maincarclass.toLowerCase() === type
        );

        const matchesSubType = selectedSubTypes.length === 0 || 
        selectedSubTypes
        .filter((_, index) => index % 2 === 0) // Take elements at even indices
        .some((subType) => {
          return vehicle.maincarclass.toLowerCase() === subType.type.toLowerCase() && 
                 vehicle.class.toLowerCase() === subType.subType.toLowerCase();
        });

      const matchesTransmission =
        filters.transmission === "all" ||
        vehicle.transmission.toLowerCase() === filters.transmission;

      const matchesSeats =
        filters.seats === "all" ||
        vehicle.passengers >= parseInt(filters.seats);

      return matchesType && matchesSubType && matchesTransmission && matchesSeats;
    });

    // Then sort the filtered vehicles
    return filteredVehicles.sort((a, b) => {
      // First priority: SkyScanner SIPP code match
      if (skyScannerSippCode) {
        if (
          a.sippCode === skyScannerSippCode &&
          b.sippCode !== skyScannerSippCode
        )
          return -1;
        if (
          a.sippCode !== skyScannerSippCode &&
          b.sippCode === skyScannerSippCode
        )
          return 1;
      }

      // Second priority: price sorting
      if (filters.price === "lowToHigh") return a.price - b.price;
      if (filters.price === "highToLow") return b.price - a.price;
      return 0;
    });
  }, [vehicles, selectedVehicleTypes, filters,selectedSubTypes, searchParams, location.state]);

  // Add this function to handle back navigation
  const handleBackClick = () => {
    window.history.back();
  };

  // Helper function to get currency symbol
  // const getCurrencySymbol = (currency) => {
  //   switch (currency) {
  //     case "USD":
  //       return "$";
  //     case "CAD":
  //       return "CA$";
  //     case "EUR":
  //       return "€";
  //     case "GBP":
  //       return "£";
  //     default:
  //       return "$";
  //   }
  // };
  const getCurrencySymbol = (currencyCode) => {
    // Special handling for USD and CAD
    if (currencyCode === "USD") {
      return "$";
    } else if (currencyCode === "CAD") {
      return "CA$"; // Updated to use CA$ for CAD
    }
  
    try {
      // Using Intl.NumberFormat to get the currency symbol for other currencies
      return new Intl.NumberFormat("en", {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
        .format(0)
        .replace(/[0-9]/g, "")
        .trim();
    } catch (error) {
      // Fallback to currency code if symbol is not available
      return currencyCode + " ";
    }
  };
  // Memoize expensive computations
  const sortedAndFilteredVehicles = useMemo(() => {
    // Just return filteredVehicles directly since sorting is already handled there
    return filteredVehicles;
  }, [filteredVehicles]);

  // Add handlers for the price comparison modal
  const handleChooseClick = (vehicle) => {
    setSelectedVehicle(vehicle);
    setShowPriceModal(true);
  };

  // Add intersection observer for infinite scrolling
  const [visibleVehicles, setVisibleVehicles] = useState(12);
  const loadMoreRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          sortedAndFilteredVehicles.length > visibleVehicles
        ) {
          setVisibleVehicles((prev) =>
            Math.min(prev + 12, sortedAndFilteredVehicles.length)
          );
        }
      },
      { threshold: 0.1 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [sortedAndFilteredVehicles.length, visibleVehicles]);

  // Reset visible vehicles when filters change
  useEffect(() => {
    setVisibleVehicles(12);
  }, [filters, selectedVehicleTypes]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means this runs once when component mounts

  useEffect(() => {
    const handleSkyScanner = async () => {
      // Check if we're on the SkyScanner route and haven't processed it yet
      if (
        window.location.pathname.includes("/vehicle/SkyScanner") &&
        !isSkyScannerProcessed &&
        !apiVehicleData
      ) {
        try {
          setIsLoadingFromDeepLink(true); // Show loading overlay
          setIsSkyScannerProcessed(true);

          const pickupDate = searchParams.get("pickup_date");
          const pickupTime = searchParams.get("pickup_time");
          const dropoffDate = searchParams.get("dropoff_date");
          const dropoffTime = searchParams.get("dropoff_time");
          const pickupLocation = searchParams.get("pickup_location");
          const skyScannerId = searchParams.get("skyscanner_redirectid");
          const sippCode = searchParams.get("SIPP_Code");

          if (
            !pickupDate ||
            !pickupTime ||
            !dropoffDate ||
            !dropoffTime ||
            !pickupLocation
          ) {
            console.error("Missing required SkyScanner parameters");
            setIsLoadingFromDeepLink(false);
            return;
          }

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/get_country_city_airport_name`
          );
          const locations = response.data;

          const pickupPost = locations.find((post) =>
            post.location_id
              .split("-")[1]
              .toLowerCase()
              .startsWith(pickupLocation.toLowerCase())
          );

          if (!pickupPost) {
            throw new Error("Location not found");
          }

          const formatLocationDisplay = (location) => {
            return location.location_id
              ? `${location.airport_code} ${location.airport_name}, ${location.country_name}`
              : `${location.airport_code} ${location.city}, ${location.country_name}`;
          };

          const formatTimeTo12Hour = (time) => {
            const [hour, minute] = time.split(':');
            const period = hour >= 12 ? 'PM' : 'AM';
            const formattedHour = String(hour % 12 || 12).padStart(2, '0'); // Convert to 12-hour format and ensure two digits
            const formattedMinute = minute.padStart(2, '0'); // Ensure minutes are two digits
            return `${formattedHour}:${formattedMinute} ${period}`;
        };
        
          const searchData = {
            pickupDate: new Date(pickupDate),
            dropoffDate: new Date(dropoffDate),
            pickupTime,
            dropoffTime,
            locationId: pickupPost.location_id,
            source: "SkyScanner",
            sippCode,
          };

          // Get vehicle rates first
          const vehicleRatesResponse = await getVehicleRates(searchData);

          if (vehicleRatesResponse) {
            // Update form data 
            await updateFormData({
              selectedLocation: formatLocationDisplay(pickupPost),
              selectedLocationId: pickupPost.location_id,
              pickupDate: new Date(pickupDate).toLocaleDateString(),
              pickupTime: formatTimeTo12Hour(pickupTime),
              returnDate: new Date(dropoffDate).toLocaleDateString(),
              returnTime: formatTimeTo12Hour(dropoffTime),
              skyScannerId,
              sippCode,
              source: "SkyScanner",
            });

            // Navigate with both vehicle data and SIPP code
            navigate("/vehicle", {
              state: {
                vehicleData: vehicleRatesResponse,
                skyScannerSippCode: sippCode,
              },
              replace: true,
            });
          }
        } catch (error) {
          console.error("Error processing SkyScanner parameters:", error);
        } finally {
          setIsLoadingFromDeepLink(false); // Hide loading overlay
        }
      }
    };

    handleSkyScanner();
  }, [
    searchParams,
    updateFormData,
    navigate,
    apiVehicleData,
    isSkyScannerProcessed,
  ]);

  useEffect(() => {
    // Get values from URL params
    const urlSkyscannerId = searchParams.get('skyscanner_redirectid');
    const urlSippCode = searchParams.get('SIPP_Code');
    
    // If we have new values from URL, update our persistent state
    if (urlSkyscannerId && urlSippCode) {
      setPersistentSkyscannerId(urlSkyscannerId);
      setPersistentSippCode(urlSippCode);
    }

    // Use either URL values or persistent values
    const currentSkyscannerId = urlSkyscannerId || persistentSkyscannerId;
    const currentSippCode = urlSippCode || persistentSippCode;

    // Check pixelFiredRef instead of pixelFired state
    if (!pixelFiredRef.current && currentSkyscannerId && currentSippCode && vehicles.length > 0) {
      // Find the matching vehicle
      const matchingVehicle = vehicles.find(vehicle => vehicle.sippCode === currentSippCode);
      
      if (matchingVehicle) {
        // Set ref to true immediately to prevent double firing
        pixelFiredRef.current = true;

        // Set pixel data and fire pixel in one go
        const pixelData = {
          redirectId: currentSkyscannerId,
          price: matchingVehicle.price_after_taxes?.toFixed(2) || matchingVehicle.price.toFixed(2),
          currency: matchingVehicle.currency
        };
        
        // Create and fire pixel immediately
        const pixel = new Image();
        pixel.src = `https://analytics.skyscanner.net/dv/v1/event.png?skyscanner_redirectid=${
          pixelData.redirectId
        }&tracking_source_id=DV-942169-3&event_type=pre-conversion&occurred_at=${encodeURIComponent(
          new Date().toISOString()
        )}&price=${pixelData.price}&payment_fee=0.00&currency=${
          pixelData.currency
        }&stage=dayview-page`;
        
        pixel.onload = () => {
          console.log("Pixel fired successfully");
          setPixelFired(true);
        };
        
        pixel.onerror = (e) => {
          console.error("Error loading pixel:", e);
          setPixelFired(true);
        };
      }
    }
  }, [searchParams, vehicles, persistentSkyscannerId, persistentSippCode]); // Remove pixelFired from dependencies

  return (
    <div className="min-h-screen bg-white text-black">
      {/* Add the loading overlay */}
      {isLoadingFromDeepLink && <LoadingOverlay />}

      <div className="relative" style={{ zIndex: 50 }}>
        <MainHeader
          selectedLocation={formData.selectedLocation}
          pickupDate={formData.pickupDate}
          returnDate={formData.returnDate}
          pickupTime={formData.pickupTime}
          returnTime={formData.returnTime}
          onLocationSelect={handleLocationSelect}
          onDateSelect={handleDateSelect}
          onTimeChange={(type, value) => {
            updateFormData({
              [type === "pickup" ? "pickupTime" : "returnTime"]: value,
            });
          }}
          onSearch={handleFindMyRide}
          timeOptions={generateTimeOptions()}
        />
      </div>

      <div
        className="container mx-auto px-4"
        style={{ position: "relative", zIndex: 1 }}
      >
        <div className="flex justify-between items-center mt-4">
          {/* <button
            onClick={handleBackClick}
            className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <ArrowLeft size={20} />
            <span>Go Back</span>
          </button> */}

          {/* Mobile Filter Button */}
          <span className="ml-2 text-gray-600"></span> 
          <button
            onClick={() => setIsFilterVisible(!isFilterVisible)}
            className="lg:hidden flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
          >
            <Filter size={20} className="text-gray-600" />
            {/* <span className="ml-2 text-gray-600">Filter</span> */}
          </button>
        </div>
      </div>

      {/* Calendar Component */}
      {calendarOpen && (
        <Calendar
          isOpen={calendarOpen}
          onClose={() => setCalendarOpen(false)}
          onSelect={(start, end) => {
            updateFormData({
              pickupDate: start.toLocaleDateString(),
              returnDate: end.toLocaleDateString(),
            });
            setCalendarOpen(false);
          }}
        />
      )}

      {/* Main Content */}
      <main className="container mx-auto px-4 py-8 relative">
        <div className="flex flex-col lg:flex-row">
          {/* Filters */}
          <aside
            className={`w-full lg:w-1/4 mb-8 lg:mb-0 lg:mr-8 ${
              !isFilterVisible ? "hidden lg:block" : ""
            }`}
          >
           <FilterSection 
              filters={filters}
              handleFilterChange={handleFilterChange}
              carClasses={carClasses}
              carSubClasses={carSubClasses}
              selectedVehicleTypes={selectedVehicleTypes}
              selectedSubTypes={selectedSubTypes}
              handleVehicleTypeChange={handleVehicleTypeChange}
              handleSubTypeChange={handleSubTypeChange}
              handleSubTypeChange2={handleSubTypeChange2}
              sourceValue={sourceValue}

            />
          </aside>

          {/* Vehicle Grid or No Results Message */}
          <div className="w-full lg:w-3/4">
            {sortedAndFilteredVehicles.length > 0 ? (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {sortedAndFilteredVehicles
                    .slice(0, visibleVehicles)
                    .map((vehicle) => (
                      <VehicleCard
                        key={vehicle.id}
                        vehicle={vehicle}
                        onPayNowClick={handlePayNowClick}
                        onPayLaterClick={handlePayLaterClick}
                        onChooseClick={handleChooseClick}
                        getRibbonColor={getRibbonColor}
                        getCurrencySymbol={getCurrencySymbol}
                        sourceValue={sourceValue}
                        dataObject={dataObject}
                        selectedChooseDetail={selectedChooseDetail}
                        isHighlighted={
                          vehicle.sippCode ===
                          (searchParams.get("SIPP_Code") ||
                            location.state?.skyScannerSippCode)
                        }
                      />
                    ))}
                </div>

                {/* Only show loading ref if there are more vehicles to load */}
                {visibleVehicles < sortedAndFilteredVehicles.length && (
                  <div
                    ref={loadMoreRef}
                    className="h-20 flex items-center justify-center mt-4"
                  >
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#FF6100]"></div>
                  </div>
                )}

                {/* Add total count display */}
                <div className="text-center mt-4 text-gray-600">
                  Showing{" "}
                  {Math.min(visibleVehicles, sortedAndFilteredVehicles.length)}{" "}
                  of {sortedAndFilteredVehicles.length} vehicles
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center p-8 bg-white rounded-xl shadow-lg text-center">
                <div className="mb-6">
                  <Car className="w-16 h-16 text-gray-300" />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-3">
                  No Vehicles Found
                </h2>
                <p className="text-gray-600 mb-6 max-w-md">
                  We couldn't find any vehicles matching your search criteria.
                  Try adjusting your search parameters or selecting a different
                  location/date.
                </p>
                <div className="space-y-4">
                  <button
                    onClick={() => {
                      // Reset filters
                      setFilters({
                        price: "lowToHigh",
                        vehicleType: "All Vehicles",
                        transmission: "all",
                        seats: "all",
                      });
                      setSelectedVehicleTypes(["All Vehicles"]);
                    }}
                    className="px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors duration-200"
                  >
                    Reset Filters
                  </button>
                  <button
                    onClick={() => navigate("/")}
                    className="block px-6 py-2 bg-gradient-to-r from-[#BB7E55] to-[#FF8942] text-white rounded-lg hover:opacity-90 transition-opacity duration-200"
                  >
                    Try New Search
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      {/* Footer - Updated colors */}
      <Footer />

      {/* Add ScrollButton here */}
      <ScrollButton />

      {/* Add the price comparison modal */}
      <PriceComparisonModal
        isOpen={showPriceModal}
        onClose={() => setShowPriceModal(false)}
        vehicle={selectedVehicle}
        onPayNowClick={handlePayNowClick}
        onPayLaterClick={handlePayLaterClick}
        getCurrencySymbol={getCurrencySymbol}
        rentalLength={apiVehicleData?.length_of_rental || 1}
      />
    </div>
  );
};

export default memo(VehicleListing);
