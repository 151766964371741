import React from "react";
import { Link } from "react-router-dom";
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';

const SmsPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <MainHeader />
      
      <div className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
            <h1 className="text-3xl font-bold text-center mb-8">
              SMS Alerts and Privacy Policy
            </h1>

            <div className="space-y-6">
              <section>
                <h2 className="text-xl font-bold mb-3">Routes Car Rental SMS Alerts and Privacy Policy:</h2>
                <p className="text-gray-700">
                  We take the privacy and security of our users' personal information very seriously. This policy outlines how we collect, use, and protect the personal information that we obtain from users who sign up to receive SMS messages from us via our Mobile Alerts service.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Collection of Information:</h2>
                <p className="text-gray-700">
                  We collect information from users when they opt-in to receive Mobile Alerts from us. This information may include the user's name, phone number, and other information that the user voluntarily provides.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Use of Information:</h2>
                <p className="text-gray-700">
                  We use the information that we collect to send Mobile Alerts to users who have opted in to receive them. We may also use the information to provide users with information about our products, services, or promotions.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Sharing of Information:</h2>
                <p className="text-gray-700">
                  We do not share users' personal information with third parties for marketing purposes. We may share users' personal information with service providers who assist us in sending SMS messages or performing other functions related to our Mobile Alerts program.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Security:</h2>
                <p className="text-gray-700">
                  We take appropriate measures to protect the personal information that we collect from users, including using encryption and other security measures to protect against unauthorized access, disclosure, alteration, or destruction.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Opt-In:</h2>
                <p className="text-gray-700">
                  To opt-in, users can text RCR to 1-866-467-6883. By opting-in, users consent to receive messages per transaction. Users may also receive other promotional messages from us.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Opt-Out:</h2>
                <p className="text-gray-700">
                  Users may opt-out of receiving Mobile Alerts from us at any time by replying with "STOP" to any message. Once a user has opted out, we will not send any further messages to that user.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Changes to Policy:</h2>
                <p className="text-gray-700">
                  We reserve the right to make changes to this policy at any time. If we make any material changes, we will notify users by SMS message or email.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Usage:</h2>
                <p className="text-gray-700">
                  SMS messages are distributed via third-party mobile network providers and, as a result, we cannot control certain factors relating to message delivery. Depending on the recipient's mobile provider service, it may not be possible to transmit the SMS message to the recipient successfully. We do not claim or guarantee availability or performance of this service, including liability for transmission delays or message failures.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Fees:</h2>
                <p className="text-gray-700">
                  We do not charge a fee for this service. However, we are not responsible for any charges from a person's service provider that may result from our providing this service. Message and data rates may apply. It is the user's responsibility to check with their individual carrier, as other charges may apply. We assume no responsibility for charges incurred by signing up for this text-messaging service. Any text-messaging fees that you incur will be billed on your individual wireless bill.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-bold mb-3">Help:</h2>
                <p className="text-gray-700">
                  At any time, users can get additional help by texting the word HELP to the number 1-866-467-6883 in response to any Mobile Alert SMS from us. Or users can call 1-866-467-6883. Moreover, if you have any questions or concerns about our Mobile Alerts and privacy policy, please contact us at{" "}
                  <Link to="mailto:customercare@routes.ca" className="text-blue-600 hover:text-blue-800">
                    customercare@routes.ca
                  </Link>
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SmsPolicy; 