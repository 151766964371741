import React from 'react';
import { useSession } from '../context/SessionContext';
import '../styles/SessionTimeout.css';

const SessionTimeout = () => {
  const { showTimeoutModal, handleRedirectHome } = useSession();

  if (!showTimeoutModal) return null;

  return (
    <div className="session-timeout-overlay">
      <div className="session-timeout-modal">
        <h2>Session Timeout</h2>
        <p>Your session has expired due to inactivity.</p>
        <button onClick={handleRedirectHome}>Return to Home</button>
      </div>
    </div>
  );
};

export default SessionTimeout; 