import React from 'react';
import { Loader2 } from "lucide-react";

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-md flex items-center justify-center z-50">
      <div className="bg-white/90 p-8 rounded-2xl shadow-2xl flex flex-col items-center relative">
        {/* Spinning circles background */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-24 h-24 border-4 border-[#FF6100]/20 rounded-full animate-ping" />
          <div className="absolute w-32 h-32 border-4 border-[#FF6100]/10 rounded-full animate-ping animation-delay-300" />
        </div>
        
        {/* Car icon and loader */}
        <div className="relative z-10 flex flex-col items-center">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-12 w-12 text-[#FF6100] animate-bounce"
            viewBox="0 0 24 24" 
            fill="currentColor"
          >
            <path d="M19 20H5V21C5 21.6 4.6 22 4 22H3C2.4 22 2 21.6 2 21V12L4.3 5.3C4.6 4.5 5.4 4 6.3 4H17.7C18.6 4 19.4 4.5 19.7 5.3L22 12V21C22 21.6 21.6 22 21 22H20C19.4 22 19 21.6 19 21V20ZM6.5 17C7.3 17 8 16.3 8 15.5C8 14.7 7.3 14 6.5 14C5.7 14 5 14.7 5 15.5C5 16.3 5.7 17 6.5 17ZM17.5 17C18.3 17 19 16.3 19 15.5C19 14.7 18.3 14 17.5 14C16.7 14 16 14.7 16 15.5C16 16.3 16.7 17 17.5 17ZM5 13H19L17 7H7L5 13Z"/>
          </svg>
          <Loader2 className="animate-spin text-[#FF6100] mt-4" size={32} />
          <p className="mt-4 text-gray-700 font-medium animate-pulse">
            Finding available vehicles...
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingOverlay; 