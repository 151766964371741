import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/index.css"; // Ensure styles are applied
import CarRentalHome from "./components/CarRentalHome";
import Locations from "./pages/Locations";
import Reservation from "./pages/Reservation";
import Contact from "./pages/Contact";
import Vehicle from "./pages/Vehicle";
import { FormProvider } from "./context/FormContext";
import Checkout from "./pages/Checkout";
import ReservationConfirmed from "./pages/ReservationConfirmed";
import { LocationProvider } from "./context/LocationContext";
import RentalPolicy from "./pages/RentalPolicy";
import { DataProvider } from "./context/DataContext";
import TravelAgent from "./pages/TravelAgent";
import SmsPolicy from "./pages/SmsPolicy";
import SmsAlerts from "./pages/SmsAlerts";
import ScrollToTop from "./components/ScrollToTop";
import ShuttleRequestTracking from './pages/ShuttleRequestTracking';
import { SessionProvider } from "./context/SessionContext";
import SessionTimeout from "./components/SessionTimeout";
import Offers from './pages/Offers';

function App() {
  return (
    <DataProvider>
      <LocationProvider>
        <FormProvider>
          <Router>
            <SessionProvider>
              <ScrollToTop />
              <SessionTimeout />
              <Routes>
                <Route path="/" element={<CarRentalHome />} />
                <Route
                  path="/locations"
                  element={<Locations />}
                />
                <Route
                  path="/reservation"
                  element={<Reservation />}
                />
                <Route path="/contact" element={<Contact />} />
                <Route path="/vehicle" element={<Vehicle />} />
                <Route
                  path="/checkout"
                  element={<Checkout />}
                />
                <Route
                  path="/ReservationConfirmed"
                  element={<ReservationConfirmed />}
                />
                <Route
                  path="/rentalpolicy"
                  element={<RentalPolicy />}
                />
                <Route
                  path="/TravelAgent"
                  element={<TravelAgent />}
                />
                <Route
                  path="/SmsPolicy"
                  element={<SmsPolicy />}
                />
                <Route
                  path="/SmsAlerts"
                  element={<SmsAlerts />}
                />
                <Route
                  path="/ShuttleRequestTracking"
                  element={<ShuttleRequestTracking />}
                />
                {/* <Route path="/offers" element={<Offers />} /> */}
                <Route 
                  path="/vehicle/SkyScanner"
                  element={<Vehicle />}
                />
              </Routes>
            </SessionProvider>
          </Router>
        </FormProvider>
      </LocationProvider>
    </DataProvider>
  );
}

export default App;
