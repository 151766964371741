import React, { useState, useRef } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { Search, Info, MapPin } from "lucide-react";
import LocationSearch from "../components/LocationSearch";
import { getRentalPolicies } from "../services/api";

const RentalPolicy = () => {
  const [locationSearchOpen, setLocationSearchOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationPolicy, setLocationPolicy] = useState(null);
  const [tempFormData, setTempFormData] = useState({
    selectedLocation: "",
  });
  const locationContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  // Handle location selection
  const handleLocationSelect = async (location, id) => {
    setTempFormData((prev) => ({
      ...prev,
      selectedLocation: location,
    }));

    setIsLoading(true);

    try {
      // Fetch location policy from API
      const location_code = id.split("-")[1];
      const policyData = await getRentalPolicies(location_code);

      // Set the selected location with full details including policy
      setSelectedLocation({
        id: id,
        name: location,
      });

      setLocationPolicy(policyData);
    } catch (error) {
      console.error("Error fetching location policy:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  const PolicySection = ({ title, content }) => (
    <div className="mb-8 bg-white rounded-lg p-6 shadow-sm hover:shadow-md transition-shadow duration-200">
      <div className="flex items-center gap-3 mb-4">
        <div className="p-2 bg-orange-100 rounded-lg">
          <Info className="w-5 h-5 text-orange-500" />
        </div>
        <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
      </div>
      <div className="pl-12">
        <p className="text-gray-600 leading-relaxed whitespace-pre-line text-[15px]">
          {content}
        </p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col">
      <MainHeader />

      <main className="flex-grow container mx-auto px-4 py-8 max-w-7xl">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-5xl font-extrabold text-gray-800 tracking-tight mb-4">
            Understand Our{" "}
            <span className="text-orange-500">Rental Policy</span>
          </h1>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Learn about our rental terms and conditions to ensure a smooth and
            seamless experience
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column - Location Search */}
          <div className="md:w-1/3">
            <div className="sticky top-[105px]">
              <div className="bg-white rounded-xl shadow-md p-6 border border-orange-200">
                <div className="relative mb-4">
                  {/* Location Section */}
                  <div className="mb-6 relative" ref={locationContainerRef}>
                    <div
                      className="bg-gray-50 rounded-xl p-4 flex items-center gap-3 cursor-pointer border-2 border-orange-500/50 hover:border-orange-500 transition-colors duration-200 shadow-sm hover:shadow-md"
                      onClick={() => setLocationSearchOpen(true)}
                    >
                      <MapPin className="text-orange-500" size={24} />
                      <input
                        type="text"
                        placeholder="Search for a location"
                        value={tempFormData.selectedLocation}
                        readOnly
                        className="bg-transparent w-full text-gray-900 placeholder-gray-500 focus:outline-none cursor-pointer font-medium"
                      />
                    </div>

                    {/* Location Search Dropdown */}
                    {locationSearchOpen && (
                      <div className="absolute left-0 right-0 mt-2 z-[70]">
                        <LocationSearch
                          isOpen={locationSearchOpen}
                          onClose={() => setLocationSearchOpen(false)}
                          onSelect={(location, id) => {
                            handleLocationSelect(location, id);
                            setLocationSearchOpen(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Policy Details */}
          <div className="md:w-2/3">
            {isLoading ? (
              <div className="bg-white rounded-xl shadow-sm p-8 text-center">
                <div className="max-w-md mx-auto">
                  <div className="animate-spin w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full mx-auto mb-4" />
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    Loading Policies...
                  </h3>
                  <p className="text-gray-500">
                    Please wait while we fetch the rental policies
                  </p>
                </div>
              </div>
            ) : selectedLocation && locationPolicy ? (
              <div className="space-y-6">
                <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">
                    Rental Policies for
                  </h2>
                  <div className="flex items-center gap-2 mt-2">
                    <MapPin className="w-5 h-5 text-orange-500" />
                    <p className="text-lg text-gray-700">
                      {selectedLocation.name}
                    </p>
                  </div>
                </div>

                <div className="grid gap-4">
                  {locationPolicy.map((policy) => (
                    <PolicySection
                      key={policy.name}
                      title={policy.name}
                      content={policy.content}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-xl shadow-sm p-8 text-center">
                <div className="max-w-md mx-auto">
                  <Info className="w-12 h-12 text-orange-500 mx-auto mb-4" />
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    No Location Selected
                  </h3>
                  <p className="text-gray-500">
                    Please select a location to view its rental policies
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default RentalPolicy;
