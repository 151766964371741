import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import {
  Check,
  MapPin,
  Clock,
  Calendar,
  Mail,
  Phone,
  User,
  Home,
  Car,
  CircleDollarSign,
  Users,
  Briefcase,
  Gauge,
  Snowflake,
  ShieldCheck,
  X,
  Tag,
  Download,
  Printer,
  CreditCard,
  Info,
  RefreshCw
} from "lucide-react";
import dummyImage from '../components/assets/dummy.png';
import html2pdf from 'html2pdf.js';
import routesLogo from '../components/assets/routeslogo.png';
import { getShuttleInstructions, getLocations } from '../services/api';

const ReservationConfirmed = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    reservationNumber,
    vehicleDetails,
    formData,
    driverDetails,
    selectedServices,
    services,
    totalAmount,
    servicesTotal,
    vehicleDeposit,
    driverAge
  } = location.state || {};

  const [shuttleInstructions, setShuttleInstructions] = useState(null);
  const [locationDetails, setLocationDetails] = useState(null);
  const [returnLocationDetails, setReturnLocationDetails] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchShuttleInstructions = async () => {
      try {
        const locationId = formData?.selectedLocationId;
        
        
        if (locationId && locationId.includes('-')) {
          const data = await getShuttleInstructions(locationId);
          setShuttleInstructions(data);
        }
      } catch (error) {
        console.error('Error fetching shuttle instructions:', error);
      }
    };

    if (formData?.selectedLocation) {
      fetchShuttleInstructions();
    } else {
      console.log('No selected location found in formData:', formData);
    }
  }, [formData?.selectedLocation]);

  useEffect(() => {
    const fetchLocationDetails = async () => {
      try {
        const locations = await getLocations();
        const locationId = formData?.selectedLocationId;
        const returnLocationId = formData?.selectedReturnLocationId || locationId;

        if (locationId && locations) {
          const matchedLocation = locations.find(loc => loc.location_id === locationId);
          if (matchedLocation) {
            setLocationDetails(matchedLocation);
          }
          const matchedReturnLocation = locations.find(loc => loc.location_id === returnLocationId);
          if (matchedReturnLocation) {
            setReturnLocationDetails(matchedReturnLocation);
          }
        }
      } catch (error) {
        console.error('Error fetching location details:', error);
      }
    };

    fetchLocationDetails();
  }, [formData?.selectedLocationId]);

  // Redirect if no reservation data
  if (!reservationNumber) {
    navigate('/');
    return null;
  }

  const getCurrencySymbol = (currencyCode) => {
   
    if (currencyCode === "USD") {
      return "$";
    } else if (currencyCode === "CAD") {
      return "CA$"; // Updated to use CA$ for CAD
    }
  
    try {
      return new Intl.NumberFormat("en", {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
        .format(0)
        .replace(/[0-9]/g, "")
        .trim();
    } catch (error) {
      return currencyCode + " ";
    }
  };

  const getRibbonColor = (vehicleClass) => {
    const classLower = vehicleClass?.toLowerCase() || "";
    switch (classLower) {
      case "compact":
        return "linear-gradient(45deg, #2196F3, #1976D2)";
      case "premium":
        return "linear-gradient(45deg, #F44336, #D32F2F)";
      case "economy":
        return "linear-gradient(45deg, #4CAF50, #45a049)";
      case "special":
        return "linear-gradient(45deg, #FFD700, #FFC000)";
      default:
        const otherColors = [
          "linear-gradient(45deg, #9C27B0, #7B1FA2)",
          "linear-gradient(45deg, #FF9800, #F57C00)",
          "linear-gradient(45deg, #009688, #00796B)",
          "linear-gradient(45deg, #3F51B5, #303F9F)",
          "linear-gradient(45deg, #00BCD4, #0097A7)",
        ];
        const index = Math.abs(
          classLower.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0)
        ) % otherColors.length;
        return otherColors[index];
    }
  };

  const FeatureIcon = ({ feature }) => {
    const iconProps = { size: 18, className: "feature-icon" };
    switch (feature) {
      case "seats":
        return <Users {...iconProps} />;
      case "bags":
        return <Briefcase {...iconProps} />;
      case "doors":
        return <Car {...iconProps} />;
      case "transmission":
        return <Gauge {...iconProps} />;
      case "ac":
        return <Snowflake {...iconProps} />;
      case "age":
        return <Calendar {...iconProps} />;
      default:
        return null;
    }
  };

  const handleDownloadPDF = () => {
    const downloadButton = document.querySelector('.download-button');
    if (downloadButton) {
      downloadButton.style.visibility = 'hidden';
    }

    const element = document.querySelector('.bg-white.rounded-3xl');
    const isMobile = window.innerWidth <= 768;

    const opt = {
      margin: isMobile ? 0.2 : 0.4,
      filename: `booking-confirmation-${reservationNumber}.pdf`,
      image: { 
        type: 'jpeg', 
        quality: 1
      },
      html2canvas: { 
        scale: isMobile ? 2 : 3,
        useCORS: true,
        logging: false,
        onclone: (clonedDoc) => {
          const content = clonedDoc.querySelector('.bg-white.rounded-3xl');
          
          // Common styles for both mobile and desktop
          content.style.transform = 'scale(0.95)';
          content.style.transformOrigin = 'top center';
          content.style.margin = '0 auto';
          content.style.maxWidth = isMobile ? '100%' : '800px';
          
          // Adjust spacing
          content.querySelectorAll('.p-6').forEach(el => {
            el.style.padding = isMobile ? '8px' : '12px';
          });
          content.querySelectorAll('.p-4').forEach(el => {
            el.style.padding = isMobile ? '6px' : '8px';
          });
          
          // Adjust text sizes
          content.querySelectorAll('.text-2xl').forEach(el => {
            el.style.fontSize = isMobile ? '16px' : '20px';
          });
          content.querySelectorAll('.text-lg').forEach(el => {
            el.style.fontSize = isMobile ? '14px' : '16px';
          });
          content.querySelectorAll('.text-base').forEach(el => {
            el.style.fontSize = isMobile ? '12px' : '14px';
          });
          content.querySelectorAll('.text-sm').forEach(el => {
            el.style.fontSize = isMobile ? '10px' : '12px';
          });

          // Adjust vehicle image
          const vehicleImage = content.querySelector('.vehicle-image');
          if (vehicleImage) {
            vehicleImage.style.height = isMobile ? '100px' : '140px';
            vehicleImage.style.objectFit = 'contain';
            vehicleImage.style.margin = '0 auto';
          }

          // Make spacing more compact
          content.querySelectorAll('.space-y-6').forEach(el => {
            el.style.gap = isMobile ? '8px' : '12px';
            el.style.display = 'flex';
            el.style.flexDirection = 'column';
          });
          content.querySelectorAll('.space-y-4').forEach(el => {
            el.style.gap = isMobile ? '6px' : '8px';
            el.style.display = 'flex';
            el.style.flexDirection = 'column';
          });

          // Adjust logo size
          const logo = content.querySelector('img[alt="Routes Logo"]');
          if (logo) {
            logo.style.height = isMobile ? '20px' : '24px';
          }

          // Make icons smaller
          content.querySelectorAll('svg').forEach(el => {
            el.style.width = isMobile ? '14px' : '16px';
            el.style.height = isMobile ? '14px' : '16px';
          });

          // Adjust success message
          const successMessage = content.querySelector('.bg-green-50');
          if (successMessage) {
            successMessage.style.padding = isMobile ? '6px' : '8px';
            successMessage.style.marginBottom = isMobile ? '8px' : '12px';
          }

          // Adjust feature grid
          const featureGrid = content.querySelector('.grid-cols-3');
          if (featureGrid) {
            featureGrid.style.gap = '4px';
            featureGrid.querySelectorAll('.p-1\\.5').forEach(el => {
              el.style.padding = '4px';
            });
          }

          // Remove download button from PDF
          const downloadBtn = content.querySelector('.download-button');
          if (downloadBtn) {
            downloadBtn.remove();
          }

          // Adjust payment summary section
          const paymentSection = content.querySelector('.bg-gray-50.rounded-xl');
          if (paymentSection) {
            paymentSection.style.padding = isMobile ? '8px' : '12px';
            paymentSection.querySelectorAll('.space-y-4').forEach(el => {
              el.style.gap = '6px';
            });
          }

          // Make borders thinner
          content.querySelectorAll('.border').forEach(el => {
            el.style.borderWidth = '1px';
          });

          // Adjust padding between sections
          content.querySelectorAll('.ticket-tear').forEach(el => {
            el.style.marginBottom = isMobile ? '8px' : '12px';
          });
        }
      },
      jsPDF: { 
        unit: 'in', 
        format: 'a4',
        orientation: 'portrait',
        compress: true
      }
    };

    html2pdf().set(opt).from(element).save().then(() => {
      if (downloadButton) {
        downloadButton.style.visibility = 'visible';
      }
    });
  };

  const handlePrint = () => {
    // Get both the header section and booking confirmation content
    const headerContent = document.querySelector('.header-section')?.innerHTML || '';
    const bookingContent = document.getElementById('booking-confirmation')?.innerHTML || '';
    
    // Create a new window with all content
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Booking Confirmation</title>
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
          <style>
            @media print {
              body { margin: 0; padding: 20px; }
              .download-button, .print-button { display: none !important; }
            }
          </style>
        </head>
        <body>
          <div class="max-w-4xl mx-auto">
            ${headerContent}
            ${bookingContent}
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();

    // Wait for resources to load then print
    printWindow.onload = function() {
      printWindow.print();
      printWindow.onafterprint = function() {
        printWindow.close();
      };
    };
  };

  // Add this helper function near the top of the component
const formatDateToDisplay = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  
  // Get the appropriate ordinal suffix
  const getOrdinalSuffix = (d) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const suffix = getOrdinalSuffix(day);
  
  // Return formatted date with superscript ordinal
  return `${month} ${day}<sup>${suffix}</sup>, ${year}`;
};

  const renderLocationAddress = () => {
    if (!locationDetails) {
      return <p className="font-medium">{formData.selectedLocation}</p>;
    }

    return (
      <div className="space-y-1">
        {locationDetails.airport_code && (
          <p className="font-medium text-gray-700">
            {locationDetails.airport_code} - {locationDetails.airport_name}
          </p>
        )}
        <p className="text-gray-500">
          {locationDetails.full_address || formData.selectedLocation}
        </p>
      </div>
    );
  };
  const renderLocationAddressReturn = () => {
    if (!returnLocationDetails) {
      return <p className="font-medium">{formData.selectedReturnLocation}</p>;
    }
   
    return (
      <div className="space-y-1">
        {returnLocationDetails.airport_code && (
          <p className="font-medium text-gray-700">
            {returnLocationDetails.airport_code} - {returnLocationDetails.airport_name}
          </p>
        )}
        <p className="text-gray-500">
          {returnLocationDetails.full_address || formData.selectedReturnLocation}
        </p>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <MainHeader {...formData} />
      
      <div className="container mx-auto px-2 sm:px-4 py-2 sm:py-8">
        {/* Full Width Header Section */}
        <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm mb-4 sm:mb-6 header-section">
          {/* Logo and Actions Row */}
          <div className="flex flex-row items-center justify-between gap-2 sm:gap-4 mb-4 sm:mb-6">
            <img src={routesLogo} alt="Routes Logo" className="h-6 sm:h-8" />
            <div className="flex gap-2 sm:gap-4">
              <button onClick={handlePrint} className="text-xs sm:text-sm flex items-center gap-1 text-gray-600 px-2 py-1 sm:px-3 sm:py-2 bg-gray-50 rounded-lg">
                <Printer size={14} className="sm:w-4 sm:h-4" />
                Print
              </button>
            </div>
          </div>

          {/* Confirmation Status and Vehicle Details */}
          <div className="border-t pt-4 sm:pt-6 mb-4 sm:mb-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              {/* Left Side - Confirmation Status */}
              <div className="order-2 sm:order-1"> {/* Reorder on mobile */}
                <div className="flex items-center gap-2 mb-2 sm:mb-4">
                  <Check className="text-green-500 w-4 sm:w-5 h-4 sm:h-5" />
                  <span className="font-semibold text-green-600 text-sm sm:text-base">Reservation Confirmed</span>
                </div>

                <div className="text-xs sm:text-sm font-medium text-gray-800">
                  <p>Thanks {driverDetails.firstName}! We look forward to seeing you on <span className="font-medium" dangerouslySetInnerHTML={{ __html: formatDateToDisplay(formData.pickupDate) }} /></p>
                  <p className="font-bold text-base sm:text-lg py-2">Confirmation Number: {reservationNumber}</p>
                  <p>A confirmation email has been sent to the email address provided.</p>
                </div>
              </div>

              {/* Right Side - Vehicle Details */}
              <div className="order-1 sm:order-2"> {/* Reorder on mobile */}
                <div className="flex flex-col sm:flex-row gap-3 sm:gap-6 p-3 sm:p-4 bg-gray-50 rounded-lg">
                  <img 
                    src={vehicleDetails?.image || dummyImage} 
                    alt={vehicleDetails?.name} 
                    className="w-full h-32 sm:w-40 sm:h-28 object-contain"
                  />
                  <div className="space-y-1 sm:space-y-2">
                    <h4 className="font-bold text-sm sm:text-base">{vehicleDetails?.name}</h4>
                    <p className="text-xs sm:text-sm text-gray-500 italic">or similar</p>
                    <div className="grid grid-cols-3 gap-2 sm:gap-4 mt-2 sm:mt-3">
                      <div className="flex items-center gap-1 sm:gap-2 text-xs sm:text-sm">
                        <Gauge className="w-3 h-3 sm:w-4 sm:h-4 text-gray-400" />
                        <span>{vehicleDetails?.features?.transmission}</span>
                      </div>
                      <div className="flex items-center gap-1 sm:gap-2 text-xs sm:text-sm">
                        <Users className="w-3 h-3 sm:w-4 sm:h-4 text-gray-400" />
                        <span>{vehicleDetails?.features?.seats} Seats</span>
                      </div>
                      <div className="flex items-center gap-1 sm:gap-2 text-xs sm:text-sm">
                        <Snowflake className="w-3 h-3 sm:w-4 sm:h-4 text-gray-400" />
                        <span>{vehicleDetails?.features?.ac ? "AC" : "No AC"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Time and Location Grid */}
          <div className="border-t pt-4 sm:pt-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
              <div>
                <h3 className="font-bold text-sm sm:text-base mb-2 sm:mb-3">Pick-up</h3>
                <div className="text-xs sm:text-sm space-y-3 sm:space-y-4">
                  <div className="flex gap-3">
                    <Calendar className="w-5 h-5 text-gray-400" />
                    <div>
                      <p className="text-gray-500">{new Date(formData.pickupDate).toLocaleDateString('en-US', { weekday: 'long' })}</p>
                      <p className="font-medium" dangerouslySetInnerHTML={{ __html: formatDateToDisplay(formData.pickupDate) }} />
                      <p className="font-medium">{formData.pickupTime}</p>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <MapPin className="w-5 h-5 text-gray-400" />
                    <div>
                      <p className="text-gray-500">Pick-up Address</p>
                      {renderLocationAddress()}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <h3 className="font-bold text-sm sm:text-base mb-2 sm:mb-3">Drop-off</h3>
                <div className="text-xs sm:text-sm space-y-3 sm:space-y-4">
                  <div className="flex gap-3">
                    <Calendar className="w-5 h-5 text-gray-400" />
                    <div>
                      <p className="text-gray-500">{new Date(formData.returnDate).toLocaleDateString('en-US', { weekday: 'long' })}</p>
                      <p className="font-medium" dangerouslySetInnerHTML={{ __html: formatDateToDisplay(formData.returnDate) }} />
                      <p className="font-medium">{formData.returnTime}</p>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <MapPin className="w-5 h-5 text-gray-400" />
                    <div>
                      <p className="text-gray-500">Drop-off Address</p>
                      {renderLocationAddressReturn()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Two Column Layout */}
        <div id="booking-confirmation" className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          {/* Main Content */}
          <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm">
            {/* Adjust all sections within for better mobile display */}
            <div className="mb-6 sm:mb-8">
              <h3 className="font-bold text-sm sm:text-base mb-2 sm:mb-3">Renter Details</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                <div className="flex gap-3">
                  <User className="w-5 h-5 text-gray-400" />
                  <div className="text-sm">
                    <p className="text-gray-500">Driver Name</p>
                    <p className="font-medium">{driverDetails.firstName} {driverDetails.lastName}</p>
                  </div>
                </div>
                <div className="flex gap-3">
                  <Mail className="w-5 h-5 text-gray-400" />
                  <div className="text-sm">
                    <p className="text-gray-500">Email Address</p>
                    <p className="font-medium">{driverDetails.email}</p>
                  </div>
                </div>
                <div className="flex gap-3">
                  <Phone className="w-5 h-5 text-gray-400" />
                  <div className="text-sm">
                    <p className="text-gray-500">Phone Number</p>
                    <p className="font-medium">{driverDetails.phoneNumber}</p>
                  </div>
                </div>
                <div className="flex gap-3">
                  <Calendar className="w-5 h-5 text-gray-400" />
                  <div className="text-sm">
                    <p className="text-gray-500">Age</p>
                    <p className="font-medium">
                      {driverAge >= 25 ? '25+' : driverAge}
                    </p>
                  </div>
                </div>
              </div>
            </div>
             {/* included in rates rate */}
             <div className="mb-8">
              <h3 className="font-bold mb-3">Included in Rate</h3>
              <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                    {formData?.selectedLocationSource === "rms" &&
                    ![
                      "Australia",
                      "New Zealand",
                      "Chile",
                      "Barbados",
                      "Cayman Islands",
                      "Trinidad And Tobago",
                    ].some((country) =>
                      formData?.selectedLocation?.includes(country)
                    ) ? (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>
                          {vehicleDetails?.carCode === "FKAR" ||
                          vehicleDetails?.carCode === "CKAR"
                            ? `0.2 ${
                                " " +
                                (vehicleDetails.distance_unit == null
                                  ? "kilometer"
                                  : vehicleDetails?.distance_unit)
                              } per day`
                            : `
                          ${vehicleDetails?.additionalDetails?.mileage} Free
                          ${
                            " " +
                            (vehicleDetails.distance_unit == null
                              ? "kilometer"
                              : vehicleDetails?.distance_unit)
                          }/day, ${getCurrencySymbol(vehicleDetails?.currency)}
                          ${
                            vehicleDetails?.additionalDetails?.mileageCharge
                          }/Extra 
                          ${
                            " " +
                            (vehicleDetails.distance_unit == null
                              ? "kilometer"
                              : vehicleDetails?.distance_unit)
                          }
                          `}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>Unlimited mileage</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.hasAirportSurcharge && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>Airport surcharge Included</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.hasStateTax && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>State Tax Included</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.hasEnhancedCleaning && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>Enhanced Cleaning</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.isInTerminal && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>In Terminal</span>
                      </div>
                    )}
                  </div>
            </div>
             {/* car rate */}
             <div className="mb-8">
              <h3 className="font-bold mb-3">Vehicle Price</h3>
              <div className="space-y-2 text-sm">
                <div className="flex justify-between">
                  <span className="text-gray-600">Vehicle Price for {vehicleDetails?.rentalDetails?.numberOfDays} Day(s)</span>
                  <span className="font-medium">{getCurrencySymbol(vehicleDetails?.currency)}{vehicleDetails?.rentalDetails?.subtotal.toFixed(2)}</span>
                </div>
              </div>
            </div>

            {/* Optional Services */}
            {selectedServices && selectedServices.length > 0 && (
              <div className="mb-8">
                <h3 className="font-bold mb-3">Optional Services</h3>
                <div className="space-y-2">
                  {selectedServices.map((serviceId) => {
                    const allServices = [
                      ...(services.coverages || []),
                      ...(services.extras || []),
                      ...(services.others || [])
                    ];
                    const service = allServices.find(s => s.uniqueId === serviceId);
                    
                    if (!service) return null;

                    return (
                      <div key={serviceId} className="flex justify-between items-center text-sm">
                        <div className="flex items-center gap-2">
                          <Check className="w-4 h-4 text-green-500" />
                          <span className="text-gray-600">{service.name}</span>
                        </div>
                        <span className="font-medium">
                          {getCurrencySymbol(vehicleDetails?.currency)}
                          {(service.type === "Per Day"
                            ? service.total / vehicleDetails?.rentalDetails?.numberOfDays
                            : service.total
                          ).toFixed(2)}
                          {service.type === "Per Day" && (
                            <span className="text-gray-500 text-xs ml-1">/day</span>
                          )}
                        </span>
                      </div>
                    );
                  })}
                  <div className="flex flex-wrap justify-between items-center text-sm">
                    <span className="font-medium">Total Optional Services</span>
                    <span className="font-medium">
                      {getCurrencySymbol(vehicleDetails?.currency)}
                      {servicesTotal.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Taxes & Fees */}
            <div className="mb-8">
              <h3 className="font-bold mb-3">Taxes & Fees</h3>
              <div className="space-y-2 text-sm">
                {/* <div className="flex justify-between">
                  <span className="text-gray-600">Time & Distance {vehicleDetails?.rentalDetails?.numberOfDays} Days</span>
                  <span className="font-medium">{getCurrencySymbol(vehicleDetails?.currency)}{vehicleDetails?.rentalDetails?.subtotal.toFixed(2)}</span>
                </div> */}
                <div className="flex justify-between">
                  <span className="text-gray-600">Total tax and fees</span>
                  <span className="font-medium">{getCurrencySymbol(vehicleDetails?.currency)}{vehicleDetails?.rentalDetails?.taxesAndFees.toFixed(2)}</span>
                </div>
              </div>
            </div>
           

            {/* Estimated Total */}
            <div className="border-t pt-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-bold">Estimated Total</h3>
                  <p className="text-sm text-gray-500">Amount Paid: {getCurrencySymbol(vehicleDetails?.currency)}{totalAmount.toFixed(2)}</p>
                  {location.state?.isPartialPayment && (
                    <p className="text-sm text-gray-500">
                      Due at Counter: {getCurrencySymbol(vehicleDetails?.currency)}{location.state.remainingAmount.toFixed(2)}
                    </p>
                  )}
                </div>
                <span className="text-xl font-bold">
                  {getCurrencySymbol(vehicleDetails?.currency)}{totalAmount.toFixed(2)}
                </span>
              </div>
            </div>

            {/* Shuttle Instructions */}
            {shuttleInstructions && shuttleInstructions.length > 0 && (
              <div className="mt-8">
                <h3 className="font-bold mb-3">Shuttle Instructions</h3>
                <div className="bg-[#FF6100]/10 rounded-lg p-3 sm:p-4">
                  <div className="flex items-center gap-2 text-[#FF6100] mb-3">
                    <MapPin className="w-4 h-4" />
                    <span className="font-medium">Shuttle Instructions for the day of pickup</span>
                  </div>
                  <div className="space-y-4">
                    {shuttleInstructions.map((instruction, index) => (
                      <div key={index} className="border-l-2 border-[#FF6100]/20 pl-3">
                        <p className="font-medium text-gray-800">{instruction.number}</p>
                        <p className="text-sm text-gray-600 whitespace-pre-line">
                          {instruction.arrivalInstruction}
                        </p>
                      </div>
                    ))}
                    <a 
                      href="/ShuttleRequestTracking"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#FF6100] hover:text-[#FF6100]/80 font-medium inline-block"
                    >
                      Go To Shuttle Tracker →
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Right Column */}
          <div className="space-y-4 sm:space-y-6">
            <div className="bg-white p-3 sm:p-6 rounded-lg shadow-sm">
              <h3 className="font-bold text-sm sm:text-base mb-3 sm:mb-4">Rental Checklist</h3>
              <div className="space-y-3 sm:space-y-4 text-xs sm:text-sm">
                <div className="flex items-start gap-2">
                  <Check className="w-4 h-4 text-green-500 mt-1 flex-shrink-0" />
                  <span>Familiarize yourself with your Pick-Up and Return locations</span>
                </div>
                <div className="flex items-start gap-2">
                  <Check className="w-4 h-4 text-green-500 mt-1 flex-shrink-0" />
                  <span>Bring a valid driving license for each driver</span>
                </div>
                <div className="flex items-start gap-2">
                  <Check className="w-4 h-4 text-green-500 mt-1 flex-shrink-0" />
                  <span>Provide an acceptable method of payment in the renter's name. See your pick-up location's policies for details.</span>
                </div>
                <div className="space-y-2">
                  <div className="flex items-start gap-2">
                    <Info className="w-4 h-4 text-gray-400 mt-1 flex-shrink-0" />
                    <span className="text-gray-500">For additional policy or deposit information, please refer to the terms and conditions in the link provided below, or within your email confirmation.</span>
                  </div>
                  <div className="pl-6">
                    <a href="/rentalpolicy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#FF6100] hover:text-[#FF6100]/80 font-medium inline-block">
                      Terms and Conditions →
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default ReservationConfirmed; 