import React, { createContext, useState } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [locations, setLocations] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [finalLocationSelected, setfinalLocationSelected] = useState(null);

  const value = {
    locations,
    setLocations,
    policy,
    setPolicy,
    finalLocationSelected,
    setfinalLocationSelected
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
}; 