import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';

const baseURL = process.env.REACT_APP_API_URL; 

const TravelAgent = () => {
  const [TravelAgent, setTravelAgent] = useState("");
  const [ContactName, setContactName] = useState("");
  const [EmailAddress, setEmailAddress] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [IATAcode, setIATAcode] = useState("");
  const [Paymentpaid, setPaymentpaid] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  const handleClick = () => {
    setLoading(true);
    setError("");

    if (TravelAgent.toString().trim() === '') {
      setLoading(false);
      setError("Please fill Travel Agency Name");
      return;
    }

    if (ContactName.toString().trim() === '') {
      setLoading(false);
      setError("Please fill Contact Name");
      return;
    }

    if (EmailAddress.toString().trim() === '') {
      setLoading(false);
      setError("Please fill Email Address");
      return;
    }

    if (!isValidEmail(EmailAddress.toString().trim())) {
      setLoading(false);
      setError("Please enter valid Email Address");
      return;
    }

    if (PhoneNumber.toString().trim() === '') {
      setLoading(false);
      setError("Please fill Phone Number");
      return;
    }

    if (Paymentpaid.toString().trim() === '') {
      setLoading(false);
      setError("Please fill Address where Payment needs to be sent");
      return;
    }

    axios
      .post(baseURL + "/new_routes_website_api_travel_agent", {
        TravelAgent,
        ContactName,
        EmailAddress,
        PhoneNumber,
        IATAcode,
        Paymentpaid
      })
      .then(() => {
        alert("Thank you for registering with Routes car rental.");
        setTravelAgent("");
        setContactName("");
        setEmailAddress("");
        setPhoneNumber("");
        setIATAcode("");
        setPaymentpaid("");
      })
      .catch((err) => {
        setError("An error occurred. Please try again later.");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="min-h-screen flex flex-col">
      <MainHeader />
      
      <div className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg p-8">
            <h1 className="text-3xl font-bold text-center mb-8">
              ROUTES CAR RENTAL TRAVEL AGENT REGISTRATION SIGN UP
            </h1>
            
            {error && (
              <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-600 rounded-md">
                {error}
              </div>
            )}

            <div className="mb-8">
              <p className="text-gray-800 font-semibold text-justify mb-6">
                Routes Car Rental pays a 10% commission on Time & mileage for leisure bookings that result in a
                consummated rental. Commission is paid on a monthly basis by check for Travel Agents that have
                registered to receive commission from Routes Car Rental. A minimum threshold of $25.00 is required
                for commission payments to be made on a monthly basis in North America ($50.00 outside North
                America). Commission payments that fall below that Threshold will be paid at the end of each quarter
                (March, June, September & December). For any questions about our commission policy or commission
                payments please email us at{" "}
                <Link to="mailto:customercare@routes.ca" className="text-blue-600 hover:text-blue-800">
                  customercare@routes.ca
                </Link>
              </p>

              <p className="text-gray-700 font-semibold mb-8">
                Please provide us with the following information in order to register with us to pay you commission for
                any leisure reservations you send us that result in a consummated rental:
              </p>
            </div>

            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Travel Agency Name*"
                    value={TravelAgent}
                    onChange={(e) => setTravelAgent(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Contact Name*"
                    value={ContactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <input
                    type="email"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Email Address*"
                    value={EmailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Phone Number*"
                    value={PhoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="space-y-4">
                <p className="text-gray-600 text-sm italic">
                  If you do not have an IATA number please email us a business card or stationery that has your Travel
                  Agency information imprinted on it and a pseudo IATA number will be assigned and sent to you
                </p>
                
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="IATA/Pseudo Internal Number"
                  value={IATAcode}
                  onChange={(e) => setIATAcode(e.target.value)}
                />
                
                <textarea
                  rows="3"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Address where Payment needs to be sent*"
                  value={Paymentpaid}
                  onChange={(e) => setPaymentpaid(e.target.value)}
                />
              </div>

              <div className="flex justify-center mt-8">
                <button
                  className="px-8 py-3 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                  onClick={handleClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="flex gap-2">
            <div className="w-3 h-3 bg-orange-500 rounded-full animate-bounce"></div>
            <div className="w-3 h-3 bg-orange-500 rounded-full animate-bounce delay-100"></div>
            <div className="w-3 h-3 bg-orange-500 rounded-full animate-bounce delay-200"></div>
          </div>
        </div>
      )}
      
      <Footer />
    </div>
  );
};

export default TravelAgent;
