import React, { useState, useEffect, useRef } from 'react'
import { ChevronRight, ChevronLeft } from 'lucide-react'

const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const Calendar = ({ isOpen, onClose, onSelect, initialPickupDate, initialReturnDate }) => {
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedStartDate, setSelectedStartDate] = useState(initialPickupDate)
  const [selectedEndDate, setSelectedEndDate] = useState(initialReturnDate)
  const [currentView, setCurrentView] = useState('pickup') // 'pickup' or 'return'

  const calendarRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      setCurrentDate(initialPickupDate || new Date())
      setSelectedStartDate(initialPickupDate)
      setSelectedEndDate(initialReturnDate)
      setCurrentView('pickup')
    }
  }, [isOpen, initialPickupDate, initialReturnDate])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  const nextMonth = (e) => {
    e.preventDefault()
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1))
  }

  const prevMonth = (e) => {
    e.preventDefault()
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1))
  }

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate()

  const getMonthData = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay()
    const daysInMonth = getDaysInMonth(year, month)
    const monthData = []

    for (let i = 0; i < firstDay; i++) {
      monthData.push(null)
    }

    for (let i = 1; i <= daysInMonth; i++) {
      monthData.push(new Date(year, month, i))
    }

    return monthData
  }

  const handleDateClick = (date) => {
    if (currentView === 'pickup') {
      setSelectedStartDate(date)
      setCurrentView('return')
    } else {
      if (date < selectedStartDate) {
        setSelectedStartDate(date)
      } else {
        setSelectedEndDate(date)
        onSelect(selectedStartDate, date)
        onClose()
      }
    }
  }

  const isDateInRange = (date) => {
    return date >= selectedStartDate && date <= (selectedEndDate || selectedStartDate)
  }

  const isDateDisabled = (date) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date < today
  }

  const renderMonth = (year, month) => {
    const monthData = getMonthData(year, month)

    return (
      <div className="w-1/2 px-2">
        <h3 className="text-lg font-semibold mb-2 text-white">{MONTHS[month]} {year}</h3>
        <div className="grid grid-cols-7 gap-1">
          {DAYS.map(day => (
            <div key={day} className="text-center text-sm font-medium text-gray-300">{day}</div>
          ))}
          {monthData.map((date, index) => (
            <div
              key={index}
              className={`text-center p-1 ${
                date && !isDateDisabled(date) ? 'cursor-pointer hover:bg-white/20' : ''
              } ${
                date && isDateInRange(date) ? 'bg-white/30 text-white' : ''
              } ${
                date && (date.getTime() === selectedStartDate?.getTime() || date.getTime() === selectedEndDate?.getTime())
                  ? 'bg-orange-500 text-black font-bold rounded-full'
                  : ''
              } ${
                date && isDateDisabled(date) ? 'text-gray-600 cursor-not-allowed' : 'text-white'
              }`}
              onClick={() => date && !isDateDisabled(date) && handleDateClick(date)}
            >
              {date ? date.getDate() : ''}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  const nextMonthDate = new Date(currentYear, currentMonth + 1, 1)

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div ref={calendarRef} className="bg-black/70 backdrop-blur-sm rounded-lg p-4 max-w-2xl w-full text-white">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {currentView === 'pickup' ? 'Select Pick-up Date' : 'Select Return Date'}
          </h2>
          <div className="flex items-center space-x-2">
            <button onClick={prevMonth} className="p-1 rounded-full hover:bg-white/10">
              <ChevronLeft size={24} />
            </button>
            <button onClick={nextMonth} className="p-1 rounded-full hover:bg-white/10">
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
        <div className="flex">
          {renderMonth(currentYear, currentMonth)}
          {renderMonth(nextMonthDate.getFullYear(), nextMonthDate.getMonth())}
        </div>
        <div className="mt-4 flex justify-between">
          <button onClick={onClose} className="px-4 py-2 bg-gray-600 rounded hover:bg-gray-700">
            Cancel
          </button>
          <button onClick={() => onSelect(selectedStartDate, selectedEndDate)} className="px-4 py-2 bg-[#FF6100] rounded hover:bg-[#FF8942]">
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default Calendar
