import React, { useState, useRef, useEffect } from "react";
import {
  MapPin,
  Menu,
  X,
  Loader2,
  Edit2,
  MoveRight,
  BusFront
} from "lucide-react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LocationSearch from "./LocationSearch";
import Calendar from "./Calendar";
import { getVehicleRates } from "../services/api";
import { useFormData } from "../context/FormContext";
import LoadingOverlay from "./LoadingOverlay";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideMenu from "./SideMenu";
import moment from "moment/moment";
import MobileCalendar from "./MobileCalendar";

const MainHeader = ({
  selectedLocation,
  pickupDate,
  returnDate,
  pickupTime,
  returnTime,
  onLocationSelect,
  onDateSelect,
  onTimeChange,
  onSearch,
  timeOptions: providedTimeOptions,
}) => {
  const { formData, updateFormData } = useFormData();
  const [menuOpen, setMenuOpen] = useState(false);
  const [locationSearchOpen, setLocationSearchOpen] = useState(false);
  const [locationSearchOpenMobile, setLocationSearchOpenMobile] =
    useState(false);
  const locationInputRef = useRef(null);
  const locationContainerRef = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showMobileForm, setShowMobileForm] = useState(false);
  const [tempFormData, setTempFormData] = useState({
    selectedLocation: formData.selectedLocation || "",
    selectedLocationId: formData.selectedLocationId || "",
    selectedLocationSource: formData.selectedLocationSource || "",
    selectedReturnLocation:formData.selectedLocation || "",
      selectedReturnLocationId:formData.selectedLocationId || "",
      selectedReturnLocationSource:formData.selectedLocationSource || "",
    pickupDate: formData.pickupDate || "",
    returnDate: formData.returnDate || "",
    pickupTime: formData.pickupTime || "",
    returnTime: formData.returnTime || "",
  });

  const location = useLocation();
  const isShuttleTrackingPage = () => {
    return (location.pathname === "/ShuttleRequestTracking" 
      || location.pathname === "/ReservationConfirmed" 
      || (formData.selectedLocationId != formData.selectedReturnLocationId && formData.selectedReturnLocationId)
      || location.pathname === "/locations" 
      || location.pathname === "/rentalpolicy" 
      || location.pathname === "/TravelAgent" 
      || location.pathname === "/contact" 
      || location.pathname === "/reservation" 
      || location.pathname === "/vehicle" 
      || location.pathname === "/checkout" 
      || location.pathname === "/vehicle/SkyScanner" 
    )
  };
  useEffect(() => {
    
    setTempFormData({
      selectedLocation: formData.selectedLocation || "",
      selectedLocationId: formData.selectedLocationId || "",
      selectedLocationSource: formData.selectedLocationSource || "",  
      selectedReturnLocation:formData.selectedLocation || "",
      selectedReturnLocationId:formData.selectedLocationId || "",
      selectedReturnLocationSource:formData.selectedLocationSource || "",
      pickupDate: formData.pickupDate || "",
      returnDate: formData.returnDate || "",
      pickupTime: formData.pickupTime || "",
      returnTime: formData.returnTime || "",
    });
  }, [formData]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationContainerRef.current &&
        !locationContainerRef.current.contains(event.target) &&
        locationSearchOpen
      ) {
        setLocationSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [locationSearchOpen]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCalendarSelect = (start, end, pickupTime, dropoffTime) => {
    if (start && end) {
      const defaultPickupTime =
        pickupTime || tempFormData.pickupTime || "11:00 AM";
      const defaultDropoffTime =
        dropoffTime || tempFormData.returnTime || "11:00 AM";

      setTempFormData((prev) => ({
        ...prev,
        pickupDate: start.toLocaleDateString(),
        returnDate: end.toLocaleDateString(),
        pickupTime: defaultPickupTime,
        returnTime: defaultDropoffTime,
      }));

      setCalendarOpen(false);
    }
  };

  const handleLocationSelect = (location, locationId) => {
    setTempFormData((prev) => ({
      ...prev,
      selectedLocation: location,
      selectedLocationId: locationId,
    }));
    setLocationSearchOpen(false);
  };

  const handleDateSelect = (start, end) => {
    const defaultTime = tempFormData.pickupTime || "11:00 AM";
    setTempFormData((prev) => ({
      ...prev,
      pickupDate: start.toLocaleDateString(),
      returnDate: end.toLocaleDateString(),
      pickupTime: defaultTime,
      returnTime: defaultTime,
    }));
    setCalendarOpen(false);
  };

  const handleTimeChange = (type, value) => {
    setTempFormData((prev) => ({
      ...prev,
      [type === "pickup" ? "pickupTime" : "returnTime"]: value,
    }));
  };

  const handleSearch = async (e) => {
    if (e) e.preventDefault();

    try {
      setCalendarOpen(false);

      if (!tempFormData.selectedLocationId) {
        toast.error("Please select your pick-up location");
        return;
      }

      if (!tempFormData.pickupDate) {
        toast.error("Please select your pick-up date");
        return;
      }

      if (!tempFormData.pickupTime) {
        toast.error("Please select your pick-up time");
        return;
      }

      if (!tempFormData.returnDate) {
        toast.error("Please select your return date");
        return;
      }

      if (!tempFormData.returnTime) {
        toast.error("Please select your return time");
        return;
      }

      setIsLoading(true);

      const formatDateTimeForAPI = (dateStr, timeStr) => {
        const [month, day, year] = dateStr.split("/");
        const [time, period] = timeStr.split(" ");
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours);

        if (period === "PM" && hours !== 12) hours += 12;
        if (period === "AM" && hours === 12) hours = 0;

        const date = new Date(year, month - 1, day, hours, parseInt(minutes));
        return date;
      };

      const formatTimeTo24Hr = (timeStr) => {
        const [time, period] = timeStr.split(" ");
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours);

        if (period === "PM" && hours !== 12) hours += 12;
        if (period === "AM" && hours === 12) hours = 0;

        return `${hours.toString().padStart(2, "0")}:${minutes}`;
      };

      const pickupDateTime = formatDateTimeForAPI(
        tempFormData.pickupDate,
        tempFormData.pickupTime
      );
      const returnDateTime = formatDateTimeForAPI(
        tempFormData.returnDate,
        tempFormData.returnTime
      );

      if (pickupDateTime >= returnDateTime) {
        toast.error("Return date must be after pickup date");
        return;
      }

      const searchData = {
        pickupDate: pickupDateTime,
        dropoffDate: returnDateTime,
        pickupTime: formatTimeTo24Hr(tempFormData.pickupTime),
        dropoffTime: formatTimeTo24Hr(tempFormData.returnTime),
        locationId: tempFormData.selectedLocationId,
        dropoff_location_id: tempFormData.selectedLocationId
      };
     
      const response = await getVehicleRates(searchData);

      if (!response || (Array.isArray(response) && response.length === 0)) {
        toast.error("No vehicles found for the selected criteria");
        return;
      }
      updateFormData(tempFormData);

      setShowMobileForm(false);

      navigate("/vehicle", {
        state: {
          vehicleData: response,
          searchCriteria: searchData,
        },
        replace: true,
      });
    } catch (error) {
      console.error("Error fetching vehicle rates:", error);
      toast.error("Error fetching vehicle rates. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Mobile Search api call
  const handleSearchMobile = async (e) => {
    if (e) e.preventDefault();

    try {
      setCalendarOpen(false);

      updateFormData(tempFormData);

      if (!tempFormData.selectedLocationId) {
        toast.error("Please select your pick-up location");

        return;
      }

      setIsLoading(true);

      // Parse the dates using moment

      const pickupDate = moment(tempFormData.pickupDate, "L");

      const returnDate = moment(tempFormData.returnDate, "L");

      const formatTimeTo24Hr = (timeStr) => {
        const [time, period] = timeStr.split(" ");

        let [hours, minutes] = time.split(":");

        hours = parseInt(hours);

        if (period === "PM" && hours !== 12) hours += 12;

        if (period === "AM" && hours === 12) hours = 0;

        return `${hours.toString().padStart(2, "0")}:${minutes}`;
      };

      const pickupTime24 = formatTimeTo24Hr(tempFormData.pickupTime);

      const returnTime24 = formatTimeTo24Hr(tempFormData.returnTime);

      // Format dates for API

      const searchData = {
        pickupDate: pickupDate.format("YYYY-MM-DD") + "T" + pickupTime24,

        dropoffDate: returnDate.format("YYYY-MM-DD") + "T" + returnTime24,

        pickupTime: pickupTime24,

        dropoffTime: returnTime24,

        locationId: tempFormData.selectedLocationId,
        dropoff_location_id: tempFormData.selectedLocationId
      };

      const response = await getVehicleRates(searchData);

      if (!response || (Array.isArray(response) && response.length === 0)) {
        toast.error("No vehicles found for the selected criteria");

        return;
      }

      updateFormData(tempFormData);

      setShowMobileForm(false);

      navigate("/vehicle", {
        state: {
          vehicleData: response,

          searchCriteria: searchData,
        },

        replace: true,
      });
    } catch (error) {
      console.error("Error fetching vehicle rates:", error);

      toast.error("Error fetching vehicle rates. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const generateTimeOptions = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        let hour = i % 12;
        const period = i < 12 ? "AM" : "PM";
        // Use 00 for midnight hours (12 AM)
        if (period === "AM" && hour === 0) {
          hour = "00";
        } else {
          hour = hour || 12; // Use 12 for noon (12 PM)
        }
        times.push(
          `${hour.toString().padStart(2, "0")}:${j
            .toString()
            .padStart(2, "0")} ${period}`
        );
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  // Add this helper function near the top of the component
  const formatDateToDisplay = (dateString) => {
    if (!dateString) return "";
    const date = moment(dateString).startOf("day");
    return date.format("MMM D, YYYY");
  };

  // Use the props instead of formData where appropriate
  const displayLocation =
    selectedLocation || tempFormData.selectedLocation || "";
  const displayPickupDate = pickupDate || tempFormData.pickupDate || "";
  const displayReturnDate = returnDate || tempFormData.returnDate || "";
  const displayPickupTime = pickupTime || tempFormData.pickupTime || "";
  const displayReturnTime = returnTime || tempFormData.returnTime || "";

  // Use provided handlers if they exist
  const handleLocationSelectWrapper = (location, id) => {
    if (onLocationSelect) {
      onLocationSelect(location, id);
    } else {
      setTempFormData((prev) => ({
        ...prev,
        selectedLocation: location,
        selectedLocationId: id,
      }));
    }
  };

  const handleDateSelectWrapper = (start, end) => {
    if (onDateSelect) {
      onDateSelect(start, end);
    } else {
      const defaultTime = "11:00 AM";
      setTempFormData((prev) => ({
        ...prev,
        pickupDate: start.toLocaleDateString(),
        returnDate: end.toLocaleDateString(),
        pickupTime: defaultTime,
        returnTime: defaultTime,
      }));
      setCalendarOpen(false);
    }
  };

  const handleTimeChangeWrapper = (type, value) => {
    if (onTimeChange) {
      onTimeChange(type, value);
    } else {
      setTempFormData((prev) => ({
        ...prev,
        [type === "pickup" ? "pickupTime" : "returnTime"]: value,
      }));
    }
  };

  const handleSearchWrapper = async (e) => {
    if (onSearch) {
      onSearch(e);
    } else {
      await handleSearch(e);
    }
  };

  // Use provided timeOptions or generate them
  const timeOptionsToUse = providedTimeOptions || generateTimeOptions();

  return (
    <>
      {isLoading && <LoadingOverlay />}

      <header
        className="fixed top-0 left-0 right-0 bg-white/70 backdrop-blur-md shadow-md"
        style={{ zIndex: 100 }}
      >
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0">
              <img
                src={require("./assets/routeslogo.png")}
                alt="Routes Logo"
                className="h-16 lg:h-20"
              />
            </Link>

            {/* Search Form Desktop*/}
            {!isShuttleTrackingPage() &&(
            <div className="hidden lg:block flex-1 max-w-4xl mx-8 ">
              <form
                className="bg-black/40 rounded-full p-2 flex items-center space-x-4 border border-white"
                onSubmit={(e) => handleSearch(e)}
              >
                {/* Location Input */}
                <div
                  className="flex-1 min-w-[200px] relative px-2"
                  ref={locationContainerRef}
                >
                  <div
                    className="rounded-full py-2 flex items-center cursor-pointer"
                    onClick={() => setLocationSearchOpen(true)}
                  >
                    <MapPin className="text-gray-100 mr-2" size={24} />
                    <input
                      type="text"
                      placeholder="Where To?"
                      value={tempFormData.selectedLocation}
                      readOnly
                      className="w-full bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100"
                    />
                  </div>
                  {locationSearchOpen && (
                    <div className="absolute top-full left-0 mt-2 w-full z-50">
                      <LocationSearch
                        isOpen={locationSearchOpen}
                        onClose={() => setLocationSearchOpen(false)}
                        onSelect={(location, id, source) => {
                          setTempFormData((prev) => ({
                            ...prev,
                            selectedLocation: location,
                            selectedLocationId: id,
                            selectedLocationSource: source,
                            selectedReturnLocation:location,
                            selectedReturnLocationId:id,
                            selectedReturnLocationSource:source
                          }));
                          setLocationSearchOpen(false);
                        }}
                      />
                    </div>
                  )}
                </div>

                {/* Pickup Date/Time */}
                <div
                  className="flex items-center space-x-1 cursor-pointer"
                  style={{ minWidth: "140px" }}
                >
                  <input
                    type="text"
                    placeholder="Pick-up"
                    value={
                      tempFormData.pickupDate
                        ? formatDateToDisplay(tempFormData.pickupDate)
                        : ""
                    }
                    readOnly
                    className="w-28 bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center"
                    onClick={() => setCalendarOpen(true)}
                  />
                  <select
                    value={tempFormData.pickupTime}
                    onChange={(e) =>
                      handleTimeChangeWrapper("pickup", e.target.value)
                    }
                    className="w-22 bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center appearance-none cursor-pointer hover:bg-white/10 rounded-lg py-1"
                    style={{
                      maxHeight: "100px",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="bg-gray-900 text-white py-2"
                    >
                      Time
                    </option>
                    {timeOptionsToUse.map((time) => (
                      <option
                        key={time}
                        value={time}
                        className="bg-gray-900 text-white py-2 px-4 hover:bg-gray-800"
                      >
                        {time}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center justify-center">
                  <MoveRight size={20} className="text-white" />
                </div>
                {/* Return Date/Time */}
                <div
                  className="flex items-center space-x-2 cursor-pointer"
                  style={{ minWidth: "140px" }}
                >
                  <input
                    type="text"
                    placeholder="Return"
                    value={
                      tempFormData.returnDate
                        ? formatDateToDisplay(tempFormData.returnDate)
                        : ""
                    }
                    readOnly
                    className="w-28 bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center"
                    onClick={() => setCalendarOpen(true)}
                  />
                  <select
                    value={tempFormData.returnTime}
                    onChange={(e) =>
                      handleTimeChangeWrapper("return", e.target.value)
                    }
                    className="w-22 bg-transparent focus:outline-none text-white placeholder-white placeholder-opacity-100 text-center appearance-none cursor-pointer hover:bg-white/10 rounded-lg py-1"
                    style={{
                      maxHeight: "100px",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  >
                    <option
                      value=""
                      disabled
                      hidden
                      className="bg-gray-900 text-white py-2"
                    >
                      Time
                    </option>
                    {timeOptionsToUse.map((time) => (
                      <option
                        key={time}
                        value={time}
                        className="bg-gray-900 text-white py-2 px-4 hover:bg-gray-800"
                      >
                        {time}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Search Button */}
                <div>
                  <button
                    type="submit"
                    onClick={(e) => handleSearch(e)}
                    disabled={isLoading}
                    className="bg-[#FF6100] border-2 border-white hover:bg-[#FF8942] hover:shadow-lg text-white px-4 py-2 rounded-full transition-all whitespace-nowrap flex items-center justify-center min-w-[120px]"
                  >
                    {isLoading ? (
                      <>
                        <Loader2 className="animate-spin mr-2" size={18} />
                        Finding...
                      </>
                    ) : (
                      "Book Now"
                    )}
                  </button>
                </div>

                {/* Calendar Dropdown */}
                {!isMobile && calendarOpen && (
                  <div
                    className="absolute left-1/2 transform -translate-x-1/2"
                    style={{
                      top: "calc(100% + 150px)",
                      zIndex: 1000,
                      width: "600px",
                      marginTop: "10px",
                      marginLeft: "70px",
                    }}
                  >
                    <Calendar
                      isOpen={calendarOpen}
                      onClose={() => setCalendarOpen(false)}
                      onSelect={(start, end, pickupTime, dropoffTime) =>
                        handleCalendarSelect(
                          start,
                          end,
                          pickupTime,
                          dropoffTime
                        )
                      }
                      initialPickupDate={
                        tempFormData.pickupDate
                          ? new Date(tempFormData.pickupDate)
                          : null
                      }
                      initialReturnDate={
                        tempFormData.returnDate
                          ? new Date(tempFormData.returnDate)
                          : null
                      }
                      className="bg-black/30 backdrop-blur-md"
                    />
                  </div>
                )}
              </form>
            </div>
            )}

            {/* Desktop Navigation */}
            <div className="hidden lg:flex items-center">
              {/* <button className="flex items-center space-x-2 text-white">
                <BusFront size={30} />
                <span>SHUTTLE TRACKER</span>
              </button> */}
              <button
                className="text-black hover:bg-gray-100 p-2 rounded-full transition-all duration-200 focus:outline-none flex items-center gap-2"
                onClick={toggleMenu}
              >
                <span className="text-sm font-medium">Menu</span>
                {menuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>

            {/* Mobile Navigation */}
            <div className="flex lg:hidden items-center space-x-4">
           
              <button
                className="text-black focus:outline-none"
                onClick={toggleMenu}
              >
                {menuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Move menu items outside of header */}
      <div className="h-[88px] lg:h-[104px]"></div>

      {/* Side Menu - Moved outside header */}
      <SideMenu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />

      {/* Mobile Search Summary/Form */}
      {!isShuttleTrackingPage() && (
      <div className="lg:hidden">
        {!showMobileForm ? (
          <div
            className="mx-4 mt-4 p-4 bg-black/40 backdrop-blur-sm rounded-xl"
            onClick={() => setLocationSearchOpenMobile(true)}
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center gap-2 mb-3">
                  <div className="bg-white/10 p-2 rounded-full">
                    <MapPin className="text-white" size={20} />
                  </div>
                  <div className="text-white text-lg font-medium">
                    {tempFormData.selectedLocation || "Where to?"}
                  </div>
                </div>

                {tempFormData.pickupDate && (
                  <div className="flex items-center gap-4 text-white/90 text-sm ml-1">
                    <div>
                      <div className="font-medium">Pick-up</div>
                      <div>
                        {tempFormData?.pickupDate ? moment(tempFormData?.pickupDate,"MM/DD/YYYY",true).format("MMM DD, YYYY") : "-"}
                        </div>
                      <div className="text-white/70">
                        {tempFormData?.pickupTime ? tempFormData.pickupTime : "-"}
                      </div>
                    </div>
                    <div className="h-12 w-[1px] bg-white/20"></div>
                    <div>
                      <div className="font-medium">Return</div>
                      <div>
                      {tempFormData?.returnDate ? moment(tempFormData?.returnDate, "MM/DD/YYYY",true).format("MMM DD, YYYY") : "-"}
                      </div>
                      <div className="text-white/70">
                        {tempFormData?.returnTime ? tempFormData.returnTime : "-"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <button className="p-2 bg-white/10 rounded-full">
                <Edit2 className="text-white" size={18} />
              </button>
            </div>
          </div>
        ) : null}

        {/* Location Search for Mobile */}
        {locationSearchOpenMobile && (
          <div className="fixed inset-0 bg-gray-900/95 z-[10002]">
            <div className="relative w-full max-w-md mx-auto mt-8 mb-4 bg-gray-900 rounded-xl ">
              <div className="h-full px-4 pt-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-white text-xl font-semibold">
                    Select Location
                  </h2>
                  <button
                    onClick={() => setLocationSearchOpenMobile(false)}
                    className="text-white p-2"
                  >
                    <X size={24} />
                  </button>
                </div>
                <div className="mb-6 relative" ref={locationContainerRef}>
                  <div className="bg-white/10 rounded-xl p-4 flex items-center gap-3">
                    <MapPin className="text-white" size={24} />
                    <input
                      type="text"
                      placeholder="Where to?"
                      value={tempFormData.selectedLocation}
                      readOnly
                      className="bg-transparent w-full text-white placeholder-white/60 focus:outline-none"
                    />
                  </div>

                  <div className="absolute left-0 right-0 mt-2">
                    <LocationSearch
                      isOpen={locationSearchOpenMobile}
                      onClose={() => setLocationSearchOpenMobile(false)}
                      onSelect={(location, id, source) => {
                        setTempFormData((prev) => ({
                          ...prev,
                          selectedLocation: location,
                          selectedLocationId: id,
                          selectedLocationSource: source,
                          selectedReturnLocation:location,
                          selectedReturnLocationId:id,
                          selectedReturnLocationSource:source
                        }));
                        setLocationSearchOpenMobile(false);
                        setCalendarOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Calendar */}
        {calendarOpen && (
          // <div className="fixed inset-0 z-[99]">
          <MobileCalendar
            isOpen={calendarOpen}
            onClose={() => {
              setCalendarOpen(false);
              setShowMobileForm(false);
            }}
            onSelect={(startDate, endDate, pickupTime, dropoffTime) => {
              setTempFormData((prev) => ({
                ...prev,
                pickupDate: moment(startDate).format("L"),
                returnDate: moment(endDate).format("L"),
                pickupTime: pickupTime,
                returnTime: dropoffTime,
              }));
            }}
            locationId={tempFormData.selectedLocationId}
            tempFormData={tempFormData}

            // initialPickupDate={
            //   tempFormData.pickupDate
            //     ? moment(tempFormData.pickupDate)
            //     : null
            // }
            // initialReturnDate={
            //   tempFormData.returnDate
            //     ? moment(tempFormData.returnDate)
            //     : null
            // }

            // initialPickupDate={
            //   tempFormData.pickupDate
            //     ? moment(tempFormData.pickupDate, "L").toDate()
            //     : null
            // }
            // initialReturnDate={
            //   tempFormData.returnDate
            //     ? moment(tempFormData.returnDate, "L").toDate()
            //     : null
            // }
            initialPickupTime={tempFormData.pickupTime}
            initialReturnTime={tempFormData.returnTime}
          />
          // </div>
        )}
      </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default MainHeader;
