import React, { useEffect, useState, useRef } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormData } from "../context/FormContext";
import {
  ArrowLeft,
  Users,
  Briefcase,
  Car,
  Gauge,
  Snowflake,
  Calendar,
  MapPin,
  Clock,
  Check,
  ChevronDown,
  ChevronUp,
  ShieldCheck,
  Plus,
  CircleDollarSign,
  Minus,
  Calculator,
  Loader,
  Shield,
  Package,
  Gift,
  FileText,
  Receipt,
  CreditCard,
  Info,
  RefreshCw,
  Menu,
  Globe,
} from "lucide-react";
import "./Checkout.css";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import {
  getOptionalServices,
  processPayment,
  getRentalPolicies,
  getVehicleDeposit,
} from "../services/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dummyImage from "../components/assets/dummy.png";
import { date_formater } from "../utils/helper";
import ScrollButton from "../components/ScrollButton";
import cc_master_card from "../components/assets/cards-cc_master_card.svg";
import cc_american_express from "../components/assets/cards-cc_american_express.svg";
import cc_visa from "../components/assets/cards-cc_visa.svg";
import SideMenu from "../components/SideMenu";
import { useSession } from "../context/SessionContext";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the styles

const getCurrencySymbol = (currencyCode) => {
  // Special handling for USD and CAD
  if (currencyCode === "USD") {
    return "$";
  } else if (currencyCode === "CAD") {
    return "CA$"; // Updated to use CA$ for CAD
  }

  try {
    // Using Intl.NumberFormat to get the currency symbol for other currencies
    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(0)
      .replace(/[0-9]/g, "")
      .trim();
  } catch (error) {
    // Fallback to currency code if symbol is not available
    return currencyCode + " ";
  }
};

const getRibbonColor = (vehicleClass) => {
  const classLower = vehicleClass?.toLowerCase() || "";

  switch (classLower) {
    case "compact":
      return "linear-gradient(45deg, #2196F3, #1976D2)"; // Blue
    case "premium":
      return "linear-gradient(45deg, #F44336, #D32F2F)"; // Red
    case "economy":
      return "linear-gradient(45deg, #4CAF50, #45a049)"; // Green
    case "special":
      return "linear-gradient(45deg, #FFD700, #FFC000)"; // Yellow
    default:
      // For other classes, use a consistent color based on the class name
      const otherColors = [
        "linear-gradient(45deg, #9C27B0, #7B1FA2)", // purple
        "linear-gradient(45deg, #FF9800, #F57C00)", // orange
        "linear-gradient(45deg, #009688, #00796B)", // teal
        "linear-gradient(45deg, #3F51B5, #303F9F)", // indigo
        "linear-gradient(45deg, #00BCD4, #0097A7)", // cyan
      ];
      const index =
        Math.abs(
          classLower
            .split("")
            .reduce((acc, char) => acc + char.charCodeAt(0), 0)
        ) % otherColors.length;
      return otherColors[index];
  }
};

const FeatureIcon = ({ feature }) => {
  const iconProps = { size: 18, className: "feature-icon" };
  switch (feature) {
    case "seats":
      return <Users {...iconProps} />;
    case "bags":
      return <Briefcase {...iconProps} />;
    case "doors":
      return <Car {...iconProps} />;
    case "transmission":
      return <Gauge {...iconProps} />;
    case "ac":
      return <Snowflake {...iconProps} />;
    case "age":
      return <Calendar {...iconProps} />;
    default:
      return null;
  }
};

const Alert = ({ message, type, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl">
        <div
          className={`text-center ${
            type === "error" ? "text-red-600" : "text-green-600"
          } mb-4`}
        >
          <div className="text-xl font-bold mb-2">
            {type === "error" ? "Error" : "Success"}
          </div>
          <p className="text-gray-700">{message}</p>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-gradient-to-r from-[#FF6100] to-[#A66D44] text-white py-2 rounded-lg font-semibold hover:from-[#A66D44] hover:to-[#955C33]"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const ProcessingOverlay = () => (
  <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-[9999] flex items-center justify-center">
    <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 flex flex-col items-center gap-4 shadow-2xl border border-white/20">
      <div className="relative">
        <div className="w-16 h-16 border-4 border-[#FF6100]/30 border-t-[#FF6100] rounded-full animate-spin"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-10 h-10 border-4 border-[#A66D44]/30 border-t-[#A66D44] rounded-full animate-spin"></div>
        </div>
      </div>
      <div className="text-white text-center">
        <p className="text-lg font-semibold mb-1">Processing Payment</p>
        <p className="text-sm text-white/80">
          Please wait while we process your payment...
        </p>
      </div>
    </div>
  </div>
);

// Add this helper function at the top of the file
const createConversionPixel = (item, response, totalAmount) => {
  if (!item || !response) return;

  const currentDate = new Date();
  const isoDateString = currentDate.toISOString();
  
  // Extract location details from the selected location
  const locationParts = item.Pickup_Location;
  const pickup_city = locationParts;
  const pickup_area = (pickup_city.length === 3 || (pickup_city.length === 4 && pickup_city[3] === 'T')) ? "Airport" : "City";
  
  // Create the query string
  const ss_final_key_value = [
    `skyscanner_redirectid=${item.skyscanner_redirectid || ''}`,
    `tracking_source_id=DV-942169-3`,
    `event_type=conversion`,
    `occurred_at=${encodeURIComponent(isoDateString)}`,
    `conversion_id=${response.rez_number}`,
    `transaction_total=${totalAmount.toFixed(2)}`,
    `payment_fee=0.00`,
    `rental_cost=${item.Total_Price.toFixed(2)}`,
    `currency=${item.Currency}`,
    `payment_method=credit`,
    `pick_up_location=${pickup_city}`,
    `pick_up_location_type=${pickup_area}`,
    `drop_off_location=${pickup_city}`, // Using same as pickup since we don't have different dropoff
    `drop_off_location_type=${pickup_area}`,
    `sipp_code=${item.SIPP_Code}`,
    `pick_up_date=${item.Pickup_Date_Time.split('T')[0]}`,
    `drop_off_date=${item.DropOff_Date_Time.split('T')[0]}`,
    `car_supplier=Routes`
  ].join('&');

  // Create and append the pixel
  const pixel = new Image();
  pixel.src = `https://analytics.skyscanner.net/dv/v1/event.png?${ss_final_key_value}`;
  pixel.style.width = '1px';
  pixel.style.height = '1px';
  pixel.style.border = 'none';
  
  pixel.onload = () => {
    console.log("Conversion pixel fired successfully");
  };
  
  pixel.onerror = (e) => {
    console.error("Error loading conversion pixel:", e);
  };

  return pixel;
};

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formData, updateFormData } = useFormData();
  const { vehicleDetails, selectedServices: initialServices } =
    location.state || {};
  const [isIncludedOpen, setIsIncludedOpen] = useState(true);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [services, setServices] = useState({
    coverages: [],
    extras: [],
    others: [],
  });
  const [selectedServices, setSelectedServices] = useState(new Set());
  const [loading, setLoading] = useState(true);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const apiCalledRef = useRef(false);

  const [dateOfBirth, setDateOfBirth] = useState(null);

  const [captchaText, setCaptchaText] = useState("");
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const captchaRef = useRef(null); // Reference to scroll to captcha input

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [smsConsent, setSmsConsent] = useState(false);
  const termsRef = useRef(null);

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [showConverge, setShowConverge] = useState(false);
  const [CardName, setCardName] = useState("");
  const [CardNumber, setCardNumber] = useState("");
  const [ExpiryDate, setExpiryDate] = useState(null);
  const [CVVNum, setCVVNum] = useState("");
  const [convergePayLoading, setConvergePayLoading] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [convergeFormErrors, setConvergeFormErrors] = useState({});

  const [isProcessing, setIsProcessing] = useState(false);

  const [policies, setPolicies] = useState([]);
  const [vehicleDeposit, setVehicleDeposit] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);

  // Add this line with the other state declarations
  const [showPoliciesModal, setShowPoliciesModal] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);

  // Add these state variables at the top of the Checkout component
  const [skyscannerData, setSkyscannerData] = useState(null);

  const { resetTimer } = useSession();

  useEffect(() => {
    resetTimer();
    // Add your existing useEffect logic here
  }, []);

  useEffect(() => {
    const countryOptions = Country.getAllCountries().map((country) => ({
      label: country.name,
      value: country.isoCode,
      ...country,
    }));
    setCountries(countryOptions);
  }, []);

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
    setState(null); // Reset state when country changes
    setCity(null); // Reset city when country changes

    // Get states for selected country
    const stateOptions = State.getStatesOfCountry(selectedOption?.value).map(
      (state) => ({
        label: state.name,
        value: state.isoCode,
        ...state,
      })
    );
    setStates(stateOptions);
  };

  const handleStateChange = (selectedOption) => {
    setState(selectedOption);
    setCity(null); // Reset city when state changes

    // Get cities for selected state
    const cityOptions = City.getCitiesOfState(
      country?.value,
      selectedOption?.value
    ).map((city) => ({
      label: city.name,
      value: city.name,
      ...city,
    }));
    setCities(cityOptions);
  };

  const handleCityChange = (selectedOption) => {
    setCity(selectedOption);
  };

  useEffect(() => {
    if (!vehicleDetails) {
      console.error("No vehicle details found");
      navigate("/"); // Redirect to home if no vehicle details
    }
  }, [vehicleDetails, navigate]);

  // Add this useEffect after your existing useEffects
  useEffect(() => {
    // Get skyscanner data from location state
    const skyData = location.state?.skyscannerData;
    if (skyData) {
      setSkyscannerData(skyData);
    }
  }, [location.state]);

  // Handler functions for MainHeader (same as OptionalServices.js)
  const handleLocationSelect = (location, locationId) => {
    updateFormData({
      selectedLocation: location,
      selectedLocationId: locationId,
    });
  };

  const handleDateSelect = (start, end) => {
    if (start && end) {
      updateFormData({
        pickupDate: start.toLocaleDateString(),
        returnDate: end.toLocaleDateString(),
      });
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  // Calculate optional services total
  const calculateServicesTotal = () => {
    const allServices = [
      ...services.coverages,
      ...services.extras,
      ...services.others,
    ];
    return Array.from(selectedServices).reduce((total, serviceId) => {
      const service = allServices.find((s) => s.uniqueId === serviceId);
      if (!service) return total;

      const serviceRate =
        parseFloat(
          service.type === "Per Day"
            ? service.total / vehicleDetails?.rentalDetails?.numberOfDays
            : service.total
        ) || 0;
      if (service.type === "Per Day") {
        return (
          total +
          serviceRate * (vehicleDetails?.rentalDetails?.numberOfDays || 1)
        );
      }
      return total + serviceRate;
    }, 0);
  };

  // Calculate total price including optional services
  const calculateTotal = () => {
    const basePrice = vehicleDetails?.price || 0;
    const servicesTotal = calculateServicesTotal();
    return basePrice + servicesTotal;
  };

  useEffect(() => {
    const fetchServices = async () => {
      if (apiCalledRef.current) return;

      try {
        if (!formData || !vehicleDetails?.carCode) return;

        apiCalledRef.current = true;

        const searchParams = {
          selectedLocationId: formData.selectedLocationId,
          dropLocationId:
            formData.dropLocationId || formData.selectedLocationId,
          country: formData.country || "Canada",
          carClass: vehicleDetails.carCode,
          pickupDate: formData.pickupDate,
          dropoffDate: formData.returnDate,
          pickupTime: formData.pickupTime,
          dropoffTime: formData.returnTime,
        };

        const response = await getOptionalServices(searchParams);

        const servicesWithIds = response.map((service, index) => ({
          ...service,
          uniqueId: `service-${index}-${service.name
            .replace(/\s+/g, "-")
            .toLowerCase()}`,
        }));

        const groupedServices = servicesWithIds.reduce(
          (acc, service) => {
            const type = service.option_service_type?.toLowerCase() || "";
            if (type.includes("coverage")) {
              acc.coverages.push(service);
            } else if (type.includes("extra")) {
              acc.extras.push(service);
            } else {
              acc.others.push(service);
            }
            return acc;
          },
          { coverages: [], extras: [], others: [] }
        );

        setServices(groupedServices);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching optional services:", error);
        setLoading(false);
      }
    };

    fetchServices();
  }, []); // Keep empty dependency array

  const handleServiceToggle = (uniqueId) => {
    setSelectedServices((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(uniqueId)) {
        newSelected.delete(uniqueId);
      } else {
        newSelected.add(uniqueId);
      }
      return newSelected;
    });
  };

  const ServiceCategory = ({ title, services }) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleServiceToggle = (serviceId) => {
      // Find the service
      const service = services.find(s => s.uniqueId === serviceId);
      
      // Check if it's the under age fee service
      const isUnderAgeFeeService = service?.name.toLowerCase().includes('under age fee') || 
                                  service?.name.toLowerCase().includes('age between 21-24');
      
      // If it's the under age fee service, don't allow manual toggle
      if (isUnderAgeFeeService) {
        return;
      }

      // For other services, proceed with normal toggle
      if (selectedServices.has(serviceId)) {
        const newSelected = new Set(selectedServices);
        newSelected.delete(serviceId);
        setSelectedServices(newSelected);
      } else {
        setSelectedServices(new Set([...selectedServices, serviceId]));
      }
    };

    // Add a function to check if a service is the under age fee service
    const isUnderAgeFeeService = (service) => {
      return service.name.toLowerCase().includes('under age fee') || 
             service.name.toLowerCase().includes('age between 21-24');
    };

    if (!services || services.length === 0) return null;

    // Function to get the appropriate icon based on category title
    const getCategoryIcon = (title) => {
      switch (title.toLowerCase()) {
        case "coverages":
          return <Shield size={22} className="text-[#46A24A]" />;
        case "extras":
          return <Package size={22} className="text-[#FF6100]" />;
        case "additional services":
          return <Gift size={22} className="text-[#A66D44]" />;
        default:
          return null;
      }
    };

    return (
      <div className="service-category mb-1">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full flex items-center justify-between text-lg font-semibold border-b hover:text-gray-700 transition-colors duration-200 py-3"
        >
          <div className="flex items-center gap-2">
            {getCategoryIcon(title)}
            <span>{title}</span>
          </div>
          <div className="transform transition-transform duration-300">
            {isOpen ? (
              <ChevronUp size={20} className="text-gray-600" />
            ) : (
              <ChevronDown size={20} className="text-gray-600" />
            )}
          </div>
        </button>

        <div
          className={`transform transition-all duration-300 origin-top mt-2 ${
            isOpen ? "scale-y-100 opacity-100" : "scale-y-0 opacity-0 h-0"
          }`}
        >
          <div className="grid grid-cols-1 gap-2">
            {services.map((service) => (
              <div
                key={service.uniqueId}
                onClick={() => handleServiceToggle(service.uniqueId)}
                className={`bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 border-2 
                  ${selectedServices.has(service.uniqueId) ? "border-[#46A24A]" : "border-gray-100"}
                  ${isUnderAgeFeeService(service) ? "opacity-75 cursor-not-allowed" : "cursor-pointer"}
                  flex items-center justify-between min-h-[60px] p-4`}
              >
                <div className="flex items-center justify-between w-full flex-col sm:flex-row gap-2 sm:gap-4">
                  <span className="font-medium text-gray-900 text-center sm:text-left">
                    {service.name}
                  </span>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1 text-gray-500 whitespace-nowrap">
                      <span className="font-semibold">
                        {getCurrencySymbol(vehicleDetails?.currency)}
                        {parseFloat(
                          service.type === "Per Day"
                            ? service.total /
                                vehicleDetails?.rentalDetails?.numberOfDays
                            : service.total
                        ).toFixed(2)}
                      </span>
                      <span className="text-[10px] text-gray-500">
                        {service.type === "Per Day" ? "/Day" : service.type}
                      </span>
                    </div>
                    <div
                      className={`rounded-full transition-colors ${
                        selectedServices.has(service.uniqueId)
                          ? "bg-[#46A24A] text-white"
                          : "bg-gray-100 text-gray-600"
                      } p-1`}
                    >
                      {selectedServices.has(service.uniqueId) ? (
                        <Minus size={14} />
                      ) : (
                        <Plus size={14} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const OptionalServicesSection = ({
    services,
    selectedServices,
    onToggle,
  }) => {
    if (!services || Object.values(services).flat().length === 0) return null;

    return (
      <div className="optional-services-section mt-1 mb-1">
        <ServiceCategory title="Coverages" services={services.coverages} />
        <ServiceCategory title="Extras" services={services.extras} />
        <ServiceCategory
          title="Additional Services"
          services={services.others}
        />
      </div>
    );
  };

  // Update the selectStyles object
  const selectStyles = {
    control: (base, state) => ({
      ...base,
      height: "55px",
      minHeight: "55px",
      background: "#F9FAFB",
      borderColor: state.isFocused ? "#FF6100" : "#e5e7eb",
      borderRadius: "0.375rem",
      boxShadow: state.isFocused ? "0 0 0 1px #FF6100" : "none",
      "&:hover": {
        borderColor: "#FF6100",
      },
      padding: "0 6px",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 6px",
      height: "55px",
      display: "flex",
      alignItems: "center",
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: "#374151",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#9CA3AF",
      margin: 0,
    }),
    singleValue: (base) => ({
      ...base,
      color: "#374151",
      margin: 0,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "53px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0 8px",
    }),
  };

  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptchaText(captcha);
  };

  // Generate initial captcha when component mounts
  useEffect(() => {
    generateCaptcha();
  }, []);

  // Add new state for form data and errors
  const [driverDetails, setDriverDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    zipCode: "",
    flightNumber: "",
  });

  const [formErrors, setFormErrors] = useState({});

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDriverDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  // Validate email format
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validate phone number (accepts formats: +1234567890, 123-456-7890, (123) 456-7890)
  const isValidPhone = (phone) => {
    // Updated regex to allow phone numbers with length between 6 to 12 digits
    const phoneRegex =
      /^(\+\d{1,3}[-.\s]?)?(\(?\d{3,12}\)?[-.\s]?)?\d{3,12}[-.\s]?\d{4}$/; // Adjusted to ensure at least 6 digits
    return phoneRegex.test(phone);
  };

  useEffect(() => {
    if (vehicleDetails?.currency !== "USD") {
      // Load Stripe script
      const script = document.createElement("script");
      script.src = "https://checkout.stripe.com/checkout.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.StripeCheckout.configure({
          key: process.env.REACT_APP_PUBLISHABLE_KEY_STRIPE,
          locale: "auto",
        });
      };

      return () => {
        // Clean up the script when the component is unmounted
        document.body.removeChild(script);
      };
    }
  }, []);

  const prepareBookingData = () => {
    const data = vehicleDetails || {};
    const rentalDays = data?.rentalDetails?.numberOfDays || 1;
    // Format dates properly
    // const formatDateTime = (date, time) => {
    //   if (!date || !time) return "";
    //   const [timeStr, period] = time.split(" ");
    //   let [hours, minutes] = timeStr.split(":");
    //   hours = parseInt(hours);

    //   if (period === "PM" && hours !== 12) {
    //     hours += 12;
    //   } else if (period === "AM" && hours === 12) {
    //     hours = 0;
    //   }

    //   const dateObj = new Date(date);
    //   dateObj.setHours(hours, parseInt(minutes), 0, 0);
    //   return dateObj.toISOString().slice(0, -1);
    // };
    const formatDateTime = (date, time) => {
      if (!date || !time) return "";

      // Parse time components
      const [timeStr, period] = time.split(" ");
      let [hours, minutes] = timeStr.split(":");
      hours = parseInt(hours);

      // Convert to 24-hour format
      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }

      // Format time as HH:mm
      const formattedTime = `${String(hours).padStart(2, "0")}:${minutes}`;

      // Create date object in UTC to avoid timezone shifts
      const [month, day, year] = date.split("/");

      // Return ISO string format with the correct date
      return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
        2,
        "0"
      )}T${formattedTime}:00.000`;
    };

    // Get location code from location ID
    const getLocationCode = (locationId) => {
      if (!locationId) return "";
      const parts = locationId.split("-");
      return parts.length > 1 ? parts[1] : "";
    };

    // Get tax breakdown from the API response
    const getTaxBreakdown = () => {
      const taxDetails = data?.taxes_detail;
      if (!taxDetails) return "";
      
      const taxParts = [];

      // Add independent taxes
      Object.entries(taxDetails || {}).forEach(([name, amount]) => {
        taxParts.push(`${name} : ${amount}`);
      });
     
      return taxParts.join(", ");
    };

    // Get extras breakdown
    const extrasBreakdown = Array.from(selectedServices)
      .map((serviceId) => {
        const service = [
          ...services.coverages,
          ...services.extras,
          ...services.others,
        ].find((s) => s.uniqueId === serviceId);
        return `${service?.name || ""} : ${
          (service.type === "Per Day"
            ? service?.total / vehicleDetails?.rentalDetails?.numberOfDays
            : service?.total) || 0
        }`;
      })
      .filter((extra) => !extra.endsWith(": 0"))
      .join(",");
    
    const calculateServicesTax = () => {
        const allServices = [
            ...services.coverages,
            ...services.extras,
            ...services.others,
        ];
        return Array.from(selectedServices).reduce((total, serviceId) => {
            const service = allServices.find((s) => s.uniqueId === serviceId);
            return total + (service?.tax || 0); // Sum the tax from each selected service
        }, 0);
    };
    // Calculate extras totals
    const extrasTotal = calculateServicesTotal();
    const extrasTaxRate = calculateServicesTax();
    const extrasTax = extrasTaxRate;
    const extrasBaseValue = extrasTotal - extrasTax;


    return {
      OTA: "Routes Website",
      Booked_ip: "",
      DOB: dateOfBirth ? date_formater(dateOfBirth) : "",
      Age: "",
      Address: driverDetails.address || "",
      Rate_Code: data.Rate_Code,
      Pickup_Location: getLocationCode(formData.selectedLocationId),
      DropOff_Location: getLocationCode(
        formData.selectedReturnLocationId || formData.selectedLocationId
      ),
      Pickup_Date_Time: formatDateTime(
        formData.pickupDate,
        formData.pickupTime
      ),
      DropOff_Date_Time: formatDateTime(
        formData.returnDate,
        formData.returnTime
      ),
      Booking_Date_Time: new Date().toISOString().slice(0, -1),
      SIPP_Code: data.carCode,
      First_Name: driverDetails.firstName || "",
      Last_Name: driverDetails.lastName || "",
      Email_ID: driverDetails.email || "",
      Phone_No: driverDetails.phoneNumber || "",
      city: city?.name || "",
      state: state?.name || "",
      country: country?.name || "",
      zip_code: driverDetails.zipCode || "",
      flight_number: driverDetails.flightNumber || "",
      flight: "",
      uniqe_id: "",
      confirm_email: driverDetails.email || "",
      pay_type: "",
      accept_terms: termsAccepted ? "1" : "0",
      Base_Price_rate_table: +(
        data.rentalDetails.ratePerDay / rentalDays
      ).toFixed(2),
      Total_Price: +(data.rentalDetails.total || 0).toFixed(2),
      Car_Price: +(data.rentalDetails.ratePerDay || 0).toFixed(2),
      Tax_on_Car_Price_Value: +(data.rentalDetails.taxesAndFees || 0).toFixed(
        2
      ),
      Tax_on_Car_Price_Details_Breakup: getTaxBreakdown(),
      Extras_Total_Price: +extrasTotal.toFixed(2),
      Extras_Base_Value: +extrasBaseValue.toFixed(2),
      Tax_on_Extras: +extrasTax.toFixed(2),
      Currency: data.currency,
      Tax_on_Extras_Details: extrasBreakdown,
      upload_to_TSD: 0,
      upload_to_rent_centric: 0,
      upload_to_mexico_file: 2,
      Account_ID: formData.selectedLocationId?.split("-")[0] || "690337",
      length_of_rental: rentalDays.toString(),
      Status_Valid_Cancelled_No_Show: "Valid",
      driver_country: country?.name || "",
      pickup_country: "Canada",
      Promocode: "",
      skyscanner_redirectid: (skyscannerData && skyscannerData.redirectId) ? skyscannerData.redirectId : null,
      booking_from: "en",
      c_name: `${driverDetails.firstName} ${driverDetails.lastName}`.trim(),
      c_number: "",
      c_expiry_m: "",
      c_expiry_y: "",
      c_code: "",
    };
  };

  // Update the Stripe payment handler
  const handlePaymentSubmit = async () => {
    try {
      const errors = {};
      let firstErrorField = null;

      // Helper function to set error and track first error field
      const setError = (fieldName, errorMessage) => {
        errors[fieldName] = errorMessage;
        if (!firstErrorField) {
          firstErrorField = fieldName;
        }
      };

      // Check First Name
      if (!driverDetails.firstName?.trim()) {
        setError("firstName", "First Name is required");
      }

      // Check Last Name
      if (!driverDetails.lastName?.trim()) {
        setError("lastName", "Last Name is required");
      }

      // Check Phone Number
      if (!driverDetails.phoneNumber?.trim()) {
        setError("phoneNumber", "Phone Number is required");
      } else if (!isValidPhone(driverDetails.phoneNumber)) {
        setError("phoneNumber", "Please enter a valid phone number");
      }

      // Check Email
      if (!driverDetails.email?.trim()) {
        setError("email", "Email is required");
      } else if (!isValidEmail(driverDetails.email)) {
        setError("email", "Please enter a valid email address");
      }

      // Check Confirm Email
      // if (!driverDetails.confirmEmail?.trim()) {
      //   setError("confirmEmail", "Confirm Email is required");
      // } else if (driverDetails.email !== driverDetails.confirmEmail) {
      //   setError("confirmEmail", "Emails do not match");
      // }

      // Check Address
      if (!driverDetails.address?.trim()) {
        setError("address", "Address is required");
      }

      // Check Date of Birth
      if (!dateOfBirth) {
        setError("dateOfBirth", "Date of Birth is required");
      }

      // Check Country
      if (!country) {
        setError("country", "Country is required");
      }

      // Check State/Province (only if country is selected)
      // if (country && !state) {
      //   setError("state", "State/Province is required");
      // }

      // Check ZIP Code
      if (!driverDetails.zipCode?.trim()) {
        setError("zipCode", "ZIP Code is required");
      } else {
        const zipRegex = /^.{4,9}$/; // Updated regex to allow any character
        if (!zipRegex.test(driverDetails.zipCode.trim())) {
          setError("zipCode", "Please enter a valid ZIP code");
        }
      }

      // Add captcha validation
      if (!userCaptchaInput) {
        setError("captcha", "Please enter the captcha text");
        setCaptchaError("Please enter the captcha text");
      } else if (userCaptchaInput !== captchaText) {
        setError("captcha", "Captcha does not match");
        setCaptchaError("Captcha does not match");
        // Generate new captcha when incorrect
        generateCaptcha();
        setUserCaptchaInput(""); // Clear the input
      } else {
        setCaptchaError(""); // Clear any existing captcha error
      }

      // Add checkbox validations at the start
      if (!termsAccepted) {
        setError("terms", "Please accept the terms and conditions");
      }

      if (!smsConsent) {
        setError("sms", "Please accept the text message consent");
      }

      if (!marketingConsent) {
        setError(
          "marketing",
          "Please agree to participate in marketing campaigns"
        );
      }
      // Update form errors
      setFormErrors(errors);

      // If there are errors, scroll to the first error field
      if (firstErrorField) {
        setTimeout(() => {
          let element;
          if (firstErrorField === "terms" || firstErrorField === "sms") {
            element = termsRef.current;
          } else if (firstErrorField === "captcha") {
            element = captchaRef.current;
          } else if (firstErrorField === "address") {
            element = document.getElementById("address");
          } else {
            element = document.getElementById(firstErrorField);
          }

          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            // Optional: Add focus to the field if it's an input
            if (element.tagName === "INPUT") {
              element.focus();
            }
          }
        }, 100);
        return;
      }

      const item = prepareBookingData();

      if (vehicleDetails?.currency === "USD") {
        setShowConverge(true);
        return;
      }

      // Rest of your existing Stripe payment logic
      const paymentHandler = window.StripeCheckout.configure({
        key: process.env.REACT_APP_PUBLISHABLE_KEY_STRIPE,
        locale: "auto",
        token: async function (stripeToken) {
          try {
            const token = stripeToken.id;
            if (token) {
              item.token = token;
              setIsProcessing(true);
              const response = await processPayment(item);
              setIsProcessing(false);

              if (response.success === "1" && response.rez_number) {
                // Fire conversion pixel after successful payment
                if (skyscannerData?.redirectId) {
                  createConversionPixel(item, response, calculateTotal());
                }

                const calculateAge = (birthDate, pickupDate) => {
                  const birth = new Date(birthDate);
                  const pickup = new Date(pickupDate);
                  let age = pickup.getFullYear() - birth.getFullYear();
                  const monthDiff = pickup.getMonth() - birth.getMonth();
                  
                  if (monthDiff < 0 || (monthDiff === 0 && pickup.getDate() < birth.getDate())) {
                    age--;
                  }
                  return age;
                };

                navigate("/ReservationConfirmed", {
                  state: {
                    reservationNumber: response.rez_number,
                    vehicleDetails,
                    formData,
                    driverDetails,
                    selectedServices: Array.from(selectedServices),
                    services,
                    totalAmount: calculateTotal(),
                    servicesTotal: calculateServicesTotal(),
                    vehicleDeposit,
                    driverAge: calculateAge(dateOfBirth, formData.pickupDate), // Add this line
                    isPartialPayment: formData?.selectedLocationSource === "europcar",
                    partialPaymentAmount: formData?.selectedLocationSource === "europcar" 
                      ? ((15 * calculateTotal()) / 100) 
                      : null,
                    remainingAmount: formData?.selectedLocationSource === "europcar"
                      ? ((85 * calculateTotal()) / 100)
                      : null,
                  },
                });
              } else {
                setAlert({
                  show: true,
                  message: "Payment processing error. Please try again.",
                  type: "error",
                });
              }
            }
          } catch (error) {
            setIsProcessing(false);
            console.error("Error processing payment:", error);
            setAlert({
              show: true,
              message: "Payment processing error. Please try again or contact support.",
              type: "error",
            });
          }
        },
      });

      paymentHandler.open({
        name: "Routes Car Rental",
        description: `Booking for ${vehicleDetails?.name || "Vehicle"}`,
        amount: vehicleDetails.Rate_Code === "NI" 
          ? 0 
          : formData?.selectedLocationSource === "europcar"
            ? Math.round((15 * calculateTotal() * 100) / 100) // 15% for Europcar
            : Math.round(calculateTotal() * 100), // Full amount for others
        currency: vehicleDetails?.currency?.toLowerCase(),
        email: driverDetails.email,
      });
    } catch (error) {
      setIsProcessing(false);
      console.error("Error:", error);
    }
  };

  // Add these handlers after your existing state declarations
  const handleCloseConverge = () => setShowConverge(false);

  const handleCardNameChange = (e) => setCardName(e.target.value);
  const handleCardNumberChange = (e) => setCardNumber(e.target.value);
  const handleCVVChange = (e) => setCVVNum(e.target.value);

  const onChangetwo = (date) => {
    setExpiryDate(date);
    setMonth((date.getMonth() + 1).toString());
    setYear(date.getFullYear().toString());
  };

  // Add this after handleCloseConverge and other handlers
  const handleConvergePay = async (e) => {
    e.preventDefault();

    // Clear previous errors
    setConvergeFormErrors({});
    const errors = {};

    // Validate card holder name
    if (!CardName.toString().trim()) {
      errors.cardName = "Card holder name is required";
    } else if (
      CardName.toString()
        .trim()
        .toLowerCase()
        .indexOf(driverDetails.firstName.toString().trim().toLowerCase()) < 0
    ) {
      errors.cardName =
        "Name on the credit card should match with the name of driver/renter";
    }

    // Validate card number
    if (!CardNumber.toString().trim()) {
      errors.cardNumber = "Card number is required";
    } else if (
      CardNumber.toString().trim().length < 14 ||
      CardNumber.toString().trim().length > 16
    ) {
      errors.cardNumber = "Please enter valid card number";
    }

    // Validate expiry date
    if (!ExpiryDate) {
      errors.expiryDate = "Expiry date is required";
    }

    // Validate CVV
    if (!CVVNum) {
      errors.cvv = "CVC is required";
    } else if (CVVNum.length < 3) {
      errors.cvv = "Invalid CVC";
    }

    // If there are form errors, show them in the modal
    if (Object.keys(errors).length > 0) {
      setConvergeFormErrors(errors);
      return;
    }

    // Proceed with payment processing
    const item = prepareBookingData();
    item.c_name = CardName;
    item.c_number = CardNumber;
    item.c_expiry_m = month.toString();
    item.c_expiry_y = year.toString();
    item.c_code = CVVNum;

    setConvergePayLoading(true);
    setIsProcessing(true); // Show processing overlay
    const response = await processPayment(item);
    setIsProcessing(false); // Hide processing overlay
    setConvergePayLoading(false);
    handleCloseConverge();

    if (response.success === "1" && response.rez_number) {
      // Fire conversion pixel after successful payment
      if (skyscannerData?.redirectId) {
        createConversionPixel(item, response, calculateTotal());
        console.log("conversion pixel called in converge")
      }

      navigate("/ReservationConfirmed", {
        state: {
          reservationNumber: response.rez_number,
          vehicleDetails,
          formData,
          driverDetails,
          selectedServices: Array.from(selectedServices),
          services,
          totalAmount: calculateTotal(),
          servicesTotal: calculateServicesTotal(),
          vehicleDeposit,
          driverAge: calculateAge(dateOfBirth, formData.pickupDate), // Add this line
          isPartialPayment: formData?.selectedLocationSource === "europcar",
          partialPaymentAmount: formData?.selectedLocationSource === "europcar" 
            ? ((15 * calculateTotal()) / 100) 
            : null,
          remainingAmount: formData?.selectedLocationSource === "europcar"
            ? ((85 * calculateTotal()) / 100)
            : null,
        },
      });
    } else {
      setAlert({
        show: true,
        message: "Payment processing error. Please try again.",
        type: "error",
      });
    }
  };

  // Add this near the top with other useEffect hooks
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means this runs once when component mounts

  const formatDateToDisplay = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "short" });
    const year = date.getFullYear();

    // Get the appropriate ordinal suffix
    const getOrdinalSuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const suffix = getOrdinalSuffix(day);

    // Return formatted date with superscript ordinal
    return `${month} ${day}<sup>${suffix}</sup>, ${year}`;
  };

  useEffect(() => {
    const fetchPolicies = async () => {
      if (formData?.selectedLocationId) {
        const locationCode = formData.selectedLocationId.split("-")[1];
        const policiesData = await getRentalPolicies(locationCode);
        setPolicies(policiesData);
      }
    };
    try {
      fetchPolicies();
    } catch (error) {
      console.error("Error fetching policies:", error);
    }
  }, [formData?.selectedLocationId]);

  // Add this new component for the modal
  const PoliciesModal = ({ isOpen, onClose, policies }) => {
    const [expandedPolicies, setExpandedPolicies] = useState({});

    if (!isOpen) return null;

    const togglePolicy = (name) => {
      setExpandedPolicies((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    };

    return (
      <div className="fixed inset-0 z-[9999] overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4 text-center sm:p-0">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black/60 transition-opacity"
            aria-hidden="true"
            onClick={onClose}
          />

          {/* Modal panel */}
          <div className="relative inline-block w-full transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-6xl">
            {/* Header */}
            <div className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-100 bg-white px-4 py-4 sm:px-6">
              <h3 className="flex items-center gap-2 text-lg font-semibold text-gray-800 sm:text-xl">
                <FileText size={24} className="text-[#FF6100]" />
                Rental Policies
              </h3>
              <button
                onClick={onClose}
                className="rounded-lg p-1 text-gray-400 hover:bg-gray-50 hover:text-gray-500"
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Content */}
            <div className="max-h-[calc(100vh-150px)] overflow-y-auto px-4 py-4 sm:px-6">
              <div className="space-y-4">
                {policies.map((policy, index) => (
                  <div
                    key={index}
                    onClick={() => togglePolicy(policy.name)}
                    className="policy-item rounded-xl border border-gray-100 bg-white 
                      transition-all duration-200 hover:border-gray-200 hover:shadow-md"
                  >
                    <button className="flex items-center justify-between p-4 text-left sm:p-5">
                      <div className="flex items-center gap-3 sm:gap-4">
                        <div
                          className={`p-2 rounded-lg transition-colors duration-200 
                          ${
                            expandedPolicies[policy.name]
                              ? "bg-[#FF6100]/10"
                              : "bg-gray-100"
                          }`}
                        >
                          <ChevronDown
                            size={20}
                            className={`transition-transform duration-200 
                              ${
                                expandedPolicies[policy.name]
                                  ? "rotate-180 text-[#FF6100]"
                                  : "text-gray-400"
                              }`}
                          />
                        </div>
                        <span className="text-base font-medium text-gray-800 sm:text-lg">
                          {policy.name}
                        </span>
                      </div>
                    </button>

                    {expandedPolicies[policy.name] && (
                      <div className="px-4 pb-4 text-gray-600 sm:px-5">
                        <div className="pl-4 pt-4 border-t border-gray-100 sm:pl-7">
                          <div className="prose prose-sm sm:prose-lg max-w-none">
                            <div
                              className="space-y-4 [&>p]:text-gray-600 [&>p]:mb-4 
                              [&>ul]:list-disc [&>ul]:pl-4 [&>ul]:space-y-2 
                              [&>ol]:list-decimal [&>ol]:pl-4 [&>ol]:space-y-2
                              [&>h3]:text-base [&>h3]:sm:text-lg [&>h3]:font-semibold [&>h3]:text-gray-800 [&>h3]:mt-6 [&>h3]:mb-3
                              [&>h4]:text-sm [&>h4]:sm:text-base [&>h4]:font-medium [&>h4]:text-gray-700 [&>h4]:mt-4 [&>h4]:mb-2
                              [&>strong]:text-gray-800 [&>strong]:font-semibold
                              [&>em]:text-gray-600 [&>em]:italic
                              [&>a]:text-[#FF6100] [&>a]:hover:underline"
                            >
                              {policy.content}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Update the RentalPolicies component
  const RentalPolicies = () => {
    const [expandedPolicies, setExpandedPolicies] = useState({});

    if (!policies.length) return null;

    // Find specific policies
    const ageSurchargePolicy = policies.find(
      (p) => p.name === "Renter" || p.name === "Age surcharge"
    );
    const mileagePolicy = policies.find(
      (p) => p.name === "Local" || p.name === "Geographic"
    );
    const otherPolicies = policies.filter((p) => p.name !== "Mileage");

    const togglePolicy = (name) => {
      setExpandedPolicies((prev) => ({
        ...prev,
        [name]: !prev[name],
      }));
    };

    // Update the PolicyItem component inside RentalPolicies
    const PolicyItem = ({
      name,
      content,
      apiContent,
      isDefaultVisible = false,
    }) => {
      if (!content) return null;

      return (
        <div
          className="policy-item bg-white rounded-xl 
        border border-gray-100 transition-all duration-200 hover:border-gray-200 hover:shadow-sm"
          onClick={() => togglePolicy(name)}
        >
          <button className="flex items-center text-left px-5 py-4 transition-colors duration-200">
            <ChevronDown
              size={18}
              className={`text-gray-400 transition-transform duration-200 mr-3 ${
                expandedPolicies[name] ? "rotate-180" : ""
              }`}
            />
            <div className="flex flex-col flex-1">
              <span className="font-semibold text-gray-800 mb-1">{name}</span>
              {/* {isDefaultVisible && !expandedPolicies[name] && ( */}
              {isDefaultVisible && (
                <p className="text-sm text-gray-500 line-clamp-2">{content}</p>
              )}
            </div>
          </button>

          {expandedPolicies[name] && (
            <div className="px-5 pb-4 text-gray-600">
              <div className="pl-7 pt-2 border-t border-gray-100">
                <div className="prose prose-sm max-w-none">
                  {apiContent || content}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    };

    // Update the policies section in the RentalPolicies component
    return (
      <div className="rental-policies bg-white rounded-xl p-6 border">
        <h3 className="flex items-center gap-2 text-xl font-bold text-gray-800 mb-4">
          <FileText size={24} className="text-[#FF6100]" />
          Rental policies
        </h3>

        <div className="space-y-1">
          {/* Age Surcharge policy with API content */}
          {ageSurchargePolicy && (
            <PolicyItem
              name="Age surcharge"
              content="Might be applicable for drivers under 25 years"
              apiContent={ageSurchargePolicy.content}
              isDefaultVisible={true}
            />
          )}

          {/* Mileage policy */}
          {mileagePolicy && (
            <PolicyItem
              name="Mileage"
              content="Different mileage policies might be applicable for local renters"
              apiContent={mileagePolicy.content}
              isDefaultVisible={true}
            />
          )}

          {/* View all rules and restrictions button */}
          {otherPolicies.length > 0 && (
            <div>
              <button
                onClick={() => setShowPoliciesModal(true)}
                className="text-orange-600 hover:text-orange-800 flex items-center gap-1 mt-2"
              >
                <span>View all rules and restrictions</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>

        {/* Policies Modal */}
        <PoliciesModal
          isOpen={showPoliciesModal}
          onClose={() => setShowPoliciesModal(false)}
          policies={otherPolicies}
        />
      </div>
    );
  };

  // Add this helper function to calculate age
  const calculateAge = (birthDate, pickupDate) => {
    const birth = new Date(birthDate);
    const pickup = new Date(pickupDate);
    let age = pickup.getFullYear() - birth.getFullYear();
    const monthDiff = pickup.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && pickup.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateOfBirthChange = (date) => {
    // Calculate age based on pickup date
    const age = calculateAge(date, formData.pickupDate);
    
    // Check if age is less than 21
    if (age < 21) {
      setFormErrors((prev) => ({
        ...prev,
        dateOfBirth: "Driver must be at least 21 years old at pickup date",
      }));
      setDateOfBirth(null);
      return;
    }
    
    // Find the under age fee service
    const allServices = [...services.coverages, ...services.extras, ...services.others];
    const underAgeFeeService = allServices.find(service => 
      service.name.toLowerCase().includes('under age fee') || 
      service.name.toLowerCase().includes('age between 21-24')
    );
    
    if (underAgeFeeService) {
      // Create new Set without the under age fee service
      const newSelected = new Set(Array.from(selectedServices)
        .filter(serviceId => serviceId !== underAgeFeeService.uniqueId));
      
      // Add the service if age is between 21 and 25
      if (age >= 21 && age < 25) {
        newSelected.add(underAgeFeeService.uniqueId);
      }
      
      setSelectedServices(newSelected);
    }
    
    setDateOfBirth(date);
    // Clear error when date is selected
    if (formErrors.dateOfBirth) {
      setFormErrors((prev) => ({
        ...prev,
        dateOfBirth: "",
      }));
    }
  };

  return (
    <div className="checkout-page">
      <MainHeader
        {...formData}
        onLocationSelect={handleLocationSelect}
        onDateSelect={handleDateSelect}
      >
        {/* Add the menu button in your header */}
        <div className="hidden md:flex items-center">
          <button
            className="text-black hover:bg-gray-100 p-2 rounded-full transition-all duration-200 focus:outline-none flex items-center gap-2"
            onClick={() => setMenuOpen(true)}
          >
            <span className="text-sm font-medium">Menu</span>
            <Menu size={24} />
          </button>
        </div>

        {/* Mobile menu button */}
        <div className="flex md:hidden items-center space-x-4">
          <div className="flex items-center space-x-2 text-black">
            <Globe size={16} />
            <span>EN</span>
          </div>
          <button
            className="text-black focus:outline-none"
            onClick={() => setMenuOpen(true)}
          >
            <Menu size={24} />
          </button>
        </div>

        {/* Add the SideMenu component */}
        <SideMenu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
      </MainHeader>

      <main className="container mx-auto px-4 py-1">
        <button
          onClick={handleBackClick}
          className="back-button flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-1"
        >
          <ArrowLeft size={20} />
          <span>Go Back</span>
        </button>

        <div className="checkout-content">
          <div>
            <div className="driver-details-section mb-8">
              <h2 className="flex items-center gap-2 text-2xl font-bold text-gray-800 mb-8 border-b pb-4">
                Driver Details
              </h2>
              <p className="mandatory-text">*Mandatory information</p>
              <form className="driver-form">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="form-group">
                    <label>First Name*</label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={driverDetails.firstName}
                      onChange={handleInputChange}
                      className={`form-input ${
                        formErrors.firstName ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.firstName && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.firstName}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Last Name*</label>
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={driverDetails.lastName}
                      onChange={handleInputChange}
                      className={`form-input ${
                        formErrors.lastName ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.lastName && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.lastName}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <PhoneInput
                      country={'us'} // Default country code
                      inputStyle={{width:'100%', height:'100%'}}
                      value={driverDetails.phoneNumber}
                      onChange={(phone) => handleInputChange({ target: { name: 'phoneNumber', value: phone } })}
                      placeholder="e.g., +1 (123) 456-7890"
                      className={`form-input h-14 border rounded-md shadow-sm focus:outline-none focus:ring-[#FF6100] focus:border-[#FF6100] ${
                        formErrors.phoneNumber ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.phoneNumber && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.phoneNumber}
                      </p>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Email Address*</label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={driverDetails.email}
                      onChange={handleInputChange}
                      className={`form-input ${
                        formErrors.email ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.email && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.email}
                      </p>
                    )}
                  </div>
                  {/* <div className="form-group">
                    <label>Confirm Email Address*</label>
                    <input
                      id="confirmEmail"
                      name="confirmEmail"
                      type="email"
                      value={driverDetails.confirmEmail}
                      onChange={handleInputChange}
                      className={`form-input ${
                        formErrors.confirmEmail ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.confirmEmail && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.confirmEmail}
                      </p>
                    )}
                  </div> */}
                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Date of Birth*
                    </label>
                    <div className="relative">
                      <DatePicker
                        id="dateOfBirth"
                        selected={dateOfBirth}
                        onChange={handleDateOfBirthChange}
                        dateFormat="MM/dd/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={100}
                        placeholderText="MM/DD/YYYY"
                        className={`w-full bg-[#F9FAFB] border rounded-[0.375rem] px-3 text-sm 
                          text-gray-700 focus:outline-none focus:border-[#FF6100] focus:ring-1 focus:ring-[#FF6100] ${
                            formErrors.dateOfBirth ? "border-red-500" : "border-[#e5e7eb]"
                          }`}
                        maxDate={new Date()}
                        showMonthDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>
                    {formErrors.dateOfBirth && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.dateOfBirth}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Address*</label>
                    <input
                      id="address"
                      name="address"
                      type="text"
                      value={driverDetails.address}
                      onChange={handleInputChange}
                      className={`form-input ${
                        formErrors.address ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.address && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.address}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Country*
                    </label>
                    <Select
                      id="country"
                      options={countries}
                      value={country}
                      onChange={(selectedOption) => {
                        handleCountryChange(selectedOption);
                        // Clear error when country is selected
                        if (formErrors.country) {
                          setFormErrors((prev) => ({ ...prev, country: "" }));
                        }
                      }}
                      styles={{
                        ...selectStyles,
                        control: (base, state) => ({
                          ...selectStyles.control(base, state),
                          borderColor: formErrors.country
                            ? "#EF4444"
                            : state.isFocused
                            ? "#FF6100"
                            : "#e5e7eb",
                        }),
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select Country"
                      isSearchable
                    />
                    {formErrors.country && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.country}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      State/Province
                    </label>
                    <Select
                      id="state"
                      options={states}
                      value={state}
                      onChange={(selectedOption) => {
                        handleStateChange(selectedOption);
                        // Clear error when state is selected
                        // if (formErrors.state) {
                        //   setFormErrors((prev) => ({ ...prev, state: "" }));
                        // }
                      }}
                      styles={{
                        ...selectStyles,
                        control: (base, state) => ({
                          ...selectStyles.control(base, state),
                          borderColor: formErrors.state
                            ? "#EF4444"
                            : state.isFocused
                            ? "#FF6100"
                            : "#e5e7eb",
                        }),
                      }}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select State/Province"
                      isSearchable
                      isDisabled={!country}
                    />
                  </div>
                  {state && (
                    <div className="form-group mb-4">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        City
                      </label>
                      <Select
                        options={cities}
                        value={city}
                        onChange={handleCityChange}
                        styles={selectStyles}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder="Select City"
                        isSearchable
                        isDisabled={!state}
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label>ZIP Code*</label>
                    <input
                      id="zipCode"
                      name="zipCode"
                      type="text"
                      value={driverDetails.zipCode}
                      onChange={handleInputChange}
                      className={`form-input ${
                        formErrors.zipCode ? "border-red-500" : ""
                      }`}
                    />
                    {formErrors.zipCode && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.zipCode}
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Flight Details/Number</label>
                    <input type="text" className="form-input" />
                  </div>
                </div>
              </form>
            </div>

            <div className="mt-8">
              <OptionalServicesSection
                services={services}
                selectedServices={selectedServices}
                onToggle={handleServiceToggle}
              />
              <div className="mt-8">
                <RentalPolicies />
              </div>
            </div>
          </div>

          <div className="booking-summary bg-white rounded-2xl shadow-lg p-8 sticky top-24">
            <div className="review-section">
              <h3 className="text-2xl font-bold mb-8 text-gray-800 border-b pb-4">
                Booking Summary
              </h3>

              <div className="vehicle-details space-y-6">
                <div className="car-info bg-white rounded-xl p-6 transition-all hover:shadow-md relative">
                  <div
                    style={{
                      position: "absolute",
                      right: "-40px",
                      top: "15px",
                      width: "170px",
                      transform: "rotate(45deg)",
                      background: "linear-gradient(45deg, #FF4800, #FF6100)",
                      color: "white",
                      padding: "8px 0",
                      textAlign: "center",
                      fontSize:
                        vehicleDetails?.class?.length > 10
                          ? "0.65rem"
                          : "0.8rem",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
                      zIndex: "1",
                      overflow: "hidden",
                    }}
                  >
                    {vehicleDetails?.class}
                  </div>

                  <div className="car-img">
                    <img
                      src={vehicleDetails?.image || dummyImage}
                      alt={vehicleDetails?.name || "Car"}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop if dummy image also fails
                        e.target.src = dummyImage;
                      }}
                    />
                  </div>

                  <div className="space-y-1">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="group relative inline-flex flex-col">
                          <span className="text-xl font-bold bg-gradient-to-r from-gray-800 to-gray-600 bg-clip-text text-transparent mb-1">
                            {vehicleDetails?.name}
                          </span>
                          <span className="text-sm font-medium text-gray-500 italic">
                            or Similar
                          </span>
                          <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-gradient-to-r from-[#BB7E55] to-[#FF8942] group-hover:w-full transition-all duration-300"></span>
                        </h3>
                      </div>

                      <div className="text-right min-w-[150px]">
                        <div className="mb-4">
                          <div className="flex items-center justify-end  mb-2">
                            <span className="text-sm font-medium text-gray-800 mb-1">
                              {getCurrencySymbol(vehicleDetails?.currency)}
                            </span>
                            <span className="text-2xl font-extrabold text-gray-800 leading-none">
                              {vehicleDetails?.pricePerDay?.toFixed(2)}
                            </span>
                            <span className="text-sm text-gray-800 font-medium self-end -mb-[3px]">
                              /day
                            </span>
                          </div>
                        </div>

                        <div>
                          <div className="flex items-center justify-end  mb-1">
                            <span className="text-sm font-medium text-gray-500">
                              {getCurrencySymbol(vehicleDetails?.currency)}
                            </span>
                            <span className="text-lg font-bold text-gray-500 leading-none">
                              {vehicleDetails?.rentalDetails?.total?.toFixed(2)}
                            </span>
                            <span className="text-sm text-gray-500 font-medium ml-1 -mb-[3px]">
                              total
                            </span>
                          </div>
                          <div className="text-xs text-gray-500 font-medium">
                            Includes all taxes & fees
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-3 gap-1 text-xs mt-1">
                      <div className="flex flex-col items-center gap-1 p-1.5 bg-gray-50 rounded-lg">
                        <FeatureIcon feature="transmission" />
                        <span className="text-gray-600 font-medium">
                          {vehicleDetails?.features?.transmission}
                        </span>
                      </div>
                      <div className="flex flex-col items-center gap-1 p-1.5 bg-gray-50 rounded-lg">
                        <FeatureIcon feature="seats" />
                        <span className="text-gray-600 font-medium">
                          {vehicleDetails?.features?.seats} Seats
                        </span>
                      </div>
                      <div className="flex flex-col items-center gap-1 p-1.5 bg-gray-50 rounded-lg">
                        <FeatureIcon feature="ac" />
                        <span className="text-gray-600 font-medium">
                          {vehicleDetails?.features?.ac ? "AC" : "No AC"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rental-details">
                  <div className="location-time bg-gray-50 p-6 rounded-xl hover:shadow-md transition-all">
                    <div className="flex flex-col gap-6">
                      {/* Pickup Section with Animation */}
                      <div className="relative">
                        <div className="flex items-center gap-4">
                          <div className="p-2.5 bg-[#FF6100]/10 rounded-lg relative">
                            <MapPin
                              className="text-[#FF6100]"
                              size={16}
                              strokeWidth={1.5}
                            />
                            {/* Animation Container */}
                            <div className="absolute left-1/2 top-[80%] w-0.5 h-[calc(100%+6rem)]">
                              <div className="route-path"></div>
                              <div className="car-animation">
                                <Car size={30} className="text-[#FF6100]" />
                              </div>
                            </div>
                          </div>
                          <div>
                            <p className="text-lg font-bold text-gray-800">
                              {formData.selectedLocation}
                            </p>
                            <p className="text-sm text-gray-500">
                              Pick-up location
                            </p>
                          </div>
                        </div>

                        {/* Pickup Date & Time */}
                        <div className="flex items-center gap-4 mt-3 ml-14">
                          <div className="p-2 bg-[#FF6100]/10 rounded-lg">
                            <Clock
                              className="text-[#FF6100]"
                              size={16}
                              strokeWidth={1.5}
                            />
                          </div>
                          <div>
                            <p
                              className="text-base font-semibold text-gray-700"
                              dangerouslySetInnerHTML={{
                                __html: formData.pickupDate
                                  ? formatDateToDisplay(formData.pickupDate)
                                  : "",
                              }}
                            />
                            <p className="text-sm font-medium text-[#FF6100]">
                              {formData.pickupTime}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Return Section */}
                      <div>
                        <div className="flex items-center gap-4">
                          <div className="p-2.5 bg-[#FF6100]/10 rounded-lg">
                            <MapPin
                              className="text-[#FF6100]"
                              size={16}
                              strokeWidth={1.5}
                            />
                          </div>
                          <div>
                            <p className="text-lg font-bold text-gray-800">
                              {formData.selectedReturnLocation || formData.selectedLocation}
                            </p>
                            <p className="text-sm text-gray-500">
                              Drop-off location
                            </p>
                          </div>
                        </div>

                        {/* Return Date & Time */}
                        <div className="flex items-center gap-4 mt-3 ml-14">
                          <div className="p-2 bg-[#FF6100]/10 rounded-lg">
                            <Clock
                              className="text-[#FF6100]"
                              size={16}
                              strokeWidth={1.5}
                            />
                          </div>
                          <div>
                            <p
                              className="text-base font-semibold text-gray-700"
                              dangerouslySetInnerHTML={{
                                __html: formData.returnDate
                                  ? formatDateToDisplay(formData.returnDate)
                                  : "",
                              }}
                            />
                            <p className="text-sm font-medium text-[#FF6100]">
                              {formData.returnTime}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="additional-details mt-1">
                <button
                  onClick={() => setIsIncludedOpen(!isIncludedOpen)}
                  className="w-full flex items-center justify-between text-lg font-semibold mb-4 border-b pb-2 hover:text-gray-700 transition-colors duration-200"
                >
                  <div className="flex items-center gap-2">
                    <ShieldCheck size={22} className="text-[#46A24A]" />
                    <span>Included in Rate</span>
                  </div>
                  <div className="transform transition-transform duration-300">
                    {isIncludedOpen ? (
                      <ChevronUp size={20} className="text-gray-600" />
                    ) : (
                      <ChevronDown size={20} className="text-gray-600" />
                    )}
                  </div>
                </button>

                <div
                  className={`transform transition-all duration-300 origin-top ${
                    isIncludedOpen
                      ? "scale-y-100 opacity-100"
                      : "scale-y-0 opacity-0 h-0"
                  }`}
                >
                  <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                    {formData?.selectedLocationSource === "rms" &&
                    ![
                      "Australia",
                      "New Zealand",
                      "Chile",
                      "Barbados",
                      "Cayman Islands",
                      "Trinidad And Tobago",
                    ].some((country) =>
                      formData?.selectedLocation?.includes(country)
                    ) ? (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>
                          {vehicleDetails?.carCode === "FKAR" ||
                          vehicleDetails?.carCode === "CKAR"
                            ? `0.2 ${
                                " " +
                                (vehicleDetails.distance_unit == null
                                  ? "kilometer"
                                  : vehicleDetails?.distance_unit)
                              } per day`
                            : `
                          ${vehicleDetails?.additionalDetails?.mileage} Free
                          ${
                            " " +
                            (vehicleDetails.distance_unit == null
                              ? "kilometer"
                              : vehicleDetails?.distance_unit)
                          }/day, ${getCurrencySymbol(vehicleDetails?.currency)}
                          ${
                            vehicleDetails?.additionalDetails?.mileageCharge
                          }/Extra 
                          ${
                            " " +
                            (vehicleDetails.distance_unit == null
                              ? "kilometer"
                              : vehicleDetails?.distance_unit)
                          }
                          `}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>Unlimited mileage</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.hasAirportSurcharge && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>Airport surcharge Included</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.hasStateTax && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>State Tax Included</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.hasEnhancedCleaning && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>Enhanced Cleaning</span>
                      </div>
                    )}

                    {vehicleDetails?.additionalDetails?.isInTerminal && (
                      <div className="flex items-center gap-2 text-gray-600">
                        <div className="w-5 h-5 rounded-full bg-[#46A24A] flex items-center justify-center">
                          <Check size={14} className="text-white" />
                        </div>
                        <span>In Terminal</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {selectedServices.length > 0 && (
                <div className="protections-extras mt-6">
                  <button
                    onClick={() => setIsServicesOpen(!isServicesOpen)}
                    className="w-full flex items-center justify-between text-lg font-semibold mb-4 border-b pb-2 hover:text-gray-700 transition-colors duration-200"
                  >
                    <div className="flex items-center gap-2">
                      <CircleDollarSign size={22} className="text-gray-500" />
                      <span>Selected Optional Services</span>
                    </div>
                    <div className="transform transition-transform duration-300">
                      {isServicesOpen ? (
                        <ChevronUp size={20} className="text-gray-600" />
                      ) : (
                        <ChevronDown size={20} className="text-gray-600" />
                      )}
                    </div>
                  </button>

                  <div
                    className={`transform transition-all duration-300 origin-top ${
                      isServicesOpen
                        ? "scale-y-100 opacity-100"
                        : "scale-y-0 opacity-0 h-0"
                    }`}
                  >
                    <div className="space-y-3">
                      {selectedServices.map((service) => (
                        <div
                          key={service.uniqueId}
                          className="service-item bg-gray-50 p-3 rounded-lg flex justify-between items-center"
                        >
                          <span className="font-medium">{service.name}</span>
                          <div className="flex items-center gap-1 text-gray-500">
                            <span className="font-semibold">
                              {getCurrencySymbol(vehicleDetails?.currency)}
                              {service.type === "Per Day"
                                ? parseFloat(
                                    service.total /
                                      vehicleDetails?.rentalDetails
                                        ?.numberOfDays
                                  ).toFixed(2)
                                : parseFloat(service.total).toFixed(2)}
                            </span>
                            <span className="text-sm text-gray-500">
                              {" "}
                              {service.type === "Per Day"
                                ? "/Day"
                                : service.type}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="rate-breakdown mt-8">
                {/* Header with animated gradient background */}
                <div className="relative overflow-hidden bg-gradient-to-r from-orange-50 to-white rounded-t-2xl p-6 border border-b-0 border-gray-100">
                  <div className="absolute inset-0 bg-gradient-to-r from-orange-100/30 to-transparent animate-gradient"></div>
                  <h4 className="relative flex items-center gap-3 text-xl font-bold text-gray-800">
                    <div className="p-2 bg-white rounded-lg shadow-sm">
                      <Calculator size={24} className="text-[#FF6100]" />
                    </div>
                    <span>Rate Breakdown</span>
                  </h4>
                </div>

                {/* Main content card */}
                <div className="bg-white rounded-b-2xl shadow-xl border border-t-0 border-gray-100 overflow-hidden">
                  {/* Currency indicator */}
                  <div className="flex items-center justify-between p-6 border-b border-gray-100 bg-gradient-to-r from-gray-50 to-white">
                    <div className="text-lg font-semibold text-gray-800">
                      Rate Details
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-medium text-gray-500">
                        Currency:
                      </span>
                      <span className="px-3 py-1 bg-gray-100 rounded-full text-sm font-semibold text-gray-700">
                        {vehicleDetails?.currency}
                      </span>
                    </div>
                  </div>

                  {/* Pricing breakdown */}
                  <div className="p-6 space-y-4">
                    {/* Daily Rate Card */}
                    <div className="group hover:scale-[1.02] transform transition-all duration-300">
                      <div className="relative overflow-hidden bg-gradient-to-br from-gray-50 to-white rounded-xl border border-gray-100 hover:border-orange-200 hover:shadow-lg transition-all duration-300">
                        <div className="absolute inset-0 bg-gradient-to-r from-orange-50/0 to-orange-50/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                        <div className="relative p-4 flex items-center justify-between">
                          <div className="space-y-1">
                            <div className="flex items-center gap-2">
                              <Calendar size={18} className="text-[#FF6100]" />
                              <span className="font-semibold text-gray-800">
                                Daily Rate
                              </span>
                            </div>
                            <div className="text-sm text-gray-500 ml-6">
                              {vehicleDetails?.rentalDetails?.numberOfDays}{" "}
                              Day(s)
                            </div>
                          </div>
                          <div className="text-right">
                            <div className="flex items-baseline gap-1 text-gray-800">
                              <span className="text-lg font-bold">
                                {getCurrencySymbol(vehicleDetails?.currency)}
                                {(
                                  vehicleDetails?.rentalDetails?.subtotal /
                                  vehicleDetails?.rentalDetails?.numberOfDays
                                ).toFixed(2)}
                              </span>
                              <span className="text-sm text-gray-800">
                                /day
                              </span>
                            </div>
                            <div className="text-gray-500 font-bold mt-1">
                              {getCurrencySymbol(vehicleDetails?.currency)}
                              {vehicleDetails?.rentalDetails?.subtotal?.toFixed(
                                2
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Optional Services Card */}
                    <div className="group hover:scale-[1.02] transform transition-all duration-300">
                      <div className="relative overflow-hidden bg-gradient-to-br from-gray-50 to-white rounded-xl border border-gray-100 hover:border-orange-200 hover:shadow-lg transition-all duration-300">
                        <div className="absolute inset-0 bg-gradient-to-r from-orange-50/0 to-orange-50/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                        <div className="relative p-4 flex items-center justify-between">
                          <div className="space-y-1">
                            <div className="flex items-center gap-2">
                              <Shield size={18} className="text-[#FF6100]" />
                              <span className="font-semibold text-gray-800">
                                Optional Services
                              </span>
                            </div>
                            <div className="text-sm text-gray-500 ml-6">
                              Additional protection & extras
                            </div>
                          </div>
                          <div className="text-gray-800 text-lg font-bold">
                            {getCurrencySymbol(vehicleDetails?.currency)}
                            {calculateServicesTotal().toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Taxes & Fees Card */}
                    <div className="group hover:scale-[1.02] transform transition-all duration-300">
                      <div className="relative overflow-hidden bg-gradient-to-br from-gray-50 to-white rounded-xl border border-gray-100 hover:border-orange-200 hover:shadow-lg transition-all duration-300">
                        <div className="absolute inset-0 bg-gradient-to-r from-orange-50/0 to-orange-50/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                        <div className="relative p-4 flex items-center justify-between">
                          <div className="space-y-1">
                            <div className="flex items-center gap-2">
                              <Receipt size={18} className="text-[#FF6100]" />
                              <span className="font-semibold text-gray-800">
                                Taxes & Fees
                              </span>
                            </div>
                            <div className="text-sm text-gray-500 ml-6">
                              Including all applicable taxes
                            </div>
                          </div>
                          <div className="text-gray-800 text-lg font-bold">
                            {getCurrencySymbol(vehicleDetails?.currency)}
                            {vehicleDetails?.rentalDetails?.taxesAndFees?.toFixed(
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Total Amount Section */}
                    <div className="mt-8 pt-6 border-t border-dashed">
                      <div className="bg-gradient-to-br from-[#FF6100]/5 to-orange-50 rounded-xl p-6">
                        {formData?.selectedLocationSource === "europcar" ? (
                          // Europcar payment breakdown
                          <div className="space-y-4">
                            {/* Pay Now Amount */}
                            <div className="flex items-center justify-between">
                              <div>
                                <div className="text-lg font-semibold text-gray-800">Pay Now</div>
                                <div className="text-sm text-gray-600">Due at booking</div>
                              </div>
                              <div className="text-xl font-bold text-gray-800">
                                {getCurrencySymbol(vehicleDetails?.currency)}
                                {((15 * calculateTotal()) / 100).toFixed(2)}
                              </div>
                            </div>

                            {/* Pay at Counter Amount */}
                            <div className="flex items-center justify-between">
                              <div>
                                <div className="text-lg font-semibold text-gray-800">Pay at Counter</div>
                                <div className="text-sm text-gray-600">Due at pickup</div>
                              </div>
                              <div className="text-xl font-bold text-gray-800">
                                {getCurrencySymbol(vehicleDetails?.currency)}
                                {((85 * calculateTotal()) / 100).toFixed(2)}
                              </div>
                            </div>

                            {/* Total Amount */}
                            <div className="pt-4 border-t border-dashed">
                              <div className="flex items-center justify-between">
                                <div>
                                  <div className="text-xl font-bold text-gray-900">Total Amount</div>
                                  <div className="text-sm text-gray-600 mt-1">
                                    Including all taxes & fees
                                  </div>
                                </div>
                                <div className="text-right">
                                  <div className="text-3xl font-bold bg-gradient-to-r from-gray-800 to-gray-800 bg-clip-text text-transparent">
                                    {getCurrencySymbol(vehicleDetails?.currency)}
                                    {calculateTotal().toFixed(2)}
                                  </div>
                                  <div className="text-sm text-gray-600 mt-1">
                                    Total for {vehicleDetails?.rentalDetails?.numberOfDays} day(s)
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          // Regular payment display (existing code)
                          <div className="flex items-center justify-between">
                            <div>
                              <div className="text-xl font-bold text-gray-900">Total Amount</div>
                              {vehicleDetails.Rate_Code === "NI" ? (
                                <div className="text-sm text-gray-600 mt-1">Pay at Counter</div>
                              ) : (
                                <div className="text-sm text-gray-600 mt-1">Including all taxes & fees</div>
                              )}
                            </div>
                            <div className="text-right">
                              <div className="text-3xl font-bold bg-gradient-to-r from-gray-800 to-gray-800 bg-clip-text text-transparent">
                                {getCurrencySymbol(vehicleDetails?.currency)}
                                {calculateTotal().toFixed(2)}
                              </div>
                              <div className="text-sm text-gray-600 mt-1">
                                Total for {vehicleDetails?.rentalDetails?.numberOfDays} day(s)
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 space-y-4" ref={termsRef}>
                {/* Terms & Conditions Checkbox */}
                <div className="flex items-start gap-3">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={(e) => {
                      setTermsAccepted(e.target.checked);
                      if (formErrors.terms) {
                        setFormErrors((prev) => ({ ...prev, terms: "" }));
                      }
                    }}
                    className={`mt-0.5 rounded border-gray-300 
                      cursor-pointer
                      checked:bg-gradient-to-r checked:from-[#FF6100] checked:to-[#A66D44]
                      focus:ring-[#FF6100] focus:ring-offset-0
                      ${
                        formErrors.terms ? "border-red-500" : "border-[#FF6100]"
                      }`}
                  />
                  <div className="flex flex-col">
                    <label htmlFor="terms" className="text-sm text-gray-600">
                      I have read and accepted the{" "}
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPoliciesModal(true);
                        }}
                        className="text-[#FF6100] hover:underline border-none bg-transparent p-0 inline"
                      >
                        terms & conditions
                      </button>
                      *
                    </label>
                    {formErrors.terms && (
                      <span className="text-red-500 text-sm">
                        {formErrors.terms}
                      </span>
                    )}
                  </div>
                </div>

                {/* SMS Consent Checkbox */}
                <div className="flex items-start gap-3 ">
                  <input
                    type="checkbox"
                    id="smsConsent"
                    checked={smsConsent}
                    onChange={(e) => {
                      setSmsConsent(e.target.checked);
                      if (formErrors.sms) {
                        setFormErrors((prev) => ({ ...prev, sms: "" }));
                      }
                    }}
                    className={`mt-0.5  rounded border-gray-300 
                      cursor-pointer
                      checked:bg-gradient-to-r checked:from-[#FF6100] checked:to-[#A66D44]
                      focus:ring-[#FF6100] focus:ring-offset-0
                      ${
                        formErrors.sms ? "border-red-500" : "border-[#FF6100]"
                      }`}
                  />
                  <div className="flex flex-col">
                    <label
                      htmlFor="smsConsent"
                      className="text-sm text-gray-600"
                    >
                      By checking this box you agree to receive recurring
                      messages from Routes Car Rental, Reply STOP to Opt out.
                      Reply HELP for help. Message frequency varies. Message and
                      data rates may apply. Carriers are not liable for delayed
                      or undelivered messages.
                    </label>
                    {formErrors.sms && (
                      <span className="text-red-500 text-sm">
                        {formErrors.sms}
                      </span>
                    )}
                  </div>
                </div>

                {/* Marketing Campaign Checkbox */}
                <div className="flex items-start gap-3 pb-3">
                  <input
                    type="checkbox"
                    id="marketing"
                    checked={marketingConsent}
                    onChange={(e) => {
                      setMarketingConsent(e.target.checked);
                      if (formErrors.marketing) {
                        setFormErrors((prev) => ({ ...prev, marketing: "" }));
                      }
                    }}
                    className={`mt-0.5 rounded border-gray-300 
                      cursor-pointer
                      checked:bg-gradient-to-r checked:from-[#FF6100] checked:to-[#A66D44]
                      focus:ring-[#FF6100] focus:ring-offset-0
                      ${
                        formErrors.marketing
                          ? "border-red-500"
                          : "border-[#FF6100]"
                      }`}
                  />
                  <div className="flex flex-col">
                    <label
                      htmlFor="marketing"
                      className="text-sm text-gray-600"
                    >
                      I agree to participate in Routes Car Rental's marketing
                      campaigns and receive promotional offers*
                    </label>
                    {formErrors.marketing && (
                      <span className="text-red-500 text-sm">
                        {formErrors.marketing}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="space-y-2" ref={captchaRef}>
                <label className="block text-sm text-gray-600">
                  Enter the text from the image:
                </label>
                <div className="flex items-center gap-4">
                  <div className="bg-gray-100 px-4 py-2 rounded-lg select-none">
                    <span
                      className="font-mono text-lg tracking-widest"
                      style={{
                        userSelect: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        WebkitTouchCallout: "none",
                        textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                        transform: "skew(-5deg)",
                        display: "inline-block",
                        letterSpacing: "0.7em",
                        background: "linear-gradient(45deg, #333, #666)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontFamily: "monospace",
                        position: "relative",
                      }}
                      onCopy={(e) => e.preventDefault()}
                    >
                      {captchaText}
                    </span>
                  </div>
                  <button
                    onClick={generateCaptcha}
                    className="p-2 text-gray-500 hover:text-gray-700"
                    type="button"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                      />
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  value={userCaptchaInput}
                  onChange={(e) => setUserCaptchaInput(e.target.value)}
                  placeholder="Enter here ..."
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-[#FF6100] focus:border-[#FF6100] tracking-[0.5em] font-mono text-lg ${
                    captchaError ? "border-red-500" : "border-gray-300"
                  }`}
                  maxLength={captchaText.length}
                  style={{ letterSpacing: "0.5em" }}
                />
                {captchaError && (
                  <p className="text-red-500 text-sm mt-1">{captchaError}</p>
                )}
              </div>

              <button
                onClick={handlePaymentSubmit}
                className="w-full mt-4 bg-gradient-to-r from-[#FF6100] via-[#FF7A33] to-[#FF8F66] text-white py-4 rounded-xl font-semibold transition-all hover:shadow-lg hover:from-[#FF8F66] hover:via-[#FF7A33] hover:to-[#FF6100] text-lg"
              >
                {vehicleDetails.Rate_Code === "NI"
                  ? "BOOK NOW"
                  : formData?.selectedLocationSource === "europcar"
                    ? `Pay ${getCurrencySymbol(vehicleDetails?.currency)}${((15 * calculateTotal()) / 100).toFixed(2)} Now`
                    : "Proceed to Payment"}
              </button>
            </div>
          </div>
        </div>
      </main>

      <Footer />
      {alert.show && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({ show: false, message: "", type: "" })}
        />
      )}
      <ScrollButton />

      {/* Add the Converge Modal */}
      {showConverge && (
        <div className="fixed inset-0 z-[9999] overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-1 sm:p-6 sm:pb-1">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-2xl font-bold text-gray-900">
                        Billing
                      </h3>
                      <button
                        onClick={handleCloseConverge}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="mb-6">
                      <ul className="flex items-end justify-end gap-1">
                        <li className="flex items-center justify-center">
                          <img
                            src={cc_master_card}
                            alt="Mastercard"
                            className="h-5 object-contain"
                          />
                        </li>
                        <li className="flex items-center justify-center">
                          <img
                            src={cc_american_express}
                            alt="American Express"
                            className="h-5 object-contain"
                          />
                        </li>
                        <li className="flex items-center justify-center">
                          <img
                            src={cc_visa}
                            alt="Visa"
                            className="h-5 object-contain"
                          />
                        </li>
                      </ul>
                    </div>

                    <div className="space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Card Holder's Name*
                          </label>
                          <input
                            type="text"
                            className={`mt-1 block w-full border ${
                              convergeFormErrors.cardName
                                ? "border-red-500"
                                : "border-gray-300"
                            } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#FF6100] focus:border-[#FF6100]`}
                            placeholder="Name On Card"
                            value={CardName}
                            onChange={handleCardNameChange}
                          />
                          {convergeFormErrors.cardName && (
                            <p className="mt-1 text-sm text-red-500">
                              {convergeFormErrors.cardName}
                            </p>
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Credit Card Number*
                          </label>
                          <input
                            type="password"
                            className={`mt-1 block w-full border ${
                              convergeFormErrors.cardNumber
                                ? "border-red-500"
                                : "border-gray-300"
                            } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#FF6100] focus:border-[#FF6100]`}
                            placeholder="xxxx xxxx xxxx xxxx"
                            maxLength="16"
                            value={CardNumber}
                            onChange={handleCardNumberChange}
                          />
                          {convergeFormErrors.cardNumber && (
                            <p className="mt-1 text-sm text-red-500">
                              {convergeFormErrors.cardNumber}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Expiry*
                          </label>
                          <DatePicker
                            selected={ExpiryDate}
                            onChange={onChangetwo}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            minDate={new Date()}
                            placeholderText="MM/YYYY"
                            className={`mt-1 block w-full border ${
                              convergeFormErrors.expiryDate
                                ? "border-red-500"
                                : "border-gray-300"
                            } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#FF6100] focus:border-[#FF6100]`}
                          />
                          {convergeFormErrors.expiryDate && (
                            <p className="mt-1 text-sm text-red-500">
                              {convergeFormErrors.expiryDate}
                            </p>
                          )}
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            CVC*
                          </label>
                          <input
                            type="password"
                            className={`mt-1 block w-full border ${
                              convergeFormErrors.cvv
                                ? "border-red-500"
                                : "border-gray-300"
                            } rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#FF6100] focus:border-[#FF6100]`}
                            placeholder="Enter CVC"
                            maxLength="4"
                            value={CVVNum}
                            onChange={handleCVVChange}
                          />
                          {convergeFormErrors.cvv && (
                            <p className="mt-1 text-sm text-red-500">
                              {convergeFormErrors.cvv}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gradient-to-r from-[#FF6100] to-[#A66D44] text-base font-medium text-white hover:from-[#A66D44] hover:to-[#955C33] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF6100] sm:ml-3 sm:w-auto sm:text-sm ${
                    convergePayLoading ? "opacity-75 cursor-not-allowed" : ""
                  }`}
                  onClick={handleConvergePay}
                  disabled={convergePayLoading}
                >
                  {convergePayLoading ? (
                    <>
                      <Loader className="animate-spin mr-2 h-4 w-4" />
                      Processing...
                    </>
                  ) : (
                    `Pay $${calculateTotal().toFixed(2)}`
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isProcessing && <ProcessingOverlay />}
    </div>
  );
};

export default Checkout;
