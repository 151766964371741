import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';
import ScrollButton from '../components/ScrollButton';
import LoadingOverlayCommon from '../components/LoadingOverlayCommon';
import { locationService } from '../services/locationService';
import { MapPin, Mail, Phone, X, Building2, FileText } from 'lucide-react';
import Modal from '../components/Modal';
import { DataContext } from "../context/DataContext";

const REGIONS = {
  'North America': ['Canada', 'United States', 'Mexico'],
  'Central America': ['Panama', 'Costa Rica'],
  'South America': ['Colombia', 'Chile'],
  'Europe': ['Spain', 'Poland', 'Malta', 'Iceland', 'Romania', 'Albania', 'Croatia (Hrvatska)', 'United Kingdom'],
  'Caribbean': ['Dominican Republic', 'Jamaica', 'Trinidad And Tobago', 'Barbados'],
  'Oceania': ['New Zealand', 'Australia'],
  'Africa': ['Morocco'],
  'Middle East': ['Jordan']
};

const Locations = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [countryLocations, setCountryLocations] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { locations: dataLocations, setLocations: setDataLocations } = useContext(DataContext);
  const { setPolicy } = useContext(DataContext);
  const { setfinalLocationSelected } = useContext(DataContext);
  const [showDetails, setShowDetails] = useState(false);
  const handleClose = () => setShowDetails(false);
  const [locationDetails, setLocationDetails] = useState({});
  const [policyData, setPolicyData] = useState(null);
  const [showPolicyModal, setShowPolicyModal] = useState(false);

  useEffect(() => {
    const fetchLocations = async () => {
      setIsLoading(true);
      try {
        const data = await locationService.getLocations();
        const locationsByCountry = data.reduce((acc, location) => {
          const { country, location_name, city_code } = location;
          if (!acc[country]) acc[country] = [];
          acc[country].push(`${location_name} - ${city_code}`);
          return acc;
        }, {});
        setCountryLocations(locationsByCountry);
      } catch (error) {
        console.error('Error fetching locations:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocations();
  }, []);

  // const filteredLocations = searchQuery
  //   ? Object.entries(countryLocations).flatMap(([country, locs]) =>
  //       locs.filter(loc => loc.toLowerCase().includes(searchQuery.toLowerCase()))
  //     )
  //   : [];

  const filteredLocations = searchQuery
  ? Object.entries(countryLocations).flatMap(([country, locs]) => {
      const startsWithMatches = locs.filter(loc => 
        loc.toLowerCase().startsWith(searchQuery.toLowerCase()) || 
        country.toLowerCase().startsWith(searchQuery.toLowerCase())
      );

      const includesMatches = locs.filter(loc => 
        loc.toLowerCase().includes(searchQuery.toLowerCase()) || 
        country.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Combine startsWithMatches first, then includesMatches
      return [...startsWithMatches, ...includesMatches.filter(loc => !startsWithMatches.includes(loc))];
    })
  : [];

  const handleLocationClick = async (location) => {
    setIsLoading(true);
    try {
      const shortCode = location.split(' - ')[1];
      const details = await locationService.getLocationDetails(shortCode);
      setSelectedLocation(details);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching location details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePolicies = async (short_name) => {
    setIsLoading(true);
    try {
      const res = await locationService.getLocationPolicies(short_name);
      setPolicyData(res);
      setShowPolicyModal(true);
      setShowDetails(false);
    } catch (error) {
      console.error('Error fetching policies:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    locationService.getLocationDetails()
      .then((res) => {
        setLocationDetails(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleDetails2 = (airport) => {
    const res = locationDetails;
    let array1 = res.CAD;
    let array2 = res.INT;
    let array3 = res.USD;

    let flag = false;
    let res1 = {};
    for (let i = 0; i < array1?.length; i++) {
      if (array1[i].short_name === airport.split("- ")[1] || array1[i].f_name?.toLowerCase()?.trim() === airport.split("- ")[0]?.toLowerCase()?.trim()) {
        res1 = array1[i];
        flag = true;
        break;
      }
    }
    if (!flag)
      for (let i = 0; i < array2?.length; i++) {
        if (array2[i].short_name === airport.split("- ")[1] || array2[i]?.f_name.toLowerCase()?.trim() === airport.split("- ")[0]?.toLowerCase()?.trim()) {
          res1 = array2[i];
          flag = true;
          break;
        }
      }
    if (!flag)
      for (let i = 0; i < array3?.length; i++) {
        if (array3[i].short_name === airport.split("- ")[1] || array3[i].f_name?.toLowerCase()?.trim() === airport.split("- ")[0]?.toLowerCase()?.trim()) {
          res1 = array3[i];
          flag = true;
          break;
        }
      }
    setShowDetails(true);
    setDataLocations(res1);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {isLoading && <LoadingOverlayCommon />}
      
      <MainHeader />

      <main className="container mx-auto px-4 py-12 max-w-7xl">
        {/* Enhanced Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-5xl font-extrabold text-gray-800 tracking-tight mb-4">
            Explore Our <span className="text-orange-500">Global Network</span>
          </h1>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Discover our locations across the world and find the perfect destination for your next journey
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8 backdrop-blur-sm bg-opacity-95">
          {/* Enhanced Search Bar */}
          <div className="mb-12 flex justify-center">
            <div className="relative w-full md:w-[600px]">
              <input
                type="text"
                placeholder="Search your destination..."
                className="w-full px-6 py-4 text-lg border-2 border-orange-500 rounded-full focus:ring-4 focus:ring-orange-200 focus:outline-none transition-all duration-300 shadow-sm hover:shadow-lg"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="absolute right-4 top-4 p-2 bg-orange-500 rounded-full text-white">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>
          </div>

          {searchQuery ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {Object.entries(filteredLocations).map(([key, value]) => (
                <div
                  key={key}
                  className="group p-6 border-2 rounded-xl hover:bg-gradient-to-br from-orange-50 to-orange-100 cursor-pointer transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
                  onClick={() => handleDetails2(value)}
                >
                  <div className="flex items-center space-x-3">
                    <div className="p-2 bg-orange-100 rounded-lg group-hover:bg-orange-200 transition-colors">
                      <MapPin className="w-5 h-5 text-orange-600" />
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800">{value}</h3>
                  </div>
                </div>
              ))}
            </div>
          ) : selectedCountry ? (
            <>
              <button
                onClick={() => setSelectedCountry(null)}
                className="mb-8 flex items-center space-x-2 text-orange-600 hover:text-orange-700 transition-colors duration-300 font-medium text-lg"
              >
                <span className="w-8 h-8 flex items-center justify-center bg-orange-100 rounded-full">←</span>
                <span>Back to Regions</span>
              </button>
              <h2 className="text-4xl font-bold mb-8 text-gray-800 border-b pb-4">{selectedCountry === 'Croatia (Hrvatska)' ? "Croatia" :selectedCountry}</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {countryLocations[selectedCountry]?.map((location, index) => (
                  <div
                    key={index}
                    className="group p-6 border-2 rounded-xl hover:bg-gradient-to-br from-orange-50 to-orange-100 cursor-pointer transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
                    onClick={() => handleDetails2(location)}
                  >
                    <div className="flex items-center space-x-3">
                      <div className="p-2 bg-orange-100 rounded-lg group-hover:bg-orange-200 transition-colors">
                        <Building2 className="w-5 h-5 text-orange-600" />
                      </div>
                      <h3 className="text-lg font-semibold text-gray-800">{location}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {Object.entries(REGIONS).map(([region, countries]) => (
                <div key={region} className="border-2 rounded-xl p-8 hover:shadow-2xl transition-all duration-300 bg-gradient-to-br from-white to-gray-50 group">
                  <div className="flex items-center space-x-3 mb-6">
                    <div className="p-2 bg-orange-100 rounded-lg group-hover:bg-orange-200 transition-colors">
                      <MapPin className="w-6 h-6 text-orange-600" />
                    </div>
                    <h2 className="text-2xl font-bold text-gray-800">{region}</h2>
                  </div>
                  <div className="h-px w-full bg-gradient-to-r from-orange-200 via-orange-400 to-orange-200 mb-6"></div>
                  <ul className="space-y-4">
                    {countries.map((country) => (
                      <li
                        key={country}
                        className="flex items-center space-x-3 text-gray-600 hover:text-orange-600 cursor-pointer transition-all duration-300 p-2 rounded-lg hover:bg-orange-50"
                        onClick={() => setSelectedCountry(country)}
                      >
                        <span className="w-2 h-2 bg-orange-400 rounded-full"></span>
                        {
                          'Croatia (Hrvatska)' === country ?
                          <span className="font-medium">{"Croatia"}</span>
                          :
                          <span className="font-medium">{country}</span>
                        }
                        
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>

      {/* Location Details Modal */}
      {showDetails && dataLocations && (
        <Modal
          isOpen={showDetails}
          onClose={handleClose}
          title={`${dataLocations.f_name} - ${dataLocations.short_name}`}
        >
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <Building2 className="w-5 h-5 text-gray-600" />
              <span>{dataLocations.address}</span>
            </div>
            
            <div className="flex items-center gap-2">
              <Mail className="w-5 h-5 text-gray-600" />
              <Link 
                to={`mailto:${dataLocations.email1}`}
                className="text-[#FF6100] hover:text-[#FF6100]/80 no-underline"
              >
                {dataLocations.email1}
              </Link>
            </div>
            
            <div className="flex items-center gap-2">
              <Phone className="w-5 h-5 text-gray-600" />
              <Link 
                to={`tel:${dataLocations.number}`}
                className="text-[#FF6100] hover:text-[#FF6100]/80 no-underline"
              >
                {dataLocations.number}
              </Link>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 mt-6">
              <div className="lg:col-span-4">
                <h5 className="font-medium text-lg mb-4">Operating Hours</h5>
                <ul className="space-y-2">
                  {dataLocations.timeings ? (
                    Object.keys(dataLocations.timeings)
                      .sort((a, b) => {
                        const daysOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                        return daysOrder.indexOf(a) - daysOrder.indexOf(b);
                      })
                      .map((dayOfWeek) => (
                        <li
                          key={dayOfWeek}
                          className="flex justify-between text-gray-600"
                        >
                          <span className="font-medium">{dayOfWeek}:</span>
                          <span>
                            {dataLocations.timeings[dayOfWeek].from} - {dataLocations.timeings[dayOfWeek].to}
                          </span>
                        </li>
                      ))
                  ) : (
                    <p className="text-gray-500">Closed</p>
                  )}
                </ul>
              </div>

              <div className="lg:col-span-8 h-[300px]">
                {dataLocations.latlng && (
                  <iframe
                    title="location-map"
                    src={`https://maps.google.com/maps?q=${dataLocations.latlng.lat},${dataLocations.latlng.long}&hl=es;&output=embed`}
                    className="w-full h-full rounded-lg"
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-center gap-4 mt-6">
              <Link
                to="/"
                className="no-underline"
                onClick={() => setfinalLocationSelected(dataLocations)}
              >
                <button className="px-6 py-2 bg-[#FF6100] text-white rounded-lg hover:bg-[#FF6100]/90 transition-colors">
                  Book Now
                </button>
              </Link>

              <button
                onClick={() => handlePolicies(dataLocations.short_name)}
                className="flex items-center gap-2 text-[#FF6100] hover:text-[#FF6100]/80"
              >
                <FileText className="w-5 h-5" />
                <span className="underline">{dataLocations.full_name} rental policy</span>
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Policy Modal */}
      {showPolicyModal && policyData && (
        <Modal
          isOpen={showPolicyModal}
          onClose={() => {
            setShowPolicyModal(false);
            setShowDetails(true);
          }}
          title="Rental Policy"
        >
          <div className="space-y-4">
            {policyData.map((policy, index) => (
              <div key={index} className="border-b pb-4">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  {policy.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </h3>
                <p className="text-gray-600 whitespace-pre-line">{policy.content}</p>
              </div>
            ))}
          </div>
        </Modal>
      )}

      <Footer />
      <ScrollButton />
    </div>
  );
};

export default Locations;
