import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const locationService = {
  getLocationDetails: async () => {
    const response = await axios.get(`${API_BASE_URL}/routes_website_api_location_details`);
    return response.data;
  },

  getCountryCityAirports: async () => {
    const response = await axios.get(`${API_BASE_URL}/get_country_city_airport_name`);
    return response.data;
  },

  getLocations: async () => {
    const response = await axios.get(`${API_BASE_URL}/get_locations`);
    return response.data;
  },

  getLocationPolicies: async (shortName) => {
    const response = await axios.get(`${API_BASE_URL}/new_routes_website_api_all_policies/${shortName}`);
    return response.data;
  }
}; 