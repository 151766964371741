import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import routeslogo from "../components/assets/routeslogo.png";
import {
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import shuttleIconImg from "../components/assets/left_to_right.png";
import shuttleIconLeftToRightImg from "../components/assets/left_to_right.png";
import shuttleIconRightToLeftImg from "../components/assets/right_to_left.png";
import shuttleIconTopToBottomImg from "../components/assets/top_to_down.png";
import shuttleIconBottomToTopImg from "../components/assets/down_to_top.png";
import PillarIconImg from "../components/assets/shuttle_stop_icon.png";
import locationIconImg from "../components/assets/my_location_icon.png";
import destinationIconImg from "../components/assets/destination_location_icon.png";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";

// Define the icons outside the component to avoid re-creation on each render
const icons = {
  PillarIcon: {
    url: PillarIconImg,
    scaledSize: { width: 30, height: 40 },
  },
  locationIcon: {
    url: locationIconImg,
    scaledSize: { width: 40, height: 40 },
  },
  destinationIcon: {
    url: destinationIconImg,
    scaledSize: { width: 30, height: 30 },
  },
  shuttleIcon: {
    url: shuttleIconImg,
    scaledSize: { width: 30, height: 30 },
  },
  shuttleIconLeftToRight: {
    url: shuttleIconLeftToRightImg,
    scaledSize: { width: 30, height: 30 },
  },
  shuttleIconRightToLeft: {
    url: shuttleIconRightToLeftImg,
    scaledSize: { width: 30, height: 30 },
  },
  shuttleIconTopToBottom: {
    url: shuttleIconTopToBottomImg,
    scaledSize: { width: 30, height: 30 },
  },
  shuttleIconBottomToTop: {
    url: shuttleIconBottomToTopImg,
    scaledSize: { width: 30, height: 30 },
  },
};

const ShuttleRequestTracking = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);
  const [lastName, setLastName] = useState("");
  const [reservationNumber, setReservationNumber] = useState("");
  const [loadingLocation, setLoadingLocation] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedPillar, setSelectedPillar] = useState(null);
  const [shuttleRequested, setShuttleRequested] = useState(false);
  const [allTerminal, setAllTerminal] = useState([]);
  const [allDetail, setAllDetail] = useState({});
  const [selectedTerminalDetail, setSelectedTerminalDetail] = useState([]);
  const [selectedPillarDetail, setSelectedPillarDetail] = useState({});
  const [locationId, setLocationId] = useState("");
  const [vehicleLocations, setVehicleLocations] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: 28.429512,
    lng: -81.308912,
  }); // Default to MCO
  // const mapCenter= { lat: 28.4295, lng: -81.3089 }; // Default to MCO
  const [routesLocationCord, setRoutesLocationCord] = useState({
    lat: 28.46171,
    lng: -81.27355,
  });
  const [vehicleIcons, setVehicleIcons] = useState({}); // State to track icons for each vehicle
  const [veihcleDetail, setVehicleDetail] = useState([]);
  const [hashLocationNameVsID, setHashLocationNameVsID] = useState({});
  const [fetchInterval, setFetchInterval] = useState(null);
  const [myLocation, setMylocation] = useState({ lat: null, lng: null }); //live
  // const [myLocation, setMylocation] = useState({ lat: 28.429512, lng: -81.308912 }); //MCO
  // const [myLocation, setMylocation] = useState({ lat: 12.912975, lng: 77.650738 }); //BLR
  const [showMap, setShowMap] = useState(false); // State to toggle map display
  const [terminalNotSelected, terminal] = useState(false); // State to toggle map display
  const [hashPlate, setHashPlate] = useState({});
  // const [showPickUpSpots, setShowPickUpSpots] = useState(false);

  // Add these new state variables
  const [mapZoom, setMapZoom] = useState(14);
  // const [selectedVehicle, setSelectedVehicle] = useState(null);

  // Add a new state for minimum ETA
  const [minETA, setMinETA] = useState(10);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
  });

  const prevVehicleLocationsRef = useRef([]);

  // Ref to store previous user location
  const prevUserLocationRef = useRef({ lat: null, lng: null }); //live
  // const prevUserLocationRef = useRef({ lat: 28.4295, lng: -81.3089 }); //test mco

  useEffect(() => {
    const getLocation = () => {
      if ("geolocation" in navigator) {
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            const currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            prevUserLocationRef.current = currentLocation; //live
            setMylocation(currentLocation); // uncomment this
            setError(null);
            setLoadingLocation(false);
          },
          (err) => {
            let errorMessage;
            switch (err.code) {
              case err.PERMISSION_DENIED:
                errorMessage =
                  "User denied the request for Geolocation. Please enable location access in your browser settings.";
                break;
              case err.POSITION_UNAVAILABLE:
                errorMessage = "Location information is unavailable.";
                break;
              case err.TIMEOUT:
                errorMessage = "The request to get user location timed out.";
                break;
              default:
                errorMessage = "An unknown error occurred.";
                break;
            }
            setError(errorMessage);

            setLoadingLocation(false);
            setMylocation(prevUserLocationRef.current); // uncomment this
          },
          {
            enableHighAccuracy: true,
            timeout: 30000, // Increased timeout
            maximumAge: 0,
          }
        );

        return () => {
          navigator.geolocation.clearWatch(watchId);
        };
      } else {
        setError("Geolocation is not supported by your browser");
        setLoadingLocation(false);
        setMylocation(prevUserLocationRef.current); // uncomment this
      }
    };

    const intervalId = setInterval(getLocation, 10000); // Call getLocation every 10 seconds

    // Call getLocation immediately on mount
    getLocation();

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // getting customer distance from each pillar start
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLng = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLng / 2) *
        Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  function isWithin15km(myLocation, data) {
    const myLat = parseFloat(myLocation.lat);
    const myLng = parseFloat(myLocation.lng);

    for (const terminal of data.terminal_pillar) {
      for (const pillar of terminal.pillars) {
        const pillarLat = parseFloat(pillar.latitude);
        const pillarLng = parseFloat(pillar.longitude);
        const distance = calculateDistance(myLat, myLng, pillarLat, pillarLng);
        if (distance <= 15) {
          return true; // At least one pillar is within 15km
        }
      }
    }
    return false; // No pillar is within 15km
  }
  // getting customer distance from each pillar end

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!lastName.trim()) {
      alert("Please enter Last Name");
      return;
    }
    if (!reservationNumber.trim()) {
      alert("Please enter Reservation Number");
      return;
    }
    setLoadingSubmit(true);
    axios
      .post(`${baseURL}/srt_rw_check_validation`, {
        lastName,
        reservationNumber,
      })
      .then((response) => {
        if (response.data.status === "success") {
          setAllDetail(response.data);
          setAllTerminal(response.data.terminal_pillar);

          if (
            prevUserLocationRef.current.lat === null &&
            response.data.terminal_pillar.length > 0
          ) {
            const pillar_cord = response.data.terminal_pillar[0]["pillars"][0];
            setMylocation({
              lat: 1 * pillar_cord.latitude,
              lng: 1 * pillar_cord.longitude,
            });
          } else {
            const result = isWithin15km(myLocation, response.data);
            if (!result) {
              alert(
                "We're unable to fulfill your request at your current location. It appears you're outside the designated pick-up area. Please try again once you reach there."
              );
              setLoadingSubmit(false);
              return;
            }
          }
          setLocationId(response.data.Pickup_Location);
          setRoutesLocationCord({
            lat: 1 * response.data.location_cod.lat,
            lng: 1 * response.data.location_cod.lng,
          });
          setHashPlate(response.data.hash_srt_plate_num);
          setLoadingSubmit(false);
          setIsSubmitted(true);
        } else {
          alert(response.data.msg || "Reservation Details Not Found");
          setLoadingSubmit(false);
        }
      })
      .catch((error) => {
        alert(
          "We're experiencing technical difficulties. Please try again shortly."
        );
        setLoadingSubmit(false);
      });
  };

  // new intergration strats

  useEffect(() => {
    if (locationId) {
      setVehicleDetail([]);
      setVehicleLocations([]);
      if (fetchInterval) {
        clearInterval(fetchInterval);
        setFetchInterval(null);
      }
    }
  }, [locationId]);

  // Add this function to calculate minimum ETA
  const calculateMinETA = (vehicleLocations, selected_Pillar) => {
    if (vehicleLocations.length === 0 || !selected_Pillar) return null;

    const pillarLocation = {
      lat: parseFloat(selected_Pillar.latitude),
      lng: parseFloat(selected_Pillar.longitude),
    };

    // First, try to get ETAs for vehicles moving towards the pillar
    const movingTowardsEtas = vehicleLocations
      .map((location, index) => {
        const prevLocation = prevVehicleLocationsRef.current[index];
        if (!prevLocation) return null;

        const currentDistance = getDistanceKm(pillarLocation, location);
        const previousDistance = getDistanceKm(pillarLocation, prevLocation);

        if (currentDistance < previousDistance) {
          return Math.round((parseFloat(currentDistance) / 20) * 60);
        }
        return null;
      })
      .filter((eta) => eta !== null && !isNaN(eta) && eta >= 0);

    // If we found vehicles moving towards the pillar, return the minimum ETA
    if (movingTowardsEtas.length > 0) {
      return Math.min(...movingTowardsEtas);
    }

    // If no vehicles are moving towards the pillar, calculate ETAs based on distance only
    const distanceBasedEtas = vehicleLocations
      .map((location) => {
        const distance = getDistanceKm(pillarLocation, location);
        return Math.round((parseFloat(distance) / 20) * 60);
      })
      .filter((eta) => !isNaN(eta) && eta >= 0);

    return distanceBasedEtas.length > 0 ? Math.min(...distanceBasedEtas) : null;
  };

  const fetchVehicleLocations = (selected_Pillar) => {
    fetch(`${baseURL}/srt_vehicle_live_location/${locationId}`)
      .then((response) => response.json())
      .then((data) => {
        const vehicleLocArr = data.map((vehicle) => ({
          lat: vehicle.lat,
          lng: vehicle.lng,
        }));
        setVehicleLocations(vehicleLocArr);
        setVehicleDetail(data);

        // Calculate and set minimum ETA
        const minEtaValue = calculateMinETA(vehicleLocArr, selected_Pillar);

        setMinETA(minEtaValue);

        const updatedIcons = {};

        vehicleLocArr.forEach((vehicleLoc, index) => {
          // let prevLoc = vehicleLocations[index] || vehicleLocArr[index];
          let prevLoc =
            prevVehicleLocationsRef.current[index] || vehicleLocArr[index];

          let icon = icons.shuttleIcon; // Default icon
          const latDiff = vehicleLoc.lat - prevLoc.lat;
          const lngDiff = vehicleLoc.lng - prevLoc.lng;

          if (Math.abs(latDiff) > Math.abs(lngDiff)) {
            icon =
              latDiff > 0
                ? icons.shuttleIconBottomToTop
                : icons.shuttleIconTopToBottom;
          } else {
            icon =
              lngDiff > 0
                ? icons.shuttleIconLeftToRight
                : icons.shuttleIconRightToLeft;
          }

          updatedIcons[index] = icon;
        });
        setVehicleLocations(vehicleLocArr);

        // const myLocation = { lat: 28.4295, lng: -81.3089 }; // MCO // comment this

        setVehicleIcons(updatedIcons);
        prevVehicleLocationsRef.current = vehicleLocArr;
      })
      .catch((error) =>
        console.error("Error fetching vehicle locations: ", error)
      );
  };

  useEffect(() => {
    return () => {
      if (fetchInterval) {
        clearInterval(fetchInterval);
      }
    };
  }, [fetchInterval]);

  // Function to calculate distance in kilometers between two points
  const getDistanceKm = (point1, point2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const lat1 = (point1.lat * Math.PI) / 180;
    const lat2 = (point2.lat * Math.PI) / 180;
    const dLat = ((point2.lat - point1.lat) * Math.PI) / 180;
    const dLng = ((point2.lng - point1.lng) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance.toFixed(2); // Return distance rounded to 2 decimal places
  };

  // Function to convert kilometers to miles
  const kmToMiles = (km) => {
    const conversionFactor = 0.621371;
    return (km * conversionFactor).toFixed(2);
  };

  // new intergration ends

  const handleRequestShuttleClick = () => {
    // setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    // setIsModalOpen(false);
    setSelectedTerminal(null); // Reset the selected terminal when closing the modal
    setSelectedPillar(null); // Reset the selected pillar when closing the modal
  };

  const handleTerminalClick = (terminal) => {
    setSelectedTerminal(terminal);
    setSelectedTerminalDetail(
      allTerminal.filter((val) => val.terminal == terminal)[0]
    );
    setSelectedPillar(null); // Reset the selected pillar when selecting a new terminal
  };

  const handlePillarClick = (pillar, terminal) => {
    // Operating hours check
    if (!allDetail.hours_of_operation_flag) {
      const message =
        `The shuttle service is only available during our regular operating hours.\n\n` +
        (allDetail.timings_str
          ? `Operating Hours: ${allDetail.timings_str}\n\n`
          : "") +
        `For assistance, please contact our support team at our call center:\n` +
        `+1 (888) 471-8639 | +1 (416) 993-6826`;

      alert(message);

      return;
    }
    setSelectedTerminal(terminal);
    setSelectedTerminalDetail(allTerminal.find((t) => t.terminal === terminal));
    setSelectedPillar(pillar.number);
    setSelectedPillarDetail(pillar);

    // Instead of calling handleRequestShuttle, let's directly implement the shuttle request logic here
    setShuttleRequested(true);
    setShowMap(true);
    if (fetchInterval) {
      clearInterval(fetchInterval);
    }
    fetchVehicleLocations(pillar); // Fetch immediately
    const interval = setInterval(() => fetchVehicleLocations(pillar), 5000);
    setFetchInterval(interval);

    const obj = {
      locationId: locationId,
      reservationNumber: reservationNumber.toString().trim(),
      selectedPillar: pillar.number,
      selectedTerminal: terminal,
      customer_name: allDetail.customer_name,
      ticket_status: true,
    };

    axios
      .post(`${baseURL}/srt_rw_save_shuttle_request_ticket`, obj)
      .then((response) => {
        // Handle the response if needed
      })
      .catch((error) => {
        console.error("Error requesting shuttle:", error);
        // Handle the error if needed
      });
  };

  // Add this state at the top with other state declarations
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    message: "",
    onConfirm: null,
  });

  // Replace the openGoogleMaps function
  function openGoogleMaps(pillar) {
    setConfirmDialog({
      isOpen: true,
      title: "Get Directions",
      message: `Get Directions to ${selectedPillar}\n\nThis will guide you to ${selectedPillar} using Google Maps`,
      onConfirm: () => {
        const currentLat = myLocation.lat;
        const currentLng = myLocation.lng;
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${currentLat},${currentLng}&destination=${pillar.latitude},${pillar.longitude}&travelmode=driving`;
        window.open(googleMapsUrl, "_blank");
      },
    });
  }

  const mapContainerStyle = {
    width: "100%",
    height: "700px",
  };

  const silverStyle = [
    { elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
    { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#616161" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#f5f5f5" }] },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
  ];

  const options = {
    // disableDefaultUI: true,
    zoomControl: true,
    styles: silverStyle,
  };

  const mapRef = useRef(null);
  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();

      // Routes MCO marker
      const routesMarker = new window.google.maps.Marker({
        position: routesLocationCord,
        map: map,
        icon: icons.destinationIcon,
        title: "Routes Car Rental"
      });
      bounds.extend(routesLocationCord);

      const routesInfoWindow = new window.google.maps.InfoWindow({
        content: `
          <div>
            <div style="display: flex; justify-content: center; margin-bottom: 8px;">
              <img src="${routeslogo}" alt="Live Shuttle Tracking" style="width: 50px; height: auto;"/>
            </div>
            <div style="display: flex; justify-content: center;">
              <span style="color: green;"><b>Rental Center</b></span>
            </div>
          </div>
        `
      });
      routesInfoWindow.open(map, routesMarker);

      // Terminal and Pillar
      if (selectedTerminal && selectedPillar && selectedTerminalDetail) {
        selectedTerminalDetail.pillars
          .filter(pillar => pillar.number === selectedPillar)
          .forEach(pillar => {
            const pillarPosition = {
              lat: 1 * pillar.latitude,
              lng: 1 * pillar.longitude
            };
            const pillarMarker = new window.google.maps.Marker({
              position: pillarPosition,
              map: map,
              icon: icons.PillarIcon,
              title: pillar.number,
              label: {
                text: pillar.number,
                color: "black",
                fontWeight: "bold",
                fontSize: "14px"
              }
            });
            bounds.extend(pillarPosition);

            pillarMarker.addListener('click', () => openGoogleMaps(pillar));
          });
      }

      // Include all terminal pillars in bounds
      allTerminal.forEach((terminal) =>
        terminal.pillars.forEach((pillar) => {
          bounds.extend({
            lat: 1 * pillar.latitude,
            lng: 1 * pillar.longitude,
          });
        })
      );

      // Include user location in bounds if available
      if (myLocation.lat && myLocation.lng) {
        bounds.extend(myLocation);
      }

      // Include vehicle locations in bounds
      vehicleLocations.forEach(location => {
        bounds.extend(location);
      });

      // Fit bounds with padding
      map.fitBounds(bounds, {
        padding: {
          top: 50,
          right: 50,
          bottom: 50,
          left: 50
        }
      });

      // Optional: Set a minimum zoom level to prevent too much zoom on single marker
      const listener = new window.google.maps.event.addListener(map, 'idle', function() {
        if (map.getZoom() > 16) {
          map.setZoom(16);
        }
        window.google.maps.event.removeListener(listener);
      });

      setMapCenter(bounds.getCenter().toJSON());
      mapRef.current = map;
    },
    [allTerminal, routesLocationCord, selectedTerminal, selectedPillar, selectedTerminalDetail, myLocation, vehicleLocations]
  );

  const iHaveboardedHandle = () => {
    fetch(`${baseURL}/srt_rw_change_ticket_status/${reservationNumber}`)
      .then((response1) => response1.json())
      .then((data2) => {
        setIsSubmitted(false);
        setShowMap(false);
        setShuttleRequested(false);
        window.location.reload();
        return;
      });
  };

  const mapOptions = {
    ...options,
    zoomControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
  };

  // Add this useEffect hook
  useEffect(() => {
    if (shuttleRequested) {
      window.scrollTo(0, 0);
    }
  }, [shuttleRequested]);

  // Add these new state variables at the top with other states
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isPickupModalOpen, setIsPickupModalOpen] = useState(false);

  // Update the button click handlers
  const openHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  const openPickupModal = () => {
    setIsPickupModalOpen(true);
  };

  // Add overlay click handlers for each modal
  const handleHelpModalOverlayClick = (e) => {
    // Only close if clicking the overlay, not the modal content
    if (e.target === e.currentTarget) {
      setIsHelpModalOpen(false);
    }
  };

  const handlePickupModalOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsPickupModalOpen(false);
    }
  };

  const handleConfirmDialogOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setConfirmDialog({ ...confirmDialog, isOpen: false });
    }
  };

  // Add this new useEffect to handle marker updates
  useEffect(() => {
    if (!mapRef.current) return;
    
    // Clear existing vehicle markers
    const map = mapRef.current;
    
    // Remove previous markers if they exist
    if (window.vehicleMarkers) {
      window.vehicleMarkers.forEach(marker => marker.setMap(null));
    }
    window.vehicleMarkers = [];

    // Add new vehicle markers
    vehicleLocations.forEach((location, index) => {
      const vehicle = veihcleDetail[index] || {};
      const marker = new window.google.maps.Marker({
        position: location,
        map: map,
        icon: vehicleIcons[index] || icons.shuttleIcon,
        title: `Name: ${vehicle.name || "Unknown"}`
      });
      window.vehicleMarkers.push(marker);
    });
  }, [vehicleLocations, veihcleDetail, vehicleIcons]);

  // Add this new useEffect for user location marker
  useEffect(() => {
    if (!mapRef.current || !myLocation.lat) return;
    
    // Clear existing user location marker
    if (window.userLocationMarker) {
      window.userLocationMarker.setMap(null);
      if (window.userLocationInfo) {
        window.userLocationInfo.close();
      }
    }

    // Create new marker and info window
    const myLocationMarker = new window.google.maps.Marker({
      position: myLocation,
      map: mapRef.current,
      icon: icons.locationIcon,
      title: "My Location"
    });

    const myLocationInfo = new window.google.maps.InfoWindow({
      content: `
        <div>
          <div style="display: flex; justify-content: center;">
            <span style="color: green;">I am <b>${allDetail.customer_name}</b></span>
          </div>
          <div>
            <span style="color: green;">Reservation No.- <b>${reservationNumber}</b></span>
          </div>
        </div>
      `
    });
    myLocationInfo.open(mapRef.current, myLocationMarker);

    // Store references for cleanup
    window.userLocationMarker = myLocationMarker;
    window.userLocationInfo = myLocationInfo;

    return () => {
      if (window.userLocationMarker) {
        window.userLocationMarker.setMap(null);
      }
      if (window.userLocationInfo) {
        window.userLocationInfo.close();
      }
    };
  }, [myLocation, allDetail.customer_name, reservationNumber]);

  return (
    <div className="min-h-screen bg-gray-50">
      <MainHeader />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="text-center">
            {!isSubmitted ? (
              <h1 className="text-3xl md:text-5xl font-extrabold text-gray-800 tracking-tight mb-4">
                Shuttle <span className="text-orange-500">Tracker</span>
              </h1>
            ) : null}

            {!isSubmitted ? (
              <>
                <h4 className="text-xl font-semibold text-gray-800 mb-4">
                  Welcome to Routes Car Rental!
                </h4>
                <p className="text-lg text-gray-600 mb-4">
                  Thank you for choosing Routes Car Rental for your
                  transportation needs. We're dedicated to providing you with
                  the best service possible.
                </p>
                <p className="text-lg text-gray-600">
                  To request a shuttle and track it in real time, please enter
                  the Last Name on your reservation and your Confirmation Number
                  below.
                </p>
              </>
            ) : !shuttleRequested ? (
              <h4 className="text-xl font-semibold text-gray-800 mb-4 tracking-tight">
                Welcome
                {allDetail.customer_name != undefined &&
                allDetail.customer_name != "here"
                  ? `, ${allDetail.customer_name}!`
                  : ""}
              </h4>
            ) : null}
          </div>

          {isSubmitted && !shuttleRequested && (
            <div className="flex justify-center">
              <div className="w-full max-w-2xl">
                <div className="flex flex-wrap justify-around">
                  {allTerminal.map((terminal) =>
                    terminal.pillars.map((pillar) => (
                      <div
                        key={`${terminal.terminal}-${pillar.number}`}
                        className="w-full bg-white rounded-lg shadow-md p-6 mb-4"
                      >
                        <div className="text-center">
                          <div className="text-red-600 underline text-lg mb-2 font-bold">
                            Shuttle Instructions
                          </div>

                          <div className="text-gray-800 font-sans">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: pillar.arrivalInstruction.replace(
                                  /\n\n/g,
                                  "<br><br>"
                                ),
                              }}
                            />
                          </div>

                          {allDetail.timings_str && (
                            <span className="text-green-600 font-sans font-bold block mt-4">
                              Shuttle Pickup Hours:{" "}
                              {(allDetail.timings_str === "00:00 AM - 11:29 PM"
                                ? "Available 24 Hours"
                                : allDetail.timings_str) || "Not available"}
                            </span>
                          )}

                          {pillar.image && (
                            <div className="mt-6">
                              <span className="font-normal">Pickup Point</span>
                              <img
                                src={pillar.image}
                                alt="PickUp Point"
                                className="w-1/2 mx-auto mt-2 cursor-pointer transition-all duration-300"
                                onClick={(e) =>
                                  e.target.classList.toggle("w-1/2")
                                }
                              />
                            </div>
                          )}

                          <button
                            className="mt-6  bg-[#FF6100] text-white font-semibold py-3 px-4 rounded hover:bg-[#FF8942] transition-colors"
                            onClick={() =>
                              handlePillarClick(pillar, terminal.terminal)
                            }
                          >
                            I'm Here! Request a Shuttle
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}

          {!shuttleRequested && (
            <div className="mt-8">
              <div className="max-w-md mx-auto">
                {!isSubmitted && (
                  <form className="bg-white shadow-md rounded-lg p-6">
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="lastName"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FF6100]"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div>

                    <div className="mb-6">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="reservationNumber"
                      >
                        Confirmation Number
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#FF6100]"
                        id="reservationNumber"
                        value={reservationNumber}
                        onChange={(e) => setReservationNumber(e.target.value)}
                        required
                      />
                    </div>

                    <div className="flex justify-center">
                      <button
                        className={`px-6 py-2 bg-[#FF6100] text-white font-bold tracking-wider rounded hover:bg-[#FF8942] transition-colors ${
                          loadingSubmit ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={loadingSubmit}
                        onClick={handleSubmit}
                      >
                        {loadingSubmit ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          )}

          {isSubmitted && shuttleRequested && (
            <div className="pb-5">
              <div className="w-full pt-2">
                <h4 className="text-center pt-3 text-xl font-semibold tracking-tight">
                  Shuttle Status
                </h4>

                <div className="flex justify-between items-center mb-4">
                  {/* Back Button */}
                  <button
                    className="bg-[#FF6100] text-white px-4 py-2 rounded hover:bg-[#FF8942] transition-colors"
                    onClick={() => {
                      setShowMap(false);
                      setShuttleRequested(false);
                    }}
                  >
                    <i className="fas fa-arrow-left"></i>
                  </button>

                  {/* Instructions Button */}
                  <button
                    className="bg-[#FF6100] text-white px-4 py-2 rounded hover:bg-[#FF8942] transition-colors"
                    onClick={openPickupModal}
                  >
                    Shuttle Instructions
                  </button>
                </div>

                {/* Map Container */}
                <div className="text-center flex justify-center">
                  <div className="w-full">
                    {showMap &&
                      locationId &&
                      (vehicleLocations.length > 0 ? (
                        <>
                          <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            center={mapCenter}
                            // center={{ lat: 28.429512, lng: -81.308912 }}
                            zoom={mapZoom}
                            options={mapOptions}
                            onLoad={onLoad}
                            // Remove this onClick handler
                            // onClick={() => setSelectedVehicle(null)}
                          >
                            {/* Display minimum ETA at the top of the map */}
                            {minETA !== null && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  backgroundColor: "white",
                                  padding: "5px 10px",
                                  borderRadius: "5px",
                                  boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  width: "auto",
                                  maxWidth: "250px",
                                }}
                              >
                                Estimated Arrival:{" "}
                                {minETA === 0
                                  ? "Shuttle at pickUp point"
                                  : `${minETA} minute${
                                      minETA === 1 ? "" : "s"
                                    }`}
                              </div>
                            )}

                            {/* Add a custom control for centering the map */}
                            {/* <div style={{ position: 'absolute', right: '10px', top: '120px' }}> */}
                            <div
                              style={{
                                position: "absolute",
                                right: "10px",
                                bottom: "120px",
                              }}
                            >
                              <button
                                className="btn"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: "white",
                                }}
                                onClick={() => {
                                  mapRef.current.panTo(mapCenter);
                                  setMapZoom(14);
                                }}
                              >
                                <i className="fas fa-crosshairs"></i>
                              </button>
                            </div>
                          </GoogleMap>

                          {/* Map Footer */}
                          <div className="mt-4">
                            <p className="text-sm text-gray-600 mb-4">
                              * Due to the variability of traffic, arrival times
                              are subject to change.
                            </p>

                            <div className="flex justify-center gap-4">
                              <button
                                className="bg-gray-500 text-white px-6 py-2 rounded hover:bg-gray-600 transition-colors"
                                onClick={openHelpModal}
                              >
                                HELP
                              </button>

                              <button
                                className="bg-[#FF6100] text-white px-6 py-2 rounded hover:bg-[#FF8942] transition-colors"
                                onClick={iHaveboardedHandle}
                              >
                                I've Boarded
                              </button>
                            </div>

                            {/* Map Legend */}
                            <div className="mt-6">
                              <h6 className="font-semibold underline mb-3">
                                Map Legend
                              </h6>
                              <div className="flex flex-wrap justify-center gap-4">
                                {[
                                  {
                                    icon: icons.PillarIcon.url,
                                    label: "Pickup Point",
                                  },
                                  {
                                    icon: icons.shuttleIcon.url,
                                    label: "Shuttle",
                                  },
                                  {
                                    icon: icons.locationIcon.url,
                                    label: "Your Location",
                                  },
                                  {
                                    icon: icons.destinationIcon.url,
                                    label: "Routes Car Rental",
                                  },
                                ].map((item, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center gap-2"
                                  >
                                    <img
                                      src={item.icon}
                                      alt={item.label}
                                      className="w-5 h-5"
                                    />
                                    <span className="text-sm">
                                      {item.label}
                                    </span>
                                  </div>
                                ))}
                                <div className="flex items-center gap-2">
                                  <i className="fas fa-crosshairs w-5 h-5"></i>
                                  <span className="text-sm">
                                    Center to the pickUp point
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="flex justify-center items-center mt-4">
                          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#FF6100]"></div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Help Modal */}
      {isHelpModalOpen && (
        <div
          className="fixed inset-0 z-50 overflow-y-auto bg-gray-500 bg-opacity-75"
          onClick={handleHelpModalOverlayClick}
        >
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="bg-white rounded-lg shadow-xl w-full max-w-lg mx-4 sm:mx-auto"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking modal content
            >
              <div className="flex justify-between items-center px-6 py-4 border-b">
                <h3 className="text-lg font-medium text-gray-900">Help</h3>
                <button
                  onClick={() => setIsHelpModalOpen(false)}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <i className="fas fa-times text-xl"></i>
                </button>
              </div>
              <div className="px-6 py-4">
                <p className="text-sm text-gray-500 mb-4">For Help Call:</p>
                <div className="space-y-3">
                  <div>
                    <i className="fas fa-phone mr-2"></i>
                    <a
                      href="tel:+18884718639"
                      className="text-blue-600 hover:text-blue-800 font-semibold"
                    >
                      +1 (888) 471-8639
                    </a>
                  </div>
                  <div>
                    <i className="fas fa-phone mr-2"></i>
                    <a
                      href="tel:+14169936826"
                      className="text-blue-600 hover:text-blue-800 font-semibold"
                    >
                      +1 (416) 993-6826
                    </a>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-6 py-4 flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
                  onClick={() => setIsHelpModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Pickup Instructions Modal */}
      {isPickupModalOpen && (
        <div
          className="fixed inset-0 z-[10003] overflow-y-auto bg-gray-500 bg-opacity-75"
          onClick={handlePickupModalOverlayClick}
        >
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="bg-white rounded-lg shadow-xl w-full max-w-2xl mx-4 sm:mx-auto"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking modal content
            >
              <div className="flex justify-between items-center px-6 py-4 border-b">
                <h3 className="text-lg font-medium text-gray-900">
                  Shuttle Instructions
                </h3>
                <button
                  onClick={() => setIsPickupModalOpen(false)}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <i className="fas fa-times text-xl"></i>
                </button>
              </div>
              <div className="px-6 py-4">
                {selectedTerminal &&
                  selectedPillar &&
                  selectedTerminalDetail &&
                  selectedTerminalDetail.pillars
                    .filter((pillar) => pillar.number === selectedPillar)
                    .map((pillar, index) => (
                      <div key={index} className="space-y-6">
                        <div className="text-center font-bold text-lg">
                          {pillar.number === selectedPillar &&
                            "Selected pick-up point - "}
                          <span>{pillar.number}</span>
                        </div>

                        <div className="text-center">
                          <div className="text-red-600 underline font-bold mb-3">
                            Shuttle Instructions
                          </div>
                          <div className="text-gray-800 font-sans">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: pillar.arrivalInstruction.replace(
                                  /\n\n/g,
                                  "<br><br>"
                                ),
                              }}
                            />
                          </div>
                        </div>

                        {allDetail.timings_str && (
                          <div className="text-center">
                            <span className="text-green-600 font-bold">
                              Shuttle Pickup Hours:{" "}
                              {(allDetail.timings_str === "00:00 AM - 11:29 PM"
                                ? "Available 24 Hours"
                                : allDetail.timings_str) || "Not available"}
                            </span>
                          </div>
                        )}

                        {pillar.image && (
                          <div className="text-center">
                            <span className="block mb-2">Pickup Point</span>
                            <img
                              src={pillar.image}
                              alt="PickUp Point"
                              className="w-1/2 mx-auto cursor-pointer transition-all duration-300"
                              onClick={(e) =>
                                e.target.classList.toggle("w-1/2")
                              }
                            />
                          </div>
                        )}

                        <div className="text-red-600 text-center">
                          ★ Click the pick-up point icon on the map to access
                          turn-by-turn navigation.
                        </div>
                      </div>
                    ))}
              </div>
              <div className="bg-gray-50 px-6 py-4 flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
                  onClick={() => setIsPickupModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Dialog */}
      {confirmDialog.isOpen && (
        <div
          className="fixed inset-0 z-[10004] overflow-y-auto bg-gray-500 bg-opacity-75"
          onClick={handleConfirmDialogOverlayClick}
        >
          <div className="flex items-center justify-center min-h-screen">
            <div
              className="bg-white rounded-lg shadow-xl w-full max-w-lg mx-4 sm:mx-auto"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking modal content
            >
              <div className="flex justify-between items-center px-6 py-4 border-b">
                <h3 className="text-lg font-medium text-gray-900">
                  {confirmDialog.title}
                </h3>
                <button
                  onClick={() =>
                    setConfirmDialog({ ...confirmDialog, isOpen: false })
                  }
                  className="text-gray-400 hover:text-gray-500 focus:outline-none"
                >
                  <i className="fas fa-times text-xl"></i>
                </button>
              </div>
              <div className="px-6 py-4">
                <p className="text-gray-700 whitespace-pre-line">
                  {confirmDialog.message}
                </p>
              </div>
              <div className="bg-gray-50 px-6 py-4 flex justify-end space-x-3">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
                  onClick={() =>
                    setConfirmDialog({ ...confirmDialog, isOpen: false })
                  }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="px-4 py-2 bg-[#FF6100] text-white rounded hover:bg-[#FF8942] transition-colors"
                  onClick={() => {
                    confirmDialog.onConfirm();
                    setConfirmDialog({ ...confirmDialog, isOpen: false });
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ShuttleRequestTracking;
