import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';

const SmsAlerts = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
    setIsInvalidPhone(false);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setIsInvalidEmail(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    if (phoneNumber.toString().trim() === "") {
      setLoading(false);
      setIsInvalidPhone(true);
      alert("Please fill phone number field.");
      return;
    }

    // Success case
    alert("Successfully submitted.");
    setPhoneNumber("");
    setEmail("");
    setLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <MainHeader />
      
      <div className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-8">
            <h1 className="text-2xl font-bold text-center mb-8">
              Sign Up for Routes Car Rental SMS Alerts
            </h1>

            <p className="text-gray-700 mb-6">
              Stay informed with important updates and information about your car rental reservation, 
              pickup terms, shuttle tracking, and location details. Sign up for our SMS alerts below:
            </p>

            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number*
                  </label>
                  <input
                    type="tel"
                    className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                      isInvalidPhone ? 'border-red-500' : 'border-gray-300'
                    }`}
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className={`w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
                      isInvalidEmail ? 'border-red-500' : 'border-gray-300'
                    }`}
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
              </div>

              <div className="space-y-4 text-gray-700">
                <p>
                  By providing your phone number, you agree to receive text messages from Routes Car Rental. 
                  You will receive up to 10 messages per month. Message and data rates may apply.
                </p>

                <div className="space-y-2">
                  <p><strong>Message Frequency Disclosure:</strong> You will receive up to 10 messages per month.</p>
                  <p><strong>Help Information:</strong> Text HELP for help.</p>
                  <p><strong>Opt-out Information:</strong> Text STOP to cancel.</p>
                  <p><strong>Message Pricing Disclosure:</strong> Message and data rates may apply.</p>
                </div>
              </div>

              <div className="text-center">
                <button
                  onClick={handleSubmit}
                  className="px-8 py-3 bg-orange-500 text-white font-semibold rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                >
                  Sign Up Now
                </button>
              </div>

              <div className="mt-6">
                <h3 className="text-lg font-bold mb-2">Additional Text for Context:</h3>
                <p className="text-gray-700">
                  By clicking "Sign Up Now," you consent to receive marketing text messages from Routes Car Rental 
                  at the number provided, including messages sent by autodialer. Consent is not a condition of any 
                  purchase. Message and data rates may apply. Message frequency varies. Reply HELP for help or 
                  STOP to cancel. View our{" "}
                  <Link to="/SmsPolicy" className="text-blue-600 hover:text-blue-800">
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link to="/rentalpolicy" className="text-blue-600 hover:text-blue-800">
                    Terms of Service
                  </Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
          <div className="flex gap-2">
            <div className="w-3 h-3 bg-orange-500 rounded-full animate-bounce"></div>
            <div className="w-3 h-3 bg-orange-500 rounded-full animate-bounce delay-100"></div>
            <div className="w-3 h-3 bg-orange-500 rounded-full animate-bounce delay-200"></div>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default SmsAlerts; 