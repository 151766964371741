import React, { useState, useRef } from "react";
import Header from "../components/MainHeader";
import Footer from "../components/Footer";
import { getReservationDetails } from "../services/api";
import Modal from "../components/Modal";
import LoadingOverlayCommon from "../components/LoadingOverlayCommon";

const Reservation = () => {
  const [reservationNumber, setReservationNumber] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return "N/A";
    // const date = new Date(dateTimeStr);
    // return date.toLocaleString("en-US", {
    //   month: "short",
    //   day: "numeric",
    //   year: "numeric",
    //   hour: "numeric",
    //   minute: "2-digit",
    //   hour12: true,
    // });
    return dateTimeStr?.slice(0,-3);
  };

  const handleContactNumberChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d{0,15}$/.test(inputValue)) {
      setContactNumber(inputValue);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    const validationErrors = [];
    if (!reservationNumber.trim())
      validationErrors.push("Reservation number is required");
    if (!contactNumber.trim())
      validationErrors.push("Contact number is required");

    if (validationErrors.length > 0) {
      alert(validationErrors.join("\n"));
      return;
    }

    setIsLoading(true);
    try {
      const response = await getReservationDetails(
        reservationNumber,
        contactNumber
      );

      if (!response) {
        setIsLoading(false);
        alert("No response received from server");
        
        // throw new Error('No response received from server');
        return;
      } else if (response.status === "0") {
        setIsLoading(false);
        alert("No active reservation found with the provided details");
       
        // throw new Error(response.error || 'No reservation found with the provided details');
        return;
      } else {
        setResponseData(response);
        setIsModalOpen(true);
      }
    } catch (error) {
      alert(
        error.message ||
          "An error occurred while fetching reservation details. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      {isLoading && <LoadingOverlayCommon />}
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="bg-white rounded-xl shadow-2xl p-8 border-2 border-yellow-400 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">
              Find Your Reservation
            </h2>

            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-3">
                <label className="block text-gray-700 font-semibold text-sm uppercase tracking-wide">
                  Reservation Number*
                </label>
                <div className="relative">
                  <i className="fas fa-ticket absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"></i>
                  <input
                    type="text"
                    className="w-full pl-10 pr-4 py-3 border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent transition-all"
                    placeholder="Enter reservation number"
                    value={reservationNumber}
                    onChange={(e) => setReservationNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="space-y-3">
                <label className="block text-gray-700 font-semibold text-sm uppercase tracking-wide">
                  Contact Number*
                </label>
                <div className="relative">
                  <i className="fas fa-phone absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"></i>
                  <input
                    type="tel"
                    className="w-full pl-10 pr-4 py-3 border-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:border-transparent transition-all"
                    placeholder="Enter contact number"
                    value={contactNumber}
                    onChange={handleContactNumberChange}
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 text-center">
              <button
                onClick={handleSearch}
                className="bg-[#ff6000] hover:bg-[#ff7000] text-white font-bold py-3 px-8 rounded-lg transform transition-all hover:scale-105 hover:shadow-lg"
              >
                Search Reservation
              </button>
              <p className="text-sm mt-4 text-gray-600 bg-gray-50 p-3 rounded-lg inline-block">
                <i className="fas fa-info-circle mr-2 text-[#ff6000]"></i>
                You cannot modify or cancel the booking
              </p>
            </div>
          </div>

          {isModalOpen && responseData && (
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              title="Reservation Details"
            >
              <div className="grid md:grid-cols-2 gap-8 p-4">
                {/* Reservation Details Card */}
                <div className="bg-gray-50 rounded-lg p-6 shadow-sm border border-gray-200">
                  <h4 className="text-xl font-bold text-center mb-6 text-gray-800 border-b pb-3">
                    Reservation Details
                  </h4>
                  <ul className="space-y-4">
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="fas fa-location-dot text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Pick-up Location
                        </span>
                        <p className="text-gray-800">
                          {responseData.result.Pickup_Location}
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="fas fa-location-dot text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Drop-off Location
                        </span>
                        <p className="text-gray-800">
                          {responseData.result.DropOff_Location}
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="far fa-calendar-days text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Pick-up Date & Time
                        </span>
                        <p className="text-gray-800">
                          {formatDateTime(
                            responseData?.result?.Pickup_Date_Time
                          )}
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="far fa-calendar-days text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Drop-off Date & Time
                        </span>
                        <p className="text-gray-800">
                          {formatDateTime(
                            responseData?.result?.DropOff_Date_Time
                          )}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                {/* Customer Details Card */}
                <div className="bg-gray-50 rounded-lg p-6 shadow-sm border border-gray-200">
                  <h4 className="text-xl font-bold text-center mb-6 text-gray-800 border-b pb-3">
                    Customer Details
                  </h4>
                  <ul className="space-y-4">
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="far fa-user text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Customer Name
                        </span>
                        <p className="text-gray-800">
                          {responseData.result.RenterFirst}{" "}
                          {responseData.result.RenterLast}
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="far fa-envelope text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Email Address
                        </span>
                        <p className="text-gray-800">
                          {responseData.result.EmailAddress}
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="fas fa-phone text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Contact Number
                        </span>
                        <p className="text-gray-800">
                          {responseData.result.RenterHomePhone}
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="fas fa-calendar-day text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Length of Rental
                        </span>
                        <p className="text-gray-800">
                          {responseData.result.length_of_rental} Days
                        </p>
                      </div>
                    </li>
                    <li className="flex items-start space-x-3 p-2 hover:bg-white rounded transition-colors">
                      <i className="fas fa-toggle-on text-[#ff6000] mt-1"></i>
                      <div>
                        <span className="text-sm text-gray-500">
                          Booking Status
                        </span>
                        <p className="text-gray-800">
                          {responseData.result.Status_Valid_Cancelled_No_Show}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Modal>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Reservation;
