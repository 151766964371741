import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, BusFront, IdCard, BookOpenCheck, Phone, X, Home, User, Tag } from 'lucide-react';

const SideMenu = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0" style={{ zIndex: 999999 }}>
      {/* Overlay */}
      <div 
        className="fixed inset-0 bg-black/30 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Menu */}
      <div
        className={`fixed top-0 right-0 w-80 h-full bg-white shadow-2xl transition-transform duration-500 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex flex-col h-full">
          {/* Menu Header */}
          <div className="p-6 border-b flex-shrink-0">
            <div className="flex justify-between items-center">
              <img
                src={require("./assets/routeslogo.png")}
                alt="Routes Logo"
                className="h-10"
              />
              <button
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                onClick={onClose}
              >
                <X size={24} />
              </button>
            </div>
          </div>

          {/* Navigation Links */}
          <nav className="p-5 flex-1 overflow-y-auto">
            <ul className="">
              {[
                { to: "/", text: "Home", icon: Home, color: "bg-blue-50 text-blue-600" },
                { to: "/locations", text: "Locations", icon: MapPin, color: "bg-yellow-50 text-yellow-600" },
                // { to: "/offers", text: "Offers", icon: Tag, color: "bg-pink-50 text-pink-600" },
                { to: "/rentalpolicy", text: "Rental Policy", icon: BookOpenCheck, color: "bg-green-50 text-green-600" },
                { to: "/reservation", text: "Your Reservation", icon: IdCard, color: "bg-purple-50 text-purple-600" },
                { to: "/ShuttleRequestTracking", text: "Shuttle Tracker", icon: BusFront, color: "bg-orange-50 text-orange-600" },
                { to: "/TravelAgent", text: "Travel Agent", icon: User, color: "bg-blue-50 text-blue-600" },
                { to: "/contact", text: "Contact Us", icon: Phone, color: "bg-red-50 text-red-600" },
              ].map((item) => (
                <li key={item.to}>
                  <Link
                    to={item.to}
                    className="flex items-center px-4 py-3 rounded-lg hover:bg-orange-50 transition-colors duration-200"
                    onClick={onClose}
                  >
                    <span className={`p-2 rounded-lg mr-3 ${item.color}`}>
                      <item.icon size={20} />
                    </span>
                    <span className="text-gray-700 font-medium">{item.text}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Contact Section */}
          <div className="p-4 bg-gray-50 flex-shrink-0">
            <div className="text-xs text-gray-500 mb-2">
              Need assistance?
            </div>
            <div className="space-y-1">
              <a 
                href="tel:+18884718639" 
                className="flex items-center text-gray-700 hover:text-orange-600"
              >
                <div className="bg-gray-100 p-1.5 rounded-lg mr-2">
                  <Phone size={16} />
                </div>
                <span className="font-medium text-sm">+1 (888) 471-8639</span>
              </a>
              <a 
                href="tel:+14169936826" 
                className="flex items-center text-gray-700 hover:text-orange-600"
              >
                <div className="bg-gray-100 p-1.5 rounded-lg mr-2">
                  <Phone size={16} />
                </div>
                <span className="font-medium text-sm">+1 (416) 993-6826</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu; 